import {
  CriticalIcon,
  InformationIcon,
  LowIcon,
  NormalIcon
} from "@packages/ds";
import styled from "styled-components";

export const Root = styled.div`
  flex: 0 0 24px;
  height: 24px;
`;

export const Normal = styled(NormalIcon)`
  fill: var(--icon-gold-fill, var(--gold));
`;

export const Low = styled(LowIcon)`
  fill: var(--icon-java-fill, var(--java));
`;

export const Information = styled(InformationIcon)`
  fill: var(--icon-color-primary-600-fill, var(--color-primary-600));
`;

export const Critical = styled(CriticalIcon)`
  fill: var(--icon-red-fill, var(--red));
`;
