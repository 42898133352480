import React from "react";

import { Icon, WrappingIconProps } from "../Icon";

export const ProjectsIcon = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M8.11111 18H5.77778C5.30628 18 4.8541 17.8174 4.5207 17.4923C4.1873 17.1673 4 16.7264 4 16.2667V6.73333C4 6.27362 4.1873 5.83274 4.5207 5.50768C4.8541 5.18262 5.30628 5 5.77778 5H9.33333L12 7.6H18.2222C18.6937 7.6 19.1459 7.78262 19.4793 8.10768C19.8127 8.43274 20 8.87362 20 9.33333V11.8"
        data-stroke
        style={{
          fill: "transparent",
          stroke: "var(--mode-vector-neutral-default)"
        }}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 20L20 18L18 16"
        data-stroke
        style={{
          stroke: "var(--mode-vector-neutral-default)"
        }}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 16L12 18L14 20"
        data-stroke
        style={{
          stroke: "var(--mode-vector-neutral-default)"
        }}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
