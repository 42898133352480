import React from "react";

import { Icon, WrappingIconProps } from "../Icon";

export const SyncIcon = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1 21.152L13.7 19.852L15.7 18.052C16.1 17.752 16.1 17.152 15.7 16.852L13.7 15.252L12.1 13.952C11.6 13.552 11 13.952 11 14.552V16.652C8.2 16.152 6 13.752 6 10.752C6 8.05202 7.8 5.75202 10.3 4.95202C10.8 4.85202 11.1 4.35202 11 3.85202C10.9 3.25202 10.3 2.85202 9.7 3.05202C6.4 4.15202 4 7.25202 4 10.752C4 14.852 7.1 18.152 11 18.652V20.552C11 21.252 11.6 21.552 12.1 21.152ZM17.6 14.852C17.2 14.552 17.1 14.052 17.3 13.552C17.5 13.152 17.7 12.752 17.8 12.352C17.9 11.852 18.4 11.552 18.9 11.652C19.5 11.752 19.9 12.352 19.7 12.952C19.6 13.202 19.5 13.477 19.4 13.7519L19.4 13.752C19.3 14.027 19.2 14.302 19.1 14.552C18.8 15.052 18.1 15.252 17.6 14.852ZM17.2 7.85202C17 7.35202 17.1 6.85202 17.5 6.55202C18 6.25202 18.7 6.35202 18.9 6.85202C19.1349 7.24352 19.3085 7.69634 19.4688 8.11445C19.5132 8.23032 19.5566 8.34352 19.6 8.45202C19.8 9.05202 19.4 9.65202 18.8 9.75202C18.3 9.85202 17.8 9.55202 17.7 9.05202C17.6 8.65202 17.4 8.25202 17.2 7.85202ZM13.7 4.95202C13.2 4.85202 12.9 4.35202 13 3.85202C13.1 3.35202 13.7 2.95202 14.3 3.05202C14.8 3.15202 15.3 3.35202 15.8 3.65202C16.3 3.95202 16.5 4.65202 16.1 5.15202C15.8 5.55202 15.2 5.65202 14.8 5.45202C14.5 5.25202 14.1 5.05202 13.7 4.95202Z"
      />
    </Icon>
  );
};
