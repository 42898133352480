import React from "react";

import { Highlightable } from "@packages/ds";
import { useNavigate } from "react-router-dom";

import getUrl from "Libs/getUrl";

import { EnvironmentTreeData } from "../utils";

import * as S from "./MenuLinkItem.styles";

type MenuLinkItemProps = {
  node: EnvironmentTreeData;
  organizationId: string;
  currentEnvironment?: string;
  id: string;
  searchValue: string;
  filter: (env: EnvironmentTreeData) => boolean;
  addPadding?: boolean;
};

const MenuLinkItem = ({
  currentEnvironment,
  filter,
  id,
  node,
  organizationId,
  searchValue,
  addPadding
}: MenuLinkItemProps) => {
  const navigate = useNavigate();
  const environmentOverviewUrl = getUrl({
    key: "organization.project.environment",
    props: {
      organizationId,
      projectId: node.projectId ?? "",
      environmentId: node.environmentId ?? ""
    }
  });

  const goToEnv = e => {
    e?.preventDefault();

    navigate(environmentOverviewUrl);
  };

  const children = node.children
    ?.sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
      return 0;
    })
    .filter(filter);

  return (
    <S.Wrapper key={node.title} $addPadding={addPadding}>
      <S.DropdownLinkItem
        $isRoot={node?.isRoot}
        id={`${id}-${node.environmentId}`}
        isSelected={node.environmentId == currentEnvironment}
        onKeyUp={e => {
          if (e.keyCode === 13 || e.keyCode === 32) goToEnv(e);
        }}
        to={environmentOverviewUrl}
        role="menuitem"
        aria-label={node?.title}
        tabIndex={0}
        analyticId={`environment_dropdown-${id}-${node.environmentId}`}
      >
        <Highlightable
          searchText={searchValue}
          textToHighlight={node?.title ?? ""}
        />
      </S.DropdownLinkItem>

      {children?.map(node => {
        return (
          <MenuLinkItem
            key={node.environmentId}
            id={id}
            node={node}
            organizationId={organizationId}
            currentEnvironment={currentEnvironment}
            searchValue={searchValue}
            filter={filter}
            addPadding={true}
          />
        );
      })}
    </S.Wrapper>
  );
};

export default MenuLinkItem;
