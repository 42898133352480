import React from "react";

import { Icon, WrappingIconProps } from "../Icon";

export const TickIcon = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path d="M9.99999 17.1C9.69999 17.1 9.49999 17 9.29999 16.8L5.09999 12.6C4.69999 12.2 4.69999 11.6 5.09999 11.2C5.49999 10.8 6.09999 10.8 6.49999 11.2L9.99999 14.7L17.5 7.20002C17.9 6.80002 18.5 6.80002 18.9 7.20002C19.3 7.60002 19.3 8.20002 18.9 8.60002L10.7 16.8C10.5 17 10.3 17.1 9.99999 17.1Z" />
    </Icon>
  );
};
