import React from "react";

import { Icon, WrappingIconProps } from "../Icon";

export const ChevronRightIcon = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path d="M10 17.2C9.7 17.2 9.5 17.1 9.3 16.9C8.9 16.5 8.9 15.9 9.3 15.5L12.8 12L9.3 8.50001C8.9 8.10001 8.9 7.50001 9.3 7.10001C9.7 6.70001 10.3 6.70001 10.7 7.10001L14.9 11.3C15.3 11.7 15.3 12.3 14.9 12.7L10.7 16.9C10.5 17.1 10.3 17.2 10 17.2Z" />
    </Icon>
  );
};
