import React from "react";

import { ChevronIcon } from "@packages/ds";
import { FormattedMessage } from "react-intl";

import { CloseButton } from "ds/Button";
import SecondaryLink from "ds/Button/SecondaryLink";
import Logo from "src/common/containers/NavBar/Logo/Logo";

import * as S from "./styles";

interface Props {
  isOpen?: boolean;
  setIsOpen: (boolean) => void;
  backButton?: boolean;
  onClickBack?: () => void;
}

const HeaderMenuMobile = ({
  isOpen = true,
  setIsOpen,
  backButton = true,
  onClickBack
}: Props) => {
  return (
    <>
      <S.LogoCloseContainer>
        <Logo />
        <CloseButton
          onClick={() => {
            setIsOpen(!isOpen);
            (document.activeElement as HTMLElement).blur();
          }}
        />
      </S.LogoCloseContainer>

      {backButton && (
        <S.CustomBackLink onClick={onClickBack}>
          <SecondaryLink>
            <ChevronIcon direction="left" aria-hidden="true" />
            <FormattedMessage id="back" />
          </SecondaryLink>
        </S.CustomBackLink>
      )}
    </>
  );
};

export default HeaderMenuMobile;
