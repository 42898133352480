import React from "react";

import { CheckedIcon } from "@packages/ds";

import { sendHeapEventCheckbox } from "Store/analytics/heapFunctions";

import * as S from "./Checkbox.styles";

export interface Props {
  /** Renders the error state if false */
  isValid?: boolean;
  checked: boolean;
  label: React.ReactNode;
  onCheckedChanged?: (checked: boolean) => void;
  error?: string;
  forId?: string;
  id?: string;
  required?: boolean | undefined;
  disabled?: boolean | undefined;
}

const Checkbox = ({
  onCheckedChanged,
  error,
  isValid = true,
  label,
  forId,
  disabled,
  required,
  id,
  checked,
  ...props
}: Props) => {
  const onCheckboxClickClick = checked => {
    onCheckedChanged && onCheckedChanged(checked);
    sendHeapEventCheckbox({
      analyticId: id,
      check_id: label,
      value: checked ? "marked checked" : "marked unchecked"
    });
  };
  return (
    <S.Layout
      data-testid="root"
      data-state={checked ? "checked" : "unchecked"}
      {...props}
    >
      <S.CheckboxWrapper>
        <S.Root
          id={forId}
          onCheckedChange={onCheckboxClickClick}
          disabled={disabled}
          required={required}
          name={forId || id}
          checked={checked}
          isValid={isValid}
        >
          <S.Indicator>
            <CheckedIcon
              color={
                isValid
                  ? "var(--mode-bg-primary-default)"
                  : "var(--mode-bg-critical-default)"
              }
            />
          </S.Indicator>
        </S.Root>
      </S.CheckboxWrapper>
      <S.Label
        htmlFor={forId}
        id={id ? id : forId + "-label"}
        disabled={disabled}
      >
        {label}
      </S.Label>
      {error && <S.Error>{error}</S.Error>}
    </S.Layout>
  );
};

export default Checkbox;
