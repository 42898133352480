import React from "react";

import { Icon, WrappingIconProps } from "../Icon";

export const HeartIcon = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path d="M18 9.80547C18 12.7855 15.25 14.6442 12.5 17C9.75 14.6442 7 12.7855 7 9.80547C7 6.8254 11.5833 5.75872 12.5 8.90616C13.4167 5.75872 18 6.8254 18 9.80547Z" />
    </Icon>
  );
};
