import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import MaxWidthContainer from "Components/MaxWidthContainer";
import config from "console_config";
import {
  ENVIRONMENT_ID_FIELD,
  BILLING_PATH_MATCH,
  SETTINGS_PATH_MATCH,
  SUPPORT_PATH_MATCH
} from "Constants/constants";
import BannerManager from "Containers/Banner";
import getUrl from "Libs/getUrl";
import { isProjectOwner } from "Libs/utils";
import { meSelector } from "Reducers/app/selectors";
import {
  loadEnvironment,
  loadEnvironments,
  environmentSelector,
  environmentsAsArraySelector,
  environmentTreeSelector
} from "Reducers/environment";
import { getFeatureFlag } from "Reducers/featureFlags";
import { hasBillingPermissionSelector } from "Reducers/organization/loggedInMember/loggedInMember.selectors";
import {
  loadSubscription as loadOrganizationSubscription,
  loadSubscriptions as loadOrganizationSubscriptions
} from "Reducers/organization/subscription";
import {
  getIsLoadingListProjectsSelector,
  gitProjectSelector,
  selectProjectsFromAllOrgs
} from "Reducers/project/project";
import { toggleProjectWizard, setForceOpen } from "Reducers/project/wizard";
import { loadSubscription, loadSubscriptions } from "Reducers/subscription";

import Breadcrumbs from "./Breadcrumbs";
import DunningTag from "./DunningTag";
import EnvironmentNavBar from "./Environment";
import HelpMenu from "./HelpMenu/HelpMenu";
import InsideNavBar from "./InsideNavBar";
import Logo from "./Logo/Logo";
import OrganizationsSwitcher from "./OrganizationsSwitcher";
import ProjectNavBar from "./Project";
import SearchBar from "./SearchBar/SearchBar";
import {
  NavBarLayout,
  NavBarLeftLayout,
  NavBarRightLayout,
  PageHeader,
  OrganizationDivider
} from "./styles";
import Menu from "./UserMenu";

let announcements = null;
const announcementsEnabled = getFeatureFlag("ENABLE_ANNOUNCEMENTS");

if (announcementsEnabled) {
  const AnnouncementDropdown = React.lazy(
    () => import("Containers/announcements/Dropdown")
  );

  announcements = <AnnouncementDropdown />;
}

class NavBar extends React.Component {
  async componentDidMount() {
    // If NavBar loads, make sure these are removed.
    const preLoadingContainer = document.getElementById("pre-loading");
    const fallbackContainer = document.getElementById("fallback");
    const organizationEnabled = getFeatureFlag("ENABLE_ORGANIZATION");
    const billingEnabled = getFeatureFlag("ENABLE_BILLING");

    if (preLoadingContainer) {
      preLoadingContainer.parentNode.removeChild(preLoadingContainer);
    }

    if (fallbackContainer) {
      fallbackContainer.parentNode.removeChild(fallbackContainer);
    }

    // If we are on a project, the project reducer will take care of the organization loading
    if (
      organizationEnabled &&
      this.props.organizationId &&
      !this.props.projectId &&
      !this.props.organizations[this.props.organizationId]
    ) {
      this.props.loadOrganization(this.props.organizationId);
    }
    if (
      billingEnabled &&
      organizationEnabled &&
      this.props.organizations[this.props.organizationId]?.name
    ) {
      this.props.loadOrganizationPaymentSource(this.props.organizationId);
    }

    if (this.props.projectId && this.props.organizationId) {
      this.props.loadEnvironments(
        this.props.projectId,
        this.props.organizationId
      );
    }

    const subscriptionId = this.props?.project?.getSubscriptionId();
    if (subscriptionId) {
      this.props.loadSubscription(
        this.props.organizationId,
        this.props.projectId,
        subscriptionId
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const organizationEnabled = getFeatureFlag("ENABLE_ORGANIZATION");
    // If we are on a project, the project reducer will take care of the organization loading
    if (
      organizationEnabled &&
      nextProps?.organizationId !== this.props?.organizationId &&
      !nextProps?.projectId &&
      !this.props.organizations[this.props.organizationId]
    ) {
      this.props?.loadOrganization(nextProps.organizationId);
    }

    if (
      nextProps?.project &&
      this.props?.project &&
      nextProps?.project?.id !== this.props?.project?.id
    ) {
      const subscriptionId = nextProps.project?.getSubscriptionId();
      this.props?.loadSubscription(
        nextProps?.organizationId,
        nextProps?.project?.id,
        subscriptionId
      );
    }
    const hasProjectIdChanged =
      nextProps?.projectId && nextProps?.projectId !== this.props?.projectId;
    const hasOrganizationIdChanged =
      nextProps?.organizationId &&
      nextProps?.organizationId !== this.props?.organizationId;
    if (hasProjectIdChanged || hasOrganizationIdChanged) {
      this.props.loadEnvironments(
        nextProps.projectId,
        nextProps.organizationId
      );
    }
  }

  componentDidUpdate(prevProps) {
    const projId = this.props.project ? this.props.project.id : null;
    const prevProjId = prevProps.project ? prevProps.project.id : null;

    const shouldLoadSubscription =
      projId !== prevProjId &&
      this.props.subscription === prevProps.subscription;
    if (shouldLoadSubscription && this.props.project) {
      const subscriptionId =
        this.props.project && this.props.project?.getSubscriptionId();
      this.props.loadSubscription(
        this.props.organizationId,
        this.props.project.id,
        subscriptionId
      );
    }
  }

  getEnvironmentPathList = () => {
    const { environmentId, environments, environmentTree } = this.props;
    if (environmentTree.length === 0 || environments.length === 0) return [];

    const environment = environments?.find(env => env.id === environmentId);
    if (environment) {
      return [
        {
          id: encodeURIComponent(environment[ENVIRONMENT_ID_FIELD]),
          name: environment.name,
          title: environment.title
        }
      ];
    }

    return [];
  };

  render() {
    const {
      projectId,
      organizationId,
      username,
      push,
      projects,
      isLoadingProjectList,
      project,
      environmentId,
      environment,
      currentPathName,
      subscription,
      toggleProjectWizard,
      organizations,
      me,
      insideNavConfig,
      testMode
    } = this.props;
    const user = me;
    const projectsArray = Object.values(projects);
    const projectOwner = isLoadingProjectList
      ? false
      : projectsArray.some(project =>
          isProjectOwner(project, user, organizations)
        );

    const organization = organizations[organizationId];
    let outsideNavbar = false;
    let insideNavbar = false;

    const projectBillingUrl = getUrl({
      key: "organization.project.usageBasedBilling",
      props: {
        organizationId: organizationId || "",
        projectId: projectId || "",
        subscriptionId: subscription?.id || ""
      }
    });

    const isProjectSettingsPage = currentPathName.startsWith(
      `/${organizationId}/${projectId}/-/settings`
    );

    const encodedEnvironmentId = encodeURIComponent(environmentId);
    const organizationEnabled = getFeatureFlag("ENABLE_ORGANIZATION");

    const isEnvironmentSettingsPage = currentPathName.startsWith(
      `/${organizationId}/${projectId}/${encodedEnvironmentId}/settings`
    );

    let classnames = [];
    if (
      currentPathName.includes("/create-project") ||
      currentPathName.includes("/billing")
    )
      classnames.push("user-menu-fix");

    if (
      SUPPORT_PATH_MATCH.test(currentPathName) ||
      BILLING_PATH_MATCH.test(currentPathName) ||
      SETTINGS_PATH_MATCH.test(currentPathName)
    )
      classnames.push("settings");

    if (
      insideNavConfig?.length ||
      BILLING_PATH_MATCH.test(currentPathName) ||
      SETTINGS_PATH_MATCH.test(currentPathName) ||
      new RegExp(
        `^/${organizationId}/-/(settings|billing)|^${projectBillingUrl}`
      ).test(currentPathName)
    ) {
      insideNavbar = (
        <InsideNavBar
          tabConfig={insideNavConfig}
          username={username}
          organizationId={organizationId}
          currentPathName={currentPathName}
          organization={organization}
          projectId={projectId}
        />
      );
    } else if (isProjectSettingsPage || isEnvironmentSettingsPage) {
      outsideNavbar = false;
    } else if (
      project &&
      (!environment?.id ||
        [
          `/${organizationId}/${projectId}/${encodedEnvironmentId}/settings`,
          `/${organizationId}/${projectId}/${encodedEnvironmentId}/settings/variables`
        ].includes(decodeURIComponent(currentPathName)))
    ) {
      outsideNavbar = (
        <ProjectNavBar
          toggleProjectWizard={toggleProjectWizard}
          subscription={subscription}
          organizationId={organizationId}
          projectId={projectId}
          environmentId={environmentId}
          project={project}
          environment={environment}
        />
      );
    } else if (
      environment &&
      environment.id &&
      typeof environment.hasLink === "function"
    ) {
      outsideNavbar = (
        <EnvironmentNavBar
          push={push}
          organizationId={organizationId}
          projectId={projectId}
          environmentId={environmentId}
          project={project}
          environment={environment}
          currentPathName={currentPathName}
        />
      );
    } else if (
      !project &&
      (currentPathName === "/" ||
        new RegExp(`^/${organizationId}/{0,1}$`).test(currentPathName))
    ) {
      outsideNavbar = <SearchBar organizationId={organizationId} />;
    }
    const showTestTrial =
      testMode?.trial?.startDate !== "" ||
      testMode?.trial?.expirationDate !== "" ||
      testMode?.trial?.status !== "";

    const hasDivider =
      organizationEnabled &&
      currentPathName !== "/" &&
      currentPathName !== `/${organizationId}` &&
      !currentPathName.startsWith("/-/users");

    return (
      <NavBarLayout className={classnames.join(" ")}>
        {(!organizationEnabled && !showTestTrial) ||
          ((showTestTrial || (organizationEnabled && organizationId)) && (
            <BannerManager
              isProjectOwner={projectOwner}
              organizationId={organizationId}
              projects={projectsArray}
              user={user}
            />
          ))}
        <PageHeader
          id="page-header"
          reduceHeight={isProjectSettingsPage || isEnvironmentSettingsPage}
        >
          <MaxWidthContainer>
            <div className="navigation-bar" role="navigation">
              <NavBarLeftLayout>
                <Logo variant={project ? "withBreadcrumbs" : "standalone"} />

                {organizationEnabled && (
                  <OrganizationsSwitcher organizationId={organizationId} />
                )}

                <DunningTag
                  organizationName={organizationId}
                  status={organization?.status}
                />

                <OrganizationDivider
                  hasDivider={hasDivider}
                  className="organization-divider"
                />
                <Breadcrumbs
                  currentPath={currentPathName}
                  environmentPath={this.getEnvironmentPathList()}
                  hiddenXs
                  projectName={project?.title}
                />
              </NavBarLeftLayout>
              <NavBarRightLayout>
                <HelpMenu accountUrl={config.URL_ACCOUNTS} user={user} />
                {announcements}

                {user && (
                  <Menu
                    breadcrumbs={
                      <Breadcrumbs
                        currentPath={currentPathName}
                        environmentPath={this.getEnvironmentPathList()}
                        hiddenXs
                        projectName={project?.title}
                      />
                    }
                    accountUrl={config.URL_ACCOUNTS}
                    user={user}
                    organizations={organizations}
                    organizationId={organizationId}
                  />
                )}
              </NavBarRightLayout>
            </div>
          </MaxWidthContainer>
          {insideNavbar}
        </PageHeader>
        {outsideNavbar}
      </NavBarLayout>
    );
  }
}

const mapStateToProps = (state, props) => {
  const me = meSelector(state);
  const project = gitProjectSelector(state, {
    organizationId: props.organizationId,
    projectId: props.projectId
  });
  const subscriptionId = project?.getSubscriptionId();

  const allProjects = selectProjectsFromAllOrgs(state);

  const hasBillingPermission = hasBillingPermissionSelector(state);

  return {
    hasBillingPermission,
    projects: allProjects,
    project,
    isLoadingProjectList: getIsLoadingListProjectsSelector(state),
    environment: environmentSelector(state, {
      organizationId: props.organizationId,
      projectId: props.projectId,
      environmentId: props.environmentId
    }),
    environments: environmentsAsArraySelector(state, {
      organizationId: props.organizationId,
      projectId: props.projectId
    }),
    environmentTree: environmentTreeSelector(state, {
      organizationId: props.organizationId,
      projectId: props.projectId,
      environmentId: props.environmentId
    }),
    subscription: getFeatureFlag("ENABLE_ORGANIZATION")
      ? state?.organizationSubscription?.data?.[props.organizationId]?.[
          subscriptionId
        ]
      : state?.subscription?.data?.[props.organizationId]?.[subscriptionId],
    organizations: state.organization.orgByDescriptionField,
    deletedSubscription: state.subscription?.deleted,
    announcementsError: state.announcements.error,
    me,
    testMode: state.testMode
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  loadEnvironment: (
    currentProjectOrganization = props.organizationId,
    currentEnvironmentId = props.environmentId
  ) =>
    dispatch(
      loadEnvironment({
        environmentId: currentEnvironmentId,
        projectId: props.projectId,
        organizationId: currentProjectOrganization
      })
    ),
  loadEnvironments: (projectId, organizationId) =>
    dispatch(loadEnvironments({ projectId, organizationId })),
  loadOrganization: organizationId => {
    import("Reducers/organization").then(organizationReducer =>
      dispatch(organizationReducer.loadOrganization(organizationId))
    );
  },
  loadOrganizationPaymentSource: organizationId => {
    import(
      "Reducers/organization/paymentSource/thunks/getOrganizationPaymentSource.thunk"
    ).then(paymentSource =>
      dispatch(paymentSource.getOrganizationPaymentSource({ organizationId }))
    );
  },
  toggleProjectWizard: () => {
    const { push, currentPathName, projectId, organizationId } = props;

    if (currentPathName.includes(`/${organizationId}/${projectId}/settings`)) {
      dispatch(setForceOpen(true));
      push(`/${organizationId}/${projectId}`);
    } else {
      dispatch(toggleProjectWizard({ organizationId, projectId }));
    }
  },
  loadSubscriptions: user =>
    getFeatureFlag("ENABLE_ORGANIZATION")
      ? dispatch(
          loadOrganizationSubscriptions({
            organizationId: props.organizationId
          })
        )
      : dispatch(loadSubscriptions({ organizationId: user?.username })),
  loadSubscription: (organizationId, projectId, id) =>
    dispatch(
      (getFeatureFlag("ENABLE_ORGANIZATION")
        ? loadOrganizationSubscription
        : loadSubscription)({ organizationId, projectId, id })
    )
});

NavBar.propTypes = {
  announcementsError: PropTypes.string,
  currentPathName: PropTypes.string,
  currentProjectOrganization: PropTypes.string,
  deletedSubscription: PropTypes.object,
  environment: PropTypes.object,
  environmentId: PropTypes.string,
  environments: PropTypes.array,
  environmentTree: PropTypes.array,
  isLoadingProjectList: PropTypes.bool,
  loadEnvironment: PropTypes.func,
  loadOrganization: PropTypes.func,
  loadEnvironments: PropTypes.func,
  loadProjects: PropTypes.func,
  loadSubscription: PropTypes.func,
  loadSubscriptions: PropTypes.func,
  me: PropTypes.object,
  organizationId: PropTypes.string,
  project: PropTypes.object,
  projectId: PropTypes.string,
  username: PropTypes.string,
  projects: PropTypes.object,
  push: PropTypes.func,
  subscription: PropTypes.object,
  organizations: PropTypes.object,
  toggleProjectWizard: PropTypes.func,
  updateUserProfile: PropTypes.func,
  user: PropTypes.object,
  insideNavConfig: PropTypes.array,
  testMode: PropTypes.object,
  hasBillingPermission: PropTypes.bool,
  loadOrganizationPaymentSource: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
