/*eslint quote-props: ["error", "always"]*/

const en: Record<string, string> = {
  "tabs.overview": "overview",
  "tabs.metrics": "metrics",
  "tabs.profiling": "profiling",
  "tabs.http_traffic": "HTTP Traffic",
  "tabs.observability_sandbox": "Obs. Sandbox",
  "tabs.activities": "activities",
  "tabs.services": "services",
  "tabs.backups": "backups",
  "tabs.settings": "settings",
  "tabs.profile": "my profile",
  "tabs.processes": "Processes",
  "tabs.variables": "variables",
  "tabs.certificates": "certificates",
  "tabs.deploy_key": "deploy key",
  "tabs.basics": "General",
  "tabs.domains": "domains",
  "tabs.environment_types": "environment types",
  "tabs.access": "access",
  "tabs.general": "General",
  "tabs.routes": "routes",
  "tabs.access_control": "access control",
  "tabs.project": "project",
  "tabs.teams": "teams",
  "tabs.billing": "billing",
  "tabs.configuration": "configuration",
  "tabs.dashboard": "dashboard",
  "tabs.all_activity": "activity",
  "tabs.activity": "activity",
  "tabs.projects": "projects",
  "tabs.organizations": "organizations",
  "tabs.integrations": "integrations",
  "tabs.members": "members",
  "tabs.invitations": "invitations",
  "tabs.credit_card": "credit card",
  "tabs.sepa": "bank account",
  "tabs.billing_address": "billing address",
  "tabs.ssh_keys": "SSH keys",
  "tabs.plan": "Plans",
  "tabs.organization_billing": "Overview",
  "tabs.project_billing_details": "Billing details",
  "tabs.details": "Billing Details",
  "tabs.history": "Invoices",
  "tabs.backups_history": "History",
  "tabs.backups_stored": "Stored",
  "tabs.accounts": "Connected accounts",
  "tabs.organization_security": "Security",
  "tabs.security": "Authentication Settings",
  "tabs.tokens": "API Tokens",
  "tabs.ssh-keys": "SSH Keys",
  "tabs.edit_plan": "Edit plan",
  "tabs.billing_history": "history",
  "tabs.payment_details": "Payment details",
  "tabs.connected_accounts": "Connected accounts",
  "tabs.vouchers": "Vouchers",
  "tabs.crons": "Crons",
  "tabs.users": "Users",
  "tabs.commitment": "Commitment",
  theme: "Theme",
  light: "Light",
  dark: "Dark",
  WCAG: "WCAG",
  "pricing.page": "pricing page",
  project_overview: "Project overview",
  "project.overview.wizard": "Finish setup",
  "project.overview.domain": "Assign a domain",
  project_admin: "project admin",
  project_name: "Project name",
  production_environment: "Production environment",
  production_environment_name: "Production environment name",
  "production_environment_name.github": "GitHub branch for production",
  "production_environment_name.details":
    "This branch will be connected to your production environment on {app_name}.",
  "production_environment_name.dialog.title.github":
    "GitHub branch for production",
  "production_environment_name.dialog.info.github":
    "Select the branch from your Git repository that will be used for your production environment in Upsun. You'll be able to change it later.",
  production_environment_info:
    "Name your future production environment. This will be your default Git branch, which is important for connecting your repositories. Projects usually use 'main' as their default Git branch.",
  production_environment_learn_more: "Learn more",
  project_information: "Create your project",
  projects_filters_title: "Filter",
  projects_filters_clear: "Clear",
  projects_filters_region: "Region",
  projects_filters_infrastructure: "Infrastructure",
  projects_filters_plan_size: "Plan size",
  projects_filters_empty_title: "No projects found",
  projects_filters_empty_description:
    "Try adjusting your filters to see more projects",
  projects_filters_empty_reset: "Reset filters",
  projects_filters_region_tooltip:
    "You can’t filter by Region for Dedicated projects",
  projects_filters_plan_tooltip:
    "You can’t filter by Plan size for Dedicated projects",
  building: "building",
  building_redirect:
    "As soon as your project is ready, you will be redirected to it.",
  building_no_redirected: "If you are not redirected, please",
  click_here: "click here",
  to_be: "to be",
  back_to_projects: "Back to projects",
  back_to_users: "Back to users",
  back_to_integrations: "Back to Integrations",
  back_to: "Back to",
  "back.profiling": "to Profiling",
  "back.vouchers": "to vouchers",
  "back.domains": "to Domains",
  "back.httplog": "to HTTP Log",
  senior_engineers: "senior engineers",
  teams: "teams",
  developers: "developers",
  qa: "qa",
  collaborators: "collaborators",
  environments: "Environments",
  "dashboard.search_placeholder": "Search projects",
  "environment_services.search_placeholder": "Search apps and services",
  search_templates: "Search all templates",
  teams_org: "Organizations",
  projects: "Projects",
  project: "Project",
  live: "Live",
  button_go_live: "Go live",
  documentations: "docs",
  help: "Get help",
  help_text: "Help",
  support: "Support",
  accounts_settings: "accounts settings",
  add_domain: "add",
  "add.to.project": "Add to project",
  add_sshkey: "add ssh key",
  "sshkey.form_input_title": "Title",
  "sshkey.form_input_key": "Key",
  "textarea.placeholder.default": "Enter {label} here...",
  edit_domain: "edit domain",
  added_domain: "added domain",
  updated_environment_variable: "updated environment variable",
  updated_domain: "updated domain",
  deleted_domain: "deleted domain",
  domain_name: "Domain name",
  changed_project_name_to: "changed project name to",
  added_project_variable: "added project variable",
  deleted_project_variable: "deleted project variable",
  updated_project_variable: "updated project variable",
  modified_project_variable: "modified project variable",
  setup_fastly_origin_restriction: "setup fastly origin restriction",
  disable_fastly_origin_restriction: "disabled fastly origin restriction",
  created_a_new_project: "created a new project",
  added: "added",
  to: "to",
  into: "into",
  from: "from",
  removed: "removed",
  added_route: "added route",
  deleted_route: "deleted route",
  environment_text: "environment",
  paused_text: "paused",
  resumed_text: "resumed",
  resources_updated_text: "updated",
  resources_allocation_text: "resource allocation",
  resource_allocation_filter: "Resource Allocation",
  resources_on_text: "on",
  modified_route: "modified route",
  modified_subscription: "modified subscription",
  updated_HTTP_Access_settings_on_environment:
    "updated HTTP Access settings on environment",
  "updated_the_robots.txt_settings_on_environment":
    "updated the robots.txt settings on environment",
  updated_SMTP_settings_on_environment: "updated SMTP settings on environment",
  added_environment_variable: "added environment variable",
  deleted_environment_variable: "deleted environment variable",
  modified_environment_variable: "modified environment variable",
  activated_environment: "activated environment",
  branched_environment: "branched environment",
  created_environment: "created environment",
  created_backup: "created backup",
  deleted_backup: "deleted backup",
  creating_backup: "is creating a backup",
  attempt_create_backup: "attempted to create a backup",
  deactivated_environment: "deactivated environment",
  "deactivate_environment.button": "Deactivate environment and delete data",
  deleted_environment: "deleted environment",
  initialized_environment: "initialized environment",
  with_profile: "with profile",
  merged_pull_request_from: "merged pull request from",
  redeployed_environment: "redeployed",
  renewal_environment_certificate: "renewed TLS certificates for",
  unknown_activity: "unknown activity",
  "merge.title": "Merge to {parentEnvironment}",
  "merge.dialog.cli.description":
    "You can also use this terminal command to merge {currentEnvironment} into your {parentEnvironment} environment.",
  "merge.question":
    "Merge your environment {currentEnvironment} into your {parentEnvironment} environment.",
  "merge.question.sizing_api_enabled":
    "Are you sure you want to merge {currentEnvironment} into {parentEnvironment}? By default new applications and services will be allocated the resources that are defined in the {currentEnvironment} environment.",
  "merge.radioGroup.description":
    "Resource initialization strategy for {parentEnvironment} environment.",
  "merge.radioBtn.child": "Use child environment resources",
  "merge.radioBtn.default": "Use default resources",
  "merge.radioBtn.minimum": "Use minimum resources",
  "merge.radioBtn.manual": "Manually configure resources after merge",
  "merge.cli": "CLI",
  "merge.cli.sizing_api_enabled": "CLI Command",
  "merge.command": "{commandName} merge {action}",
  merged: "merged",
  merge: "Merge",
  backups: "Backups",
  pushed_to: "pushed to",
  restored: "restored",
  restored_backup: "restored backup",
  restored_to_environment: "to environment",
  with: "with",
  source_code: "source code",
  environment_health: "environment health",
  metrics: "metrics",
  requests_per_min: "requests per min",
  cpu_cores: "cpu cores",
  bandwidth_mb_sec: "bandwidth MB/sec",
  view_metrics: "View graphs",
  environment_info: "environment info",
  info: "info",
  critical_issues: "critical issues",
  latest_deployment: "latest deployment",
  created_on: "created on",
  backup: "Backup",
  latest_backup: "latest backup",
  backup_scheduling: "backup scheduling",
  deployment_agreement:
    "by continuing, I understand that this project and its usage costs will be added to my monthly invoices and charged to the credit card on file for this account.",
  region_requirement:
    "we have selected this region based on your current location. Select another option below if you would like to change this before continuing.",
  empty_repository: "empty repository",
  import_your_repository: "import your repository",
  connect_to_an_external_service: "connect to an external service",
  or_enter_a_repository_url: "or enter a repository URL",
  connect_to_github: "connect to GitHub",
  repository_url: "repository URL",
  connect_to_bitbucket: "connect to Bitbucket",
  my_x_project: "my {brandName} project",
  sync_child_from_parent: "sync {child} from {parent}",
  merge_child_from_parent: "merge {child} from {parent}",
  data: "data",
  "sync.title": "Sync from {parentEnvironment} to {currentEnvironment}",
  "sync.description":
    "What would you like to sync from your {parentEnvironment} environment to your {currentEnvironment} environment?",
  "sync.replace_data": "Replace data",
  "sync.data":
    "Replace the data (e.g. databases, uploaded files) of {currentEnvironment} with that from {parentEnvironment}",
  "sync.data.sizing_api_enabled": "Replace data (databases, uploaded files)",
  "sync.data.dialog":
    "Replacing data will also sync the disk size if {currentEnvironment} has a lower disk size. This will ensure enough disk to complete the sync.",
  "sync.code": "Git merge {parentEnvironment} to {currentEnvironment}",
  "sync.sync_resources": "Sync resources",
  "sync.resources":
    "Sync resources from {parentEnvironment} into {currentEnvironment}",
  "sync.resources.dialog":
    "You can additionally sync the CPU and RAM from this environment. The disk size is synced by default to ensure that the sync is successful.",
  "sync.cli": "CLI",
  "sync.cli.sizing_api_enabled": "CLI command",
  "sync.dialog.cli.description":
    "You can also use this terminal command to sync from {parentEnvironment} to {currentEnvironment}",
  "sync.dialog.cli.requirement": "To sync an env need to have a parent",
  "sync.button": "sync",
  "sync.select": "Please make a selection",
  "actions.description":
    "Create a byte-level copy of {environmentName} cluster with all of its code, data and services.",
  "modal.confirm.leave.form.title": "Are you sure you want to leave?",
  "modal.confirm.leave.form.detail":
    "There are unsaved changes in this form that could be lost.",
  view_documentation: "View documentation",
  syncing: "syncing",
  sync: "Sync",
  synced: "synced",
  initiated_sync: "initiated sync of",
  x_commit_ahead:
    "{commit, number} {commit, plural, one {commit} other {commits}} ahead",
  more_commit_toggle:
    "{commits} {commits, plural, one {commit} other {commits}}",
  hide_commits: "Hide",
  "merge.button": "merge",
  merging: "merging",
  "backup.button": "Backup",
  "backup.title": "Backup",
  "configure.thresholds": "Configure thresholds",
  "configure.alarm.thresholds": "Configure alarm thresholds",
  "duration.of": "for a duration of",
  "resource.lower.threshold": "CPU lower threshold",
  "resource.upper.threshold": "CPU upper threshold",
  "configure_resources.page.title": "Configure resources",
  "configure_resources.page.environments": "Select environment",
  "configure_resources.page.text":
    "Configure your app or service resources to suit your needs. Changes will be applied after you click save and your environment is redeployed. Build resources can be configured in the <link>project resources</link>.",
  "configure_resources.page.container_sizes": "Container sizes",
  "configure_resources.page.dialog.text":
    "Changing your resources will trigger an environment redeploy.",
  "configure_resources.page.cpu_ram.placeholder": "Select CPU and RAM",
  "configure_resources.page.dialog.link.text": "Documentation",
  "configure_resources.page.dialog.cpu&ram.title":
    "Advanced container profiles",
  "configure_resources.page.dialog.cpu&ram.description":
    "You can change the container profile of an application or service in your Yaml configuration file.",
  "configure_resources.page.dialog.instances.title": "Container instances",
  "configure_resources.page.dialog.instances.description":
    "Instances are copies of the same container that can be added to provide horizontal scaling for higher availability. All instances use the same storage.",
  "configure_resources.page.clear": "Clear changes",
  "configure_resources.page.apps_n_services": "Apps and services",
  "configure_resources.page.cpu_n_ram": "CPU & RAM",
  "configure_resources.page.cpu_n_ram.error": "CPU and RAM is required",
  "configure_resources.page.disk.error": "Disk is required",
  "configure_resources.page.disk.placeholder": "ex 0.5 GB",
  "configure_resources.page.disk.services.tooltip": "Minimum {minDisk} GB",
  "configure_resources.page.disk.services.tooltip.no.minimum": "No minimum",
  "configure_resources.page.instances": "Instances",
  "configure_resources.page.disk_n_storage": "Disk / storage",
  "configure_resources.page.disk.units.label": "GB",
  "configure_resources.page.note":
    "Note: Changing container sizes will trigger a deployment on this environment and impact your monthly project cost. <link>View pricing</link>",
  "configure_resources.page.note.trial":
    "Note: Changing container sizes will trigger a deployment on this environment. Free trial resources are limited to up to 1 project, 2 environments, 4.5 CPU, 12GB RAM, and 20GB Disk.",
  "configure_resources.page.total": "Total",
  "configure_resources.page.total.quantity.instances":
    "{quantity} {quantity, plural, one {instance} other {instances}}",
  "configure_resources.page.notification.title":
    "Resources updated and deploying",
  "configure_resources.page.notification.description":
    "The resources on <strong>{environmentName}</strong> have been updated and the changes are being deployed.",
  "configure_resources.page.notification.linkTitle":
    "View {environmentName} environment",
  "configure_resources.page.total.quantity_type": "{quantity} {type}",
  "configure_resources.page.total.category": "{category}",
  "configure_resources.page.reduce.warning":
    "Reducing the size of your containers could negatively impact your application's performance.",
  "configure_resources.page.trial_resources.warning":
    "Your free trial includes {project} {project, plural, one {project} other {projects}}, {environment} {environment, plural, one {environment} other {environments}}, {cpu} CPU, {ram} GB RAM and {disk} GB Disk.",
  "configure_resources.page.billing.warning":
    "To configure your project resources you need to add your billing details, as your free trial has expired.",
  "configure_resources.page.no_resources.warning":
    "Resource configurations were not found. There could be an error with one of your yaml config files. For more information, <link>read our documentation</link>.",
  "configure_resources.page.admin.update.resources.warning":
    "Ask your project admin to update the resources on this environment.",
  "configure_resources.page.resources.update.in_progress.warning":
    "A resource update activity is currently in progress. To change resources on this environment, please check back after that activity has completed.",
  "configure_resources.page.inactive.environment.warning":
    "This environment is inactive. Making a change to the resources will also activate this environment.",
  "configure_resources.page.no_resources.description":
    "Configure your app or service resources to scale vertically and horizontally. Changes will not be made until saving, where a deployment will be triggered.",
  "configure_resources.page.no_resources.note":
    "No apps or services are configured.",
  "configure_resources.close": "Close",
  "configure_resources.upgrade": "Upgrade your account",
  "configure_resources.banner.sentence":
    "Subscribe to Upsun to access more resources.",
  "overview.no.configured.resources":
    "Configure your resources to initialize or deploy your {environment} environment. <link>Configure resources</link>",
  "backup_schedule.title": "Manage backup schedule",
  "backup_schedule.description":
    "Customize multiple backups by environment type to suit your needs. Backup schedule intervals must be configured in clean multiples (2,4,6) rather than (2,5,7). Please note that the size, frequency and retention policy you set will impact your backup storage costs. Learn more in our <link>Documentation</link>.",
  "backup_schedule.environment_type.label": "Environment type",
  "backup_schedule.max_manual_backups.label": "Maximum manual backups",
  "backup_schedule.column.interval.label": "Backup interval",
  "backup_schedule.column.backups.label": "#Backups",
  "backup_schedule.column.every.label": "Take backups every",
  "backup_schedule.column.placeholder": "#",
  "backup_schedule.column.maximum": "Maximum {maxBackups}",
  "backup_schedule.column.up_to.label": "and store up to a total of",
  "backup_schedule.column.up_to.placeholder": "Select",
  "backup_schedule.column.stored.label": "Total backups stored",
  "backup_schedule.row.name": "Schedule {number}",
  "backup_schedule.row.name.dialog.title": "Backup scheduling",
  "backup_schedule.row.name.dialog.description":
    "Backup intervals must be multiples of one another. E.g., if set at every 2 hrs, the next interval would be 4 or 6 hrs.",
  "backup_schedule.row.name.dialog.example.title": "Example:",
  "backup_schedule.row.name.dialog.example.description.1":
    "2 hrs, 4 hrs, 8 hrs",
  "backup_schedule.row.name.dialog.example.description.2":
    "1 day, 1 wk (7 days), 2 wks (14 days)",
  "backup_schedule.row.name.dialog.invalid.example.title": "Invalid example:",
  "backup_schedule.row.name.dialog.invalid.example.description.1":
    "2 hrs, 5 hrs, 8 hrs",
  "backup_schedule.row.name.dialog.invalid.example.description.2":
    "3 days, 10 days, 2 wks (14 days)",
  "backup_schedule.row.name.error":
    "Intervals must be a multiple of one another",
  "backup_schedule.btn.remove": "Remove",
  "backup_schedule.btn.revert": "Revert to default",
  "backup_schedule.agreement":
    "I understand that changes made will be applied to all environments of this type, and reducing the number of backups could delete older backups.",
  "backup_schedule.note":
    "Note: Customizing your backup schedule will impact your monthly project cost. <link>View pricing page</link>",
  "backup_schedule.notify.title": "Backup schedule has been updated",
  "backup_schedule.notify.description":
    "The backup schedule for {environmentType} environments was successfully updated.",
  "backup_schedule.max_manual_backups.tooltip.title": "Maximum manual backups",
  "backup_schedule.max_manual_backups.tooltip.description":
    "Set the number manual backups that can be stored before new manual backups will overwrite the oldest.",
  branch: "Branch",
  redeploy: "Redeploy",
  "redeploy.modal.title": "Redeploy environment",
  "redeploy.modal.text":
    "Redeploying an environment applies configuration changes, such as access rights and renewed SSL certificates, without pushing new code.",
  "redeploy.modal.note":
    "Note: The redeploy will happen after any scheduled builds.",
  "redeploy.modal.dialog":
    "You can also use this terminal command to redeploy your environment",
  "redeploy.command": "{commandName} environment:redeploy",
  "redeploy.cli": "CLI",
  "redeploy.button": "Redeploy",
  resume: "Resume",
  "resume.modal.title": "Resume environment",
  "resume.modal.text":
    "Your environment is paused, resume it to make it available again.",
  "resume.modal.note": "Note: The redeploy will happen soon.",
  "resume.modal.dialog":
    "You can also use this terminal command to resume your environment",
  "resume.command": "{commandName} environment:resume",
  "resume.cli": "CLI",
  "resume.button": "Resume",
  "branch.button": "create branch",
  "branch.button.sizing_api_enabled": "Branch",
  "branch.create.prompt":
    "Branching will create a new branch in the Git repository and an exact copy of your environment. Name your branch below to get started.",
  "branch.create.prompt.sizing_api_enabled":
    "Create a new preview environment as a copy of your {environmentType} environment.",
  branching: "branching",
  branching_from: "Branching from {environment}",
  "branch.cli.command":
    "{commandName} branch {branchName} {environmentId} {type}",
  "branch.cli.command.sizing":
    "{commandName} branch {branchName} {environmentId} {type} --resources-init={radioSelection}",
  "branch.cli.command.sizing.parent":
    "{commandName} branch {branchName} {environmentId} {type}",
  "branch.name.placeholder": "Branch name",
  "branch.name.placeholder.sizing_api_enabled": "Name",
  "branch.name.label": "Branch name",
  "branch.name.label.sizing_api_enabled": "Environment name",
  "branch.cli": "CLI",
  "branch.cli.sizing_api_enabled": "CLI command",
  branch_environmentName: "branch {environment}",
  "branch.dialog.cli.description":
    "You can also use this terminal command to branch from {environmentName}",
  "branch.radioGroup.description":
    "Resource initialization strategy for your new preview environment.",
  "branch.radioBtn.parent": "Use parent environment resources",
  "branch.radioBtn.default": "Use default resources",
  "branch.radioBtn.minimum": "Use minimum resources",
  "branch.radioBtn.manual": "Manually configure resources after branch",
  refresh: "refresh",
  enabled: "Enabled",
  "enabled.description":
    "Variables can be enabled or disabled per environment.",
  is_default: "Make default domain",
  "no_access.project.make_default_domain":
    "Ask your project admin to edit a domain",
  "no_access.project.delete_domain":
    "Ask your project admin to remove a domain",
  environment_type: "Environment type",
  environment_types: "environment types",
  environment_settings: "Environment settings",
  add_environment_type: "add environment type",
  environment_type_name: "environment type name",
  no_delete_permission:
    "Ask your project admin to delete the environment. <link> Learn more</link>",
  delete_environment: "Delete environment",
  delete_confirm_text:
    "Are you sure you want to delete the environment {environmentName} from {projectName}? Type the environment name to confirm that you want to delete this environment.",
  env_name_delete: "Environment name",
  "env_name_delete.placeholder": "Name",
  deactivate_environment: "Deactivate environment",
  deactivate_confirm_button: "Deactivate environment and delete data",
  delete_confirm_button: "Delete environment",
  deactivate_confirm_text:
    "Are you sure you want to deactivate the environment",
  deactivate_button: "Deactivate & Delete Data",
  activate_button: "Reactivate environment",
  max_envs_limit:
    "You’ve reached your environment limit. Upgrade your plan in order to reactivate this environment.",
  branch_max_env:
    "You have reached the resources limit for this organization's trial.",
  "disable.thresholds.configuration.info.title": "Disable thresholds",
  "disable.thresholds.configuration.info":
    "Retain your configurations, but remove the graph indicators and disable the activity creation when threshold is breached.",
  cannot_create_branch: "Cannot create a new branch",
  upgrade: "Upgrade",
  production_environment_text:
    "This is your production environment. You cannot deactivate or delete this environment. <link>Learn more</link>",
  configure_environment:
    "Configure your environment name and its parent environment.",
  activate_environment: "Activate environment",
  change_email_settings: "Change email settings",
  delete_environment_description:
    "While the branch is available, you can activate this environment at a later time. Reactivating will sync data from the parent environment and redeploy.",
  status_inactive_description:
    "This environment is not deployed on each Git push. It has no data of its own and no running services. <link>Learn more</link>",
  "status_active.title": "Status is <b>Active</b>",
  "status_active.description":
    "This environment is deployed like normal on each Git push.",
  "status_inactive.title": "Status is <b>Inactive</b>",
  "status_reactivating.title": "Environment is <b>Reactivating</b>",
  "status_deactivating.title": "Environment is <b>Deactivating</b>",
  "status_building.title": "Environment is <b>Building</b>",
  delete_git_branch_text:
    "If you would like to remove the branch completely, you need to use Git and run:",
  cli_command: "CLI command",
  parent_environment: "Parent environment",
  delete_git_branch: "delete Git branch",
  delete_git_branch_description: "also deletes Git branch",
  logins: "logins",
  usernames: "usernames",
  "https_access.description":
    "To redirect access to your environment's website, please add either login or IP address details. You can disable this at any time without losing your credentials.",
  ip_addresses: "IP Address",
  ip_addresses_description1: "Enter one IP address or range per line.",
  ip_addresses_description2:
    "Enter one IP address per line. Separate the IP address from the permission by a space. ",
  for_example: "E.g.",
  off: "Off",
  on: "On",
  or: " or ",
  "is_default.description":
    "Enabling this will unset the existing default domain and use this domain as the new default. There can only be 1 default domain.",
  is_sending_email: "Email sending",
  email_sending:
    "<b>Enable outgoing emails:</b> This allows your application to send emails via a SendGrid-based SMTP proxy.",
  "email_sending.title": "<b>Enable outgoing emails</b>",
  "email_sending.description":
    "This allows your application to send emails via a SendGrid-based SMTP proxy",
  hidden: "Hidden",
  visible: "Visible",
  search_engine_index: "Search engine index",
  "environment.search": "Search environments",
  "organizations.search": "Search organizations",
  "environment.overview.crons": "Crons",
  "environment.overview.crons.status.paused": "Paused",
  "environment.overview.crons.status.running": "Running",
  "environment.overview.crons.status.sleeping": "Paused",
  "environment.overview.crons.sleeping":
    "Crons are paused, as there’s been 14 days of no deployment activity on this environment.",
  "environment.settings.paused__or_active_env.title":
    "Status is <strong>{status}</strong>",
  "environment.settings.pausedEnv.btn": "{status} environment",
  "environment.settings.active.description":
    "Each git push to this environment triggers a new deployment.",
  "environment.settings.paused.description":
    "The environment was paused after two weeks of inactivity, which reduces its electricity consumption/carbon emissions.",
  "is_hidden.info.title": "Configure search engine visibility",
  "is_hidden.info.description":
    "For more information on how your environments are hidden from search engines visit our",
  "is_hidden.description2":
    "This is on by default for all development environments. It can only be turned off on your production environment if it is not live (does not have a domain assigned).",
  "is_hidden.info.link": "Documentation",
  is_hidden:
    "<b>Hide from search engines: </b>You can hide and ignore your site entirely from search engines, even if it is publicly visible.",
  "is_hidden.title": "<b>Hide from search engines</b>",
  "is_hidden.description":
    "You can hide and ignore your site entirely from search engines, even if it is publicly visible.",
  password_length: "The password can't be shorter than minimum length 6",
  "password_length-error": "Password must be at least 6 characters.",
  "login_length-error": "Login must be at least 2 characters.",
  login_label: "Login",
  password_label: "Password",
  is_controlled:
    "<b>HTTP Access control enabled:</b> Restrict access to your environment's website by a username/password, or by IP address.",
  "is_controlled.title": "<b>HTTP Access control enabled</b> ",
  "is_controlled.description":
    "Restrict access to your environment's website by a username/password, or by IP address.",
  "is_controlled.missing_configs":
    "Please add Login/IP or disable access control",
  "environment.upgrade.admin.description":
    "To enable this environment, you will need to upgrade your plan settings, as you have used all of your allocated environments.",
  "environment.upgrade.standard.description":
    "To enable this environment, you will need to upgrade your plan settings, as you have used all of your allocated environments.  Please contact your project owner to upgrade your plan settings.",
  all_data_lost_warning:
    "ALL DATA IN THIS ENVIRONMENT WILL BE IRREVOCABLY LOST",
  "environment.status.active": "Active",
  "environment.status.dirty": "Building",
  "environment.status.disabled": "Disabled",
  "environment.status.inactive": "Inactive",
  "environment.status.deleting": "Deleting",
  "environment.status.paused": "Paused",
  "environment_status.warning":
    "This action will destroy all services running on this environment (PHP, MySQL, volumes...) so that only the Git branch remains.",
  "environment.status.inactive.title": "Environment inactive",
  "environment.status.inactive.main.title": "Main environment inactive",
  "environment.status.inactive.url": "URL unavailable",
  "environment.status.inactive.update":
    "This environment does not have a URL, as the status is inactive. You can update your status in general settings.",
  "environment.status.no_routes.title": "Environment has no routes",
  "environment.status.no_routes.main.title": "Main environment has no routes",
  "environment.status.no_routes.update":
    "This environment does not have a URL, as no routes have been defined. Define routes in your .platform/routes.yaml file.",
  latest_from_the_blog: "latest from the blog",
  view_all_blog: "View all blog",
  your_regions: "your regions",
  system_status: "system status",
  all_systems_operational: "all systems operational",
  not_all_systems_operational: "not all systems are operational",
  view_all_status: "View all status",
  updated_moment: "Updated {moment}",
  primary_text: "primary_text",
  secondary_text: "secondary_text",
  "project.notfound": "project not found",
  "environment.notfound": "environment not found",
  maintenance_message: "Maintenance message",
  project_status: "Project status",
  healthy_projects: "healthy projects",
  critical_projects: "critical projects",
  recent_projects: "recent projects",
  invoices: "Invoices",
  settings: "Settings",
  billing_details: "Billing details",
  upcoming_invoice: "Upcoming invoice",
  view_all: "View all",
  view_all_projects: "View all projects",
  all_projects: "All projects",
  add_project: "add project",
  add_projects: "Add projects",
  create_project: "Create project",
  create_first_project: "Create your first project",
  "create_first_project.trial":
    "Create your first project to start your free trial",
  create_first_project_description:
    "Use your own code, migrate a project, or use a project template to get started.",
  "create_first_project_description.paid":
    "Use your own code, migrate a project, or try a demo project to get started. Remember, with First Project Incentive, we’ll cover the equivalent costs of your first user license and project fee every month. To get started, just add on your resources.",
  create_your_organization: "Create your organization",
  members: "members",
  groups: "groups",
  save: "Save",
  save_continue: "save & continue",
  select_continue: "select & continue",
  save_changes: "save changes",
  cancel: "Cancel",
  accept: "Accept",
  override: "Override",
  name: "Name",
  active: "active",
  disabled: "disabled",
  upload: "upload",
  upload_image: "upload image",
  files: "files",
  ok: "ok",
  required_config: "required configuration",
  "label.field.required": "Required field",
  custom_form: "custom form",
  configure_app: "configure your application",
  confirm_deploy: "confirm & deploy",
  select_region: "select region",
  payment: "payment",
  http_access: "HTTP access",
  change: "change",
  build: "build",
  super_user: "super user",
  add_user_agreement:
    "I understand that adding user licenses will result in additional charges applied to the project's monthly bill.",
  add_custom_domain: "add domain",
  add_certificate: "add certificate",
  private_key: "Private key",
  public_key: "Public key certificate",
  ssl_chain: "Intermediate SSL certificates",
  optional: "optional",
  copied: "Copied",
  copy: "Copy",
  add: "Add",
  issues: "issues",
  loading: "loading",
  user: "user",
  add_member: "add member",
  logo: "logo",
  logout: "Logout",
  new: "New",
  organization_name: "Organization name",
  organization_label: "Organization label",
  organization_path: "Organization path",
  organization_create: "Create organization",
  organization_settings: "Settings",
  white_label: "white label",
  home: "home",
  production: "production",
  enterprise: "enterprise",
  redirect_to: "Redirect to",
  type: "type",
  urls: "URLs",
  enter_team_name: "enter team name",
  enter_team_name_example: "e.g. developers",
  view_team: "View team",
  remove_from_team: "remove from team",
  remove_team: "Remove team",
  "environment types": "environment types",
  permission_level: "permission level",
  ssh_access: "SSH access",
  modify_settings: "modify settings",
  project_settings: "project settings",
  manage_members: "manage members",
  manage_settings: "manage settings",
  manage_project: "manage project",
  upstream: "Upstream",
  cache: "cache",
  cache_description:
    "When a request comes with the same cache key, the cached response is reused.",
  cookies_to_whitelist: "cookies to whitelist",
  headers_to_whitelist: "headers to whitelist",
  default_ttl: "default ttl",
  ssi_description: "Leverage caching and serve dynamic content.",
  add_variable: "Add variable",
  edit_variable: "edit variable",
  value: "Value",
  create_new_team: "create new team",
  create_new_organization: "create new organization",
  create_new_project: "Create Project",
  team_name: "team name",
  add_members: "add members",
  create: "create",
  member_name: "member name",
  back: "Back",
  "back.syslog": "to Syslog",
  "back.splunk": "to Splunk",
  "back.sumologic": "to Sumo Logic",
  "back.newrelic": "to NewRelic",
  "back.access": " to Access",
  "back.plans": " to Plans",
  "back.project": " to Project",
  "back.environment": " to Environment",
  "back.project_settings": " to Project settings",
  "back.projects": " to Projects",
  "back.tickets": " to Tickets",
  "back.billing_details": " to Billing details",
  "back.invoices": " to Invoices",
  "back.integrations": " to Integrations",
  "back.bitbucket": " to Bitbucket",
  "back.bitbucket_server": " to Bitbucket Server",
  "back.github": " to GitHub",
  "back.gitlab": " to GitLab",
  "back.health.email": " to Email",
  "back.health.pagerduty": " to Pagerduty",
  "back.health.slack": " to Slack",
  "back.health.webhook": " to Webhook",
  "back.webhook": " to custom Webhook",
  "back.script": " to script",
  "back.security": " to security",
  "back.teams": "to Teams",
  next: "Next",
  skip: "Skip",
  environment_name: "Environment name",
  environment_type_permissions: "Environment type permissions",
  environment_by_type: "Environments by type",
  showing: "showing",
  messages: "messages",
  build_log: "Build log",
  changelog: "Changelog",
  log_empty: "Log is empty.",
  scroll_top: "Scroll to top",
  scroll_down: "Scroll to bottom",
  pushed: "pushed",
  error_loading_invitation: "It's not you - it's us",
  error_loading_invitation_description:
    "The invitation you were looking for could not be found.",
  error_loading_environment: "It's not you - it's us",
  error_loading_environment_description:
    "The environment you were looking for could not be found.",
  error_environment_403:
    "You do not have permission to access this environment. Please contact the project administrator to request access.",
  error_loading_project: "It's not you - it's us",
  error_status: "You can check {appName} status <a>here</a>",
  error_loading_project_description:
    "The project you were looking for could not be found.",
  error_project_404: "The project you were looking for could not be found.",
  error_project_502:
    "The project you were looking for is not responding, please contact support.",
  error_project_403:
    "You do not have permission to access this project. Please contact the project administrator to request access.",
  error_loading_organization: "It's not you - it's us",
  error_loading_organization_description:
    "The organization {organizationName} could not be found.",
  error_organization_404:
    "The organization {organizationName} could not be found.",
  error_organization_403:
    "You do not have permission to access the organization {organizationName}. Please contact the project administrator to request access.",
  error_organization_502:
    "The organization {organizationName} is not responding, please contact support.",
  error_403_title: "Access denied",
  general: "general",
  general_project_settings: "General project settings",
  status: "status",
  last_backup: "Last backup",
  last_deployed: "Last deployed",
  last_activity: "Last activity",
  machine_name: "Machine name",
  no_backup: "No backup",
  variables: "variables",
  no_variables: "no variables.",
  search_by_team: "search by team name",
  dashboard: "dashboard",
  add_new_access: "add new access",
  certificates: "Certificates",
  issuer_info: "issuer info",
  created: "created",
  updated: "updated",
  expires: "expires",
  close: "Close",
  deploy_key: "deploy key",
  deploy_key_description:
    "Add this SSH public key to your private code repositories to allow {appName} to access them during the build process.",
  domain: "domain",
  "domain.input.placeholder": "stagingdomain.com",
  domains: "domains",
  "domains.back_to": "Back to domains",
  "domains.environment": "Environment",
  "domains.replacement_domain": "Attached production domain",
  "domains.replacement_domain.info":
    "For the routing of your app, attach your preview environment domain to an existing production domain.",
  "domains.select_domain": "Select a domain",
  "domains.details.learn_more":
    "You can’t edit a custom preview environment domain. You can only delete it and create a new one as a replacement.",
  "domains.learn_more":
    "For the routing of your app, attach your preview environment domain to an existing production domain. <link>Learn more</link>",
  "domains.add_title": "Add preview environment domain",
  "domains.add_subtitle":
    "Enter the domain name and select a production domain for environment mapping.",
  "domains.documentation":
    "Learn more about domains in our <a>documentation</a>",
  "domains.complete.configuration.title": "Complete configuration",
  "domains.complete.configuration.description":
    "The domain {customDomain} was successfully added. If you haven’t already, copy the target below and add it to your DNS provider as a CNAME record.",
  "domains.production_domains": "Production domains",
  no_domains: "no domains.",
  view: "view",
  no_title: "no title",
  add_route: "add route",
  edit_route: "edit route",
  served_by: "served by",
  routes: "routes",
  no_routes: "no routes.",
  health: "health",
  apps_services: "Apps & Services",
  "apps_services.value_type": "{value} {type}",
  services: "services",
  activity: "Activity",
  usage: "usage",
  billing: "billing",
  new_project: "New project",
  add_team: "add team",
  scheduled_maintenance: "scheduled maintenance",
  number: "number",
  expiry: "expiry",
  cvc: "cvc",
  all_type: "Show all",
  show_all: "Show all",
  git: "Git",
  snapshot: "Snapshot",
  system: "System",
  certificate: "Certificate",
  region: "Region",
  add_user: "add user",
  "remove.user": "Remove user",
  "organizations.invite_user(s)": "Invite users",
  "organizations.free_during_trial": "Free during trial",
  edit_user: "edit user",
  unknown: "unknown",
  cpu_dialog: "CPU",
  ram_dialog: "RAM",
  disk_dialog: "Disk",
  instances_dialog: "Instances",
  profile_dialog: "Profile",
  crons_dialog: "Crons",
  "projects.sort.reverse": "Reverse order",
  "projects.grid_layout": "projects grid layout",
  "projects.list_layout": "projects list layout",
  "environments.tree_layout": "environments tree layout",
  "environments.list_layout": "environments list layout",
  "environments.links_tooltip": "{title} branch links",
  "status.dialog.edit":
    "To edit the status of this environment, review your general settings.",
  view_settings: "View settings",
  status_active_description:
    "Each git push to this environment triggers a new deployment.",
  status_active_delete_description:
    "This environment is inactive, and a Git branch is still associated to it. You can delete it from your project here or via the CLI.",
  "status_active_delete_description.integration":
    "We see that you’re connected to {integration}. To delete this environment, run the following command within your CLI:",
  status_active_delete_warning:
    "De-activating your environment will destroy all data and services running on this environment, leaving only the code in the Git branch and environment variables.",
  "status_active_delete.description":
    "You must deactivate your environment before you can delete the branch.",
  "status_active_delete.title": "Delete environment",
  status_active_delete_bullets_title: "Please note:",
  status_active_delete_bullet_1:
    "This environment will be in an inactive state, will not be deployed, and access through web URLs or SSH will not work.",
  status_active_delete_bullet_2:
    "You can activate this environment at a later time if the Git branch is still available.",
  status_active_delete_bullet_3:
    " Reactivating will sync data from the parent environment and redeploy.",
  status_active_delete_verify:
    "I understand that all data will be permanently deleted and that web URLs and SSH access will no longer work for this environment.",
  status_activate_description:
    "While the Git branch is available, activating will sync data from the parent environment and redeploy.",
  user_email: "user email",
  enter_valid_email: "Please enter a valid email address",
  duplicate_email: "{email} already has access to the project",
  add_login: "add login",
  delete: "delete",
  confirm_remove: "Yes, Remove",
  remove: "Remove",
  open_mobile_nav: "open navigation",
  of: "of",
  step_x_of_n: "Step {currentStep} of {totalStep}",
  return_form: "Return to form",
  tab_template: "select a template",
  tab_template_sub: "from our collection",
  tab_push: "Create blank project",
  tab_push_sub: "Push from local repository",
  tab_connect: "connect",
  tab_connect_sub: "to hosted repository",
  continue: "Continue",
  push_text:
    "at the end of your setup, you will be provided with a repository to push your code to.",
  select_connect:
    "select the service that you would like to connect to your account.",
  connected_to: "connected to the {userName} account",
  select_project: "select the project to connect with",
  remember_to_add: "remember to add:",
  more_info_in_docs: "More information can be found in our ",
  documentation: "documentation",
  to_configure_app: "to configure your app",
  to_set_up_services: "to set up databases, caches, and other services",
  to_define_urls: "to define how incoming URLs are processed",
  prices_per_month: "prices per month, per site",
  scale_any_time: "scale at any time",
  professional: "professional",
  small_description: "single & light-resource app",
  medium_description: "low traffic multi-apps",
  large_description: "resource intensive and multiple apps",
  xlarge_description: "high-traffic complex applications",
  xxlarge_description: "high-traffic, resource intensive complex apps",
  development: "development",
  development_description: "agile project development",
  development_details:
    "Test every feature in parallel and in isolation with automatically generated staging servers for every git branch.",
  add_ons: "add-ons",
  add_ons_description: "can be added to your project as needed.",
  upgrade_to_standard_add_on: "upgrade to our Standard add-on.",
  add_on_invite_modal_note:
    "Users are automatically assigned all organization permissions. To customize permissions, you need to",
  "add_on_invite_modal_note.with_addon":
    "You can only assign permissions that you have been granted.",
  price_increase_note:
    "Note: Adding users will increase your monthly costs. To learn more, visit\nour",
  users: "users",
  users_description: "{price} per user per month",
  environments_description: "{price} a month per 3 environments",
  storage: "storage",
  storage_description: "{price} a month per 5GB x environments",
  payment_description:
    "Usage will be calculated at the end of each month and automatically billed to the information provided below.",
  billing_information: "billing information",
  payment_information: "payment information",
  payment_method: "Payment method",
  new_payment_method: "Select new payment method",
  only_one_method: "Only one can be saved to your account at any time.",
  no_upcoming_invoices: "You have no upcoming invoices",
  subtotal: "Subtotal",
  total: "Total",
  payment_information_description:
    "This is a secure 128-bit SSL encrypted payment gateway.",
  payment_voucher_description:
    "You have voucher credit that can be used for this purchase.",
  voucher_credit: "Voucher credit",
  vouchers: "Vouchers",
  "vouchers.add": "Redeem voucher",
  "vouchers.intro":
    "Redeem your voucher to get even more benefits from {appName}. Your voucher credit will be deducted from your upcoming invoice.",
  "voucher.empty.title": "Have a voucher?",
  "voucher.empty.intro":
    "Redeem your voucher to get even more benefits from {appName}.{br}Your voucher credit will be deducted from your upcoming invoice.",
  "voucher.list.headers.code": "Code",
  "voucher.list.headers.credit": "Credit",
  "voucher.list.headers.balance": "Balance",
  "voucher.list.headers.status": "Status",
  "voucher.list.status.redeemed": "Redeemed",
  "voucher.list.status.completed": "Completed",
  "voucher.form.title": "Redeem your voucher",
  "voucher.form.code": "Voucher code",
  "voucher.form.submit": "Redeem",
  "voucher.credit": "Credit <b>{credit}</b>",
  "voucher.balance": "Balance <b>{balance}</b>",
  "voucher.order.number": "Order number",
  "voucher.order.credit": "Credit used to date",
  "voucher.order.date": "Order date",
  "voucher.order.status": "Order status",
  "project_detail.header.item": "Item",
  "project_detail.header.duration": "Duration",
  "project_detail.header.unit_price": "Unit price",
  "project_detail.header.quantity": "Quantity",
  "project_detail.header.total": "Total",
  "project_detail.total": "Total",
  "order_status.invoiced": "Invoiced",
  "order_status.canceled": "Canceled",
  "order_status.dunning_past_due": "Past due",
  "order_status.cart": "Shopping cart",
  "order_status.checkout_login_register": "Checkout: Account",
  "order_status.checkout_review": "Checkout: Review",
  "order_status.checkout_register": "Checkout: Register",
  "order_status.checkout_checkout": "Checkout: Billing",
  "order_status.checkout_complete": "Checkout: Complete",
  "order_status.cardonfile_payment_error_hard_decline":
    "Payment failed (hard decline)",
  "order_status.cardonfile_payment_failed_soft_decline":
    "Payment failed (soft decline)",
  "order_status.payment_error_disputed": "Payment failed (disputed)",
  "order_status.pending": "Pending",
  "order_status.processing": "Processing",
  "order_status.fulfilment_fulfilment": "Fulfilment",
  "order_status.fulfilment_problem": "Problem",
  "order_status.recurring_usage_pending": "Recurring: Usage pending",
  "order_status.recurring_payment_pending": "Recurring: Payment pending",
  "order_status.recurring_open": "Upcoming",
  "order_status.completed": "Completed",
  "order.detail.standard_user_management.dialog.title":
    "Standard user management",
  "order.detail.standard_user_management.dialog.content":
    "Standard user management is included in the Beta period. After the Beta period, this add-on will be an additional cost.",
  "order.detail.standard_user_management.dialog.link": "Learn more",
  full_name: "Full name",
  address: "Address line 1",
  address2: "Address line 2",
  billing_address: "Billing address line 1",
  billing_address2: "Billing address line 2",
  city: "City",
  state: "State",
  state_province_region: "State / Province / Region",
  "state_province_region.placeholder": "State",
  zip: "ZIP code",
  zip_postal_code: "ZIP / Postal code",
  "zip_postal_code.placeholder": "ZIP",
  country: "Country",
  select_country: "Select country",
  select_branch: "Select branch",
  plan: "plan",
  plans: "Plans",
  estimated_total: "estimated monthly total",
  estimated_cost: "Estimated cost",
  total_monthly_payment: "total monthly payment",
  total_monthly_cost: "Total monthly cost",
  big_dev_title: "Environments application size",
  big_dev_desc:
    "Select the size for your development environment application containers",
  user_name: "User name",
  password: "Password",
  payment_consent:
    "I agree to pay my monthly usage fees. I understand I can change plans and add options through the month, which may incur additional fees.",
  check_consent: "Please check the consent box",
  gb_memory: "<b>{value}</b> GB memory",
  scale_up_down: "Scale up and down at any time",
  scale_description:
    "You can change plans at any time, add an unlimited number of development environments and more users.",
  all_include: "Our plans include",
  all_plans: "All plans",
  "1_website": "One production website",
  "3_dev": "Three live development environments",
  unlimited_traffic: "Unlimited web traffic",
  multiple_domains: "Multiple domains with free SSL certificates",
  storage_per_environment: "5 GB of storage per environment",
  "1_user": "One user",
  automatically_provisioned:
    "Automatically provisioned services (e.g., MySQL, Postgres, Redis, Elastic Search)",
  micro_service_support: "Micro-service / Multi-App support",
  dev_plan: "Development plan",
  dev_plan_info:
    "The development plan does not allow mapping a domain name to the production environment and cannot be used for live websites.",
  free_trial: "Free trial",
  free_trial_info:
    "We offer new users a one-month free development plan trial. The development plan doesn’t allow mapping a domain name to the production environment and can’t be used for live websites.",
  "banner.dunning.suspended_days.update":
    "Payment failed - update your payment method to keep your projects from being suspended in {num} days.",
  "banner.dunning.suspended_days.ask":
    "Payment failed - ask your organization owner to update your payment method to keep your projects from being suspended in {num} days.",
  "banner.dunning.suspended_tomorrow.update":
    "Payment failed - update your payment method to keep your projects from being suspended tomorrow.",
  "banner.dunning.suspended_tomorrow.ask":
    "Payment failed - ask your organization owner to update your payment method to keep your projects from being suspended tomorrow.",
  "banner.dunning.suspended_within_day.update":
    "Payment failed - update your payment method to keep your projects from being suspended within the day.",
  "banner.dunning.suspended_within_day.ask":
    "Payment failed - ask your organization owner to update your payment method to keep your projects from being suspended within the day.",
  "banner.dunning.deleted_tomorrow.update":
    "Your projects are suspended and will be deleted tomorrow if we cannot process your payment. Please update your billing details.",
  "banner.dunning.deleted_tomorrow.ask":
    "Your projects are suspended and will be deleted tomorrow if we cannot process your payment. Ask your organization owner to update your billing details.",
  "banner.dunning.deleted_within_day.update":
    "Your projects are suspended and will be deleted within the day if we cannot process your payment. Please update your billing details.",
  "banner.dunning.deleted_within_day.ask":
    "Your projects are suspended and will be deleted within the day if we cannot process your payment. Ask your organization owner to update your billing details.",
  "banner.dunning.deleted_days.update":
    "Your projects are suspended and will be deleted in {num} days if we cannot process your payment. Please update your billing details.",
  "banner.dunning.deleted_days.ask":
    "Your projects are suspended and will be deleted in {num} days if we cannot process your payment. Ask your organization owner to update your billing details.",
  "banner.dunning.suspended.update":
    "Your organization has been suspended. Update billing details to unlock your {appName} account.",
  "banner.dunning.suspended.ask":
    "Your organization has been suspended. Ask your organization owner to update billing details to unlock your {appName} account.",
  "tooltip.dunning.restricted.title": "Your organization is restricted",
  "tooltip.dunning.suspended.title": "Your organization is suspended",
  "tooltip.dunning.deleted.title": "Your organization is suspended",
  "tooltip.dunning.restricted.description":
    "<p>Your payment failed, so your organization is restricted. While your organization is restricted, your projects are still running. However, you will not be able to:</p><ul><li>Edit or delete your organization</li><li>Invite, edit, or delete organization members</li><li>Create, edit, or delete projects and project resources</li></ul><p>Update your <linkBilling>billing details</linkBilling> to regain full control of your organization.</p>",
  "tooltip.dunning.suspended.description":
    "<p>We are unable to process your payment after multiple attempts, so your organization has been suspended. While your organization is suspended, your projects are not running. Also, you will not be able to:</p><ul><li>Edit or delete your organization</li><li>Invite, edit, or delete organization members</li><li>Create, edit, or delete projects and project resources</li></ul><p>Update your <linkBilling>billing details</linkBilling> to regain full control of your organization.</p>",
  "tooltip.dunning.deleted.description":
    "<p>We are unable to process your payment after multiple attempts, so your organization has been suspended. While your organization is suspended, your projects are not running. Also, you will not be able to:</p><ul><li>Edit or delete your organization</li><li>Invite, edit, or delete organization members</li><li>Create, edit, or delete projects and project resources</li></ul><p>Update your <linkBilling>billing details</linkBilling> to regain full control of your organization.</p>",
  "tooltip.projectCreation.information": "{projectName} is being created...",
  "tooltip.projectCreation.error":
    "{projectName} could not be created. Please try again.",
  restricted: "Restricted",
  suspended: "Suspended",
  "banner.trial.state_1":
    "Enjoy your free trial. <linkBilling>Upgrade</linkBilling> your plan at any time when you’re ready to go live!",
  "banner.trial.state_2":
    "Your free trial is ending soon. <linkBilling>Upgrade</linkBilling> your plan to keep your projects running!",
  "banner.trial.state_3":
    "Your free trial has expired, please add your <linkBilling>billing details</linkBilling> to keep your projects running.",
  "banner.trial.state_4":
    "Payment method missing. Add your <linkBilling>billing details</linkBilling> to keep your projects running.",
  "banner.payment_missing":
    "Payment method missing. Add your <a>billing details</a> to keep your projects running.",
  "banner.payment_failed":
    "Payment method failed. Update your <a>billing details</a> to keep your projects running.",
  "banner.trial_limit":
    "Enjoy your trial! To unlock more projects and resources, please consider subscribing to {app_name}.",
  "banner.trial_limit.no_billing_permission":
    "Enjoy your trial! To unlock more projects and resources, contact your organization owner to subscribe to {app_name}.",
  "banner.subscribe": "Subscribe",
  "banner.reactivate": "Reactivate trial",
  "banner.reactivate.subject": "Trial extension request",
  "banner.reactivate.description":
    "I would like to speak to the sales team about extending my trial, please.",
  "banner.update_code_title": "Automated code updates",
  "banner.update_code_description":
    "Always keep the source code of all your applications and their dependencies up to date",
  basic_settings: "basic settings",
  current_password: "Current password",
  confirm_password: "Confirm password",
  display_name: "Display name",
  first_name: "First name",
  last_name: "Last name",
  username: "Username",
  email: "Email",
  ssh: "SSH",
  account_settings: "account settings",
  profile_picture: "profile picture",
  no_ssh_keys: "no SSH keys are available",
  add_ssh_key: "Add SSH key",
  ssh_keys: "SSH keys",
  website: "website URL",
  company_role_label: "Profession",
  company_type_label: "Company activity",
  timezone: "timezone",
  notification_settings: "notification settings",
  newsletter: "newsletter",
  receive_newsletter: "Receive newsletter",
  "newsletter.description":
    "Yes, I wish to receive special offers and information from {appName}",
  plaintext_email: "plaintext email only",
  information: "Information",
  title: "Title",
  api_tokens: "API Tokens",
  api_token: "API Token",
  "api_token.delete": "Delete",
  user_details: "User details",
  company_details: "Company details",
  enter_url: "Enter URL here",
  enter_company_name: "Enter company name here",
  key: "Key",
  sepa_disclamer_short:
    "By choosing to pay via your bank account you authorize {appName} to debit your account.",
  sepa_disclaimer_shorter: "Authorize {appName} to debit your account.",
  bank_account_payments: "Bank account payments",
  sepa_disclamer:
    "By providing your IBAN and confirming this payment, you are authorizing [DEV] {appName} Accounts and Stripe (our payment service provider) to send instructions to your bank to debit your account and your bank to debit your account in accordance with those instructions. You are entited to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited.",
  credit_card_number: "Credit card number",
  credit_card: "Credit card",
  "credit-card": "Credit card",
  bank_account_number: "IBAN",
  bank_account: "Bank account",
  stripe_sepa_debit: "Bank account",
  update_credit_card: "Update credit card",
  update_bank_account: "Update bank account",
  change_payment_method_header: "change payment method",
  change_payment_method_body:
    "Changing payment methods will remove your existing method from your account. Are you sure you want to change methods?",
  change_payment_method_note:
    "Note: You must add a new payment method before your previous one is removed.",
  change_payment_method_confirm: "Yes, change method",
  size: "size",
  disk: "disk",
  crons: "Crons",
  workers: "workers",
  version: "version",
  no_defined_routes: "No defined routes",
  view_details: "View details",
  configure: "Configure",
  configure_resources: "Configure resources",
  frequency: "Frequency",
  last_run: "last run",
  last_used: "last used",
  run_command: "Run command",
  metadata: "metadata",
  project_details: "your project details",
  "announcements.whatsnew": "What's new",
  "announcements.requestfeature": "Request a feature",
  "announcements.justlaunched": "Just launched",
  "announcements.gotit": "Got it!",
  "announcements.back": "Back",
  "announcements.upcomingfeatures": "Upcoming features",
  "screenshot.available": "Project preview",
  "screenshot.notAvailable": "Image not available",
  "icons.grid": "Grid",
  "icons.list": "List",
  "icons.copy": "Copy to clipboard",
  "icons.copied": "Copied!",
  "icons.download": "Download file",
  "icons.downloaded": "Downloaded!",
  "icons.share_link": "Copy link",
  "icons.copy_log": "Copy log",
  "ssh.duplicatedkey.title": "SSH Key can not be duplicated",
  "ssh.duplicatedkey.message":
    "The SSH Key you tried adding, below, is a duplicate of an existing one. You can not have duplicate keys connected to your account.",
  okay: "Okay",
  "ssh.confirm.yes": "Yes, delete",
  "ssh.confirm.title": "Delete SSH Key",
  "ssh.confirm.body":
    "Are you sure you want to delete the SSH Key for {key}? This action can not be undone.",
  "ssh.add.info.description":
    "Add your SSH Keys to your account below to use Git and command shells with any {appName} environment that your user account is authorized to work with.",
  "ssh.add.info.learnmore.description":
    "Learn more about configuring your SSH Keys",
  "ssh.add.info.learnmore": "Learn more",
  "ssh.view.title": "Title",
  plan_and_pricing: "Plan & Pricing",
  plan_and_pricing_sumup_description_1:
    "Your new project will be created on our smallest <b>{planType} plan</b>",
  plan_and_pricing_text: " unless you're in our free trial",
  plan_and_pricing_trial_text:
    " and billed at $12 per month after your free trial ends",
  plan_and_pricing_sumup_description_2:
    "You can change your plan, add more storage, users or environments at any time through your project account settings.",
  "plan_and_pricing_sumup_plan-label": "Plan",
  plan_and_pricing_sumup_plan: "Development",
  support_ticket: "support ticket",
  monthly_price: "Monthly price",
  plan_price: "$12 <label>USD</label>",
  plan_price_discounted: "<s>$12</s> <label>USD</label>",
  "plan_includes.title": "Includes",
  "plan_includes.item.user": "1 User",
  "plan_includes.item.environments": "3 Development environments",
  "plan_includes.item.storage": "5 GB Storage per environment",
  "plan_includes.item.prod": "Main dev site (future production site)",
  lets_go: "Let's go",
  billing_summary_info:
    "To change your plan, or add more storage, users or environments, you can upgrade at any time through your project account settings.",
  billing_new_project:
    "All new projects are set to our smallest Development plan.",
  billing_system_explanation:
    "Billing is based on actual usage at the end of each month. Prices displayed include VAT when applicable.",
  update_billing: "update billing",
  "support.sla.professional.text":
    "Professional support does not include an SLA",
  "support.sla.premier.text":
    "Normal SLA : 72 hours{br}High SLA : 48 hours{br}Urgent SLA : 4 hours",
  "support.sla.enterprise.text":
    "Normal SLA : 24 business hours{br}High SLA : 8 business hours{br}Urgent SLA : 1 hour, 24/7",
  "support.sla.elite.text":
    "Normal SLA : 8 hours, 24/7{br}High SLA : 4 hours, 24/7{br}Urgent SLA : 45 minutes, 24/7",
  "sla.support": "support",
  "professional.support": "Professional support",
  support_sla_link_text_learn: "Learn more",
  support_sla_link_text_contact: "Contact sales",
  contact_sales: "Contact sales",
  support_sla_plan: "{plan} support",
  support_sla_explanation:
    "Contact our sales team to get access to better SLA support. Those support tiers are available to Elite and Enterprise organizations.",
  "modal.limitation.title": "Need to create a new project?",
  "modal.limitation.description":
    "You have reached the limit. To create more projects you need to add your billing info and subscribe to {app_name}",
  "modal.trial.limit.soft.title": "Free trial limit",
  "modal.trial.limit.soft.details":
    "To create another project you’ll need to either add your billing details or delete one of your projects as your free trial is nearing its limit.{br}Or if you would like to extend your free trial please contact our <a>sales team</a>.",
  "modal.trial.limit.soft.button": "Add billing details",
  "modal.trial.limit.plan.title": "Free trial limit",
  "modal.trial.limit.plan.details":
    "To edit your plan you’ll need to either add your billing details or delete one of your projects as your free trial is nearing its limit.{br}Or if you would like to extend your free trial please contact our <a>sales team</a>.",
  "modal.trial.limit.plan.button": "Add billing details",
  "modal.trial.limit.hard.title": "Free trial limit",
  "modal.trial.limit.hard.details":
    "To create another project please add your billing details as your free trial has ended.{br}Or if you would like to extend your free trial please contact our <a>sales team</a>.",
  "modal.trial.limit.hard.button": "Add billing details",
  "modal.trial.limit.very_hard.title": "Billing details",
  "modal.trial.limit.very_hard.details":
    "To create another project please add your billing details.",
  "modal.trial.limit.very_hard.button": "Add billing details",
  "modal.organization.project.title.paymentmissing": "Payment method missing",
  "modal.organization.project.button.paymentmissing": "Add billing details",
  "modal.organization.project.title.paymentfailed": "Payment failed",
  "modal.organization.project.body.paymentfailed":
    "Please check your invoice and billing details or contact your billing provider to resolve your failed payment.",
  "modal.organization.project.button.invoices.paymentfailed": "Invoices",
  "modal.organization.project.button.billing.paymentfailed": "Billing details",
  "modal.organization.project.button.billing.invoices": "Invoices",
  "modal.resource.initialization.dialog.title":
    "Resource initialization strategy",
  "modal.resource.initialization.dialog.description":
    "You can define which strategy to use when allocating resources for any new app or service containers.",
  "modal.resource.default.dialog.title": "Default resource sizes",
  "modal.resource.default.dialog.description":
    "{appName} will auto allocate your application and service containers with the default resources.",
  "modal.resource.minimum.dialog.title": "Minimum resource sizes",
  "modal.resource.minimum.dialog.description":
    "{appName} will allocate your application and service containers with the minimum  resources required to run your containers.",
  "modal.resource.manual.dialog.title": "Manually configure resources",
  "modal.resource.manual.dialog.description":
    "Please note that the deployment will fail, which is normal, as it allows you to configure your resources manually without deploying the merge right away. You can configure resources via the CLI or through the console.",
  billing_details_added: "Billing details added",
  billing_details_added_a:
    "Thanks for adding your billing details to your account. You'll receive an invoice based on your projects' actual usage at the end of each month.",
  billing_details_added_b:
    "You can manage your billing details through your accounts billing settings.",
  billing_email: "Billing email",
  billing_contact_email: "Billing contact email",
  company: "Company",
  company_name: "Company name",
  vat_number: "VAT number",
  ssi: "Server Side Includes",
  upgraded_to_version: "upgraded to version",
  upgrade_to_version: "upgrade to version",
  yaml_app_description:
    "# The name of this app. Must be unique within a project.\nname: app\n\n",
  yaml_app_default: "# Select a runtime to begin",
  yaml_service_default: "# Select a service to begin",
  yaml_relationship_description:
    "\n\n# The relationships of the application with services or other applications.\n#\n# The left-hand side is the name of the relationship as it will be exposed\n# to the application in the PLATFORM_RELATIONSHIPS variable. The right-hand\n# side is in the form '<service name>:<endpoint name>'.\nrelationships:\n",
  yaml_mount_description:
    "\n\n# The following block defines a single writable directory, 'web/uploads'\n# The 'source' specifies where the writable mount is. The 'local' source\n# indicates that the mount point will point to a local directory on the\n# application container. The 'source_path' specifies the subdirectory\n# from within the source that the mount should point at. \nmounts:\n  'web/uploads':\n    source: local\n    source_path: uploads",
  yaml_disk_description:
    "\n\n# The size of the persistent disk of the application (in MB).\ndisk: ",
  yaml_routes_description:
    "# Each route describes how an incoming URL is going to be processed by {appName}.",
  ssh_key_autofilled: "Your existing SSH Key has been autofilled.",
  file_example: "File example",
  example_file: "Example file",
  routes_yaml: "routes.yaml",
  "file.services.yaml": "{prependText}: services.yaml",
  select_services: "Select service/s",
  select_runtime: "Select a runtime",
  environment_application_size: "Environments Application size",
  edit_plan: "Edit plan",
  environment_application_size_message:
    "All development environments default to the Standard size. The size can be increased to match your production environment if needed.",
  learnmore: "Learn more",
  learnhow: "Learn how",
  "dedicatedenv.dialog.title": "Dedicated environment",
  "dedicatedenv.dialog.text": "This branch deploys to Dedicated Infrastructure",
  this_user_not_exist: "This user does not exist",
  "setup.type.title": "Select project type",
  "setup.type.trial.title":
    "Create your first project to start your free trial",
  "setup.type.description":
    "Use your own code, migrate a project or use a project template to get started.",
  "setup.template.title": "Select your template",
  "setup.info.title": "Your project details",
  "setup.building.provisioning_error":
    "There was an issue creating your project",
  "region_picker.default_text": "Select region",
  "environment_impact.default":
    "All of our infrastructure providers are committed to improving their environmental footprints.",
  "environment_impact.default.green":
    "Get a 3% discount by deploying to our greener regions!",
  "environment_impact.green":
    "Includes 3% discount on CPU, memory, and build resources",
  "environment_impact.label": "Energy Grid Carbon Intensity",
  "environment_impact.carbon_intensity": "~{carbonIntensity} gCO2eq/kWh",
  "setup.breadcrumb.steps.type": "Type",
  "setup.breadcrumb.steps.info": "Details",
  "setup.breadcrumb.steps.template": "Template",
  "setup.breadcrumb.steps.building": "Build",
  "setup.breadcrumb.steps.configure": "Configure",
  "setup.type.template.title": "Use a template",
  "setup.type.template.description":
    "Choose from one of our pre-existing code base templates to start your project.",
  "setup.type.template.details": "Details",
  "setup.type.empty.title": "Create from scratch",
  "setup.type.empty.description":
    "Bring your own code or migrate an existing project to {appName}",
  "setup.building.title": "Your new project is building!",
  "setup.building.description":
    "We won’t be long, but if you like you can close this window at any time to continue working.",
  "setup.building.errors":
    "We're sorry, but the following {count, plural, one {error} other {errors}} occurred while attempting to create your project. Please <link>contact support</link>.",
  "project.screenshot.plan": "Plan ",
  "project.screenshot.state.live": "Live",
  "project.screenshot.state.not_live": "Go live",
  "project.state.live": "Live",
  "project.state.not_live": "Go live",
  "project.details.environments_used": "{used} out of {total}",
  "project.details.environments": "Environments",
  "project.details.environment": "Environment",
  "project.details.users": "Users",
  "project.details.disk": "Disk",
  "project.details.plan": "{name} Plan",
  "project.details.plan.upgrade": "Upgrade",
  "project.details.domain.update": "Update custom domain",
  "project.details.domain.set": "Set a custom domain",
  "project.details.no_url": "Waiting for URL...",
  "project.card.tag.trial": "Free trial",
  "project.card.tag.suspended": "Suspended",
  "project.details.resources": "Resources",
  "project.details.double_value": "{value}<separator></separator>{value2}",
  "project.details.traffic_this_month": "Traffic this month",
  "project.details.billing_period": "Billing period: {value}",
  "project.details.origin_bandwidth": "Origin bandwidth",
  "project.details.origin_requests": "Origin requests",
  "project.details.cdn_bandwidth": "CDN bandwidth",
  "project.details.cdn_requests": "CDN requests",
  "project.details.traffic_note":
    "Traffic data is accumulated throughout the current month and values are updated daily.",
  "services.display.empty": "There are no connected apps or services yet.",
  "services.display.error":
    "Your services could not be displayed. Contact support if this problem persists.",
  "services.display.empty.title": "No services",
  "services.display.empty.description":
    "Add services through your <code>services.yaml</code> file in your <code>{appConfigFolder}</code>. {br} Learn more about adding a database, cache or search engine in our <a>documentation</a>.",
  "services.display.error.title": "No Services to display",
  "services.display.error.description":
    "Your services could not be displayed.<br></br><a>Contact support</a> if this problem persists.",
  "sevices.display.no_services":
    "This environment does not have any application or service.",
  "services.process.title": "Processes",
  "services.process.refresh.total": "{count} Total",
  "services.process.refresh.button": "Refresh",
  "services.process.filters.search": "Search",
  "services.process.colum.name": "Name",
  "services.process.colum.id": "ID",
  "services.process.colum.parent": "Parent",
  "services.process.colum.children": "Children",
  "services.process.colum.stat": "STAT",
  "services.process.colum.cpu": "% CPU",
  "services.process.colum.mem": "MEM",
  "services.process.colum.user": "User",
  "services.process.colum.start": "Start",
  "services.process.colum.run": "Run time",
  "services.process.colum.run_tooltip": "Started {date}",
  "services.process.options.copy": "Copy PID",
  "services.process.options.force_kill": "Force Kill",
  "services.process.options.terminate_process": "Terminate process",
  "services.process.options.advanced_signals": "Advanced signals",
  "services.process.options.dialog.title": "Select a common signal to run",
  "services.process.options.dialog.more_information":
    "For more information about advanced signals,",
  "services.process.options.dialog.view_documentation":
    " view our documentation.",
  "services.process.options.modal.title": "Run {signalName}",
  "services.process.options.modal.description":
    "Are you sure you want to run {signalName} on process {process}? This action cannot be undone.",
  "services.process.options.modal.continue": "Continue",
  "services.process.options.modal.not_available": "Not available for {appName}",
  "projects.filter.all": "All projects",
  "projects.toggle.sort": "Sort by",
  "projects.toggle.sort_by": "Sort by",
  "projects.toggle.sort_by.name": "Alphabetically",
  "projects.toggle.sort_by.project_title": "Alphabetically",
  "projects.toggle.sort_by.owner": "Owner",
  "projects.toggle.sort_by.organization": "Organization",
  "projects.toggle.sort_by.plan": "Plan size",
  "projects.toggle.sort_by.project_region": "Region",
  "projects.toggle.sort_by.region": "Region",
  "projects.toggle.sort_by.created_at": "Date created",
  "projects.toggle.sort_by.id": "Project ID",
  "projects.toggle.sort_by.project_id": "Project ID",
  "activity.loading": "Loading...",
  "activity.showMore": "Show more",
  "activities.headers.pending": "Pending",
  "activities.headers.recent": "Recent",
  "activities.headers.running": "Running",
  "activities.tabs.all": "All",
  "activities.tabs.recent": "Recent",
  "activities.empty.all": "You have no recent activities",
  "activities.empty.pending": "You have no pending activities",
  "activities.empty.running": "You have no running activities",
  "activities.more": "Show more",
  "activity.no_description": "No description",
  "activity.log_button": "View log",
  "activity.restore_button": "Restore",
  "backup.restore_button": "Restore",
  "backup.restore.modal.title": "Restore from backup",
  "backup.delete_button": "Delete",
  "activity.scheduled_for": "Scheduled for",
  "activity.cancellable_tooltip":
    "This activity can only be cancelled while running the building hook",
  "activity.not_cancellable_tooltip":
    "This type of activity cannot be cancelled",
  "activity.cancel_button": "Cancel",
  "activity.cancel_modal.header": "Cancel activity",
  "activity.cancel_modal.description":
    "Are you sure you want to cancel the activity: {activityName} on {branch}?",
  "activity.cancel_operation_modal.description":
    "Are you sure you want to cancel the activity: <strong>{operationType}</strong> <b>{activityName}</b> on <strong>{branch}</strong>?",
  "activity.cancel_modal.note":
    "Note: If the build hook has completed, this operation can not be cancelled",
  "activity.cancel_modal.cancel": "No, not now",
  "activity.cancel_modal.cancel_operation": "Yes, cancel activity",
  "activity.cancel_modal.error.header": "This operation can not be cancelled",
  "activity.cancel_modal.error.description":
    "This operation can not be cancelled as the build hook has already completed.",
  "activity.cancel_modal.error.learn_more": "Learn more",
  "activity.cancel_modal.error.okay": "Okay",
  "activity.sticker.paused.environment":
    "The environment is paused. <ctaLink>Resume environment</ctaLink> or learn more in our<infoLink>docs</infoLink>.",
  "payload.commits":
    "{count, plural, =0 {zero} one {+ 1 commit} other {+ {count} commits}}",
  api_token_help_text:
    "Use API Tokens to access {appName} from other CI services or automation tools.",
  api_token_add_success: "API Token {tokenName} has been successfully created.",
  api_token_create: "Create API Token",
  api_token_create_help_text:
    "An API token can be exchanged for an OAuth 2 access token, which can then be used to authenticate requests.",
  api_token_info_text:
    "To learn more about configuring your API tokens, view our {cta}.",
  api_token_delete_warning:
    "Are you sure you want to delete API Token {tokenName}? This action can not be undone.",
  api_token_exists: "An API Token with this name already exists.",
  darkmode: "Dark mode",
  lightmode: "Light mode",
  enable: "Enable",
  enable_high_contrast: "Enable high contrast mode",
  display_settings: "Display settings",
  ui_color_scheme_help_text:
    "Apply your accessibility display settings to the console. <cta>{verb}</cta> about our accessibility features and ratings.",
  select_company_type: "Select company type",
  select_profession: "Select profession",
  reset_email_message:
    "<cd>{emailAddress}</cd> will be saved after you verify your new address.",
  "settings.profile.choosefile": "Change display picture",
  "settings.profile.maxfilesize": "Max. size 1.91MB",
  "settings.profile.maxfilesizeerror":
    "Warning file size exceeds the 1.91 MB limit. {name} can not be saved as it's {size}MB.",
  "environmentTree.menu.empty": "No results",
  "environmentTree.navbar.search": "Search",
  "environmentTree.navbar.filter.label": "Filter by",
  "environmentTree.navbar.filter.title": "Filter environments",
  "environmentTree.navbar.filter.show": "Show",
  "environmentTree.navbar.filter.inactive": "Inactive",
  "environmentTree.navbar.filter.active": "Active",
  "environmentTree.navbar.filter.sort": "Sort by",
  "environmentTree.navbar.filter.sort_available":
    "Environments can only be sorted in the list view.",
  "environmentTree.navbar.sort.title": "Alphabetically A-Z",
  "environmentTree.navbar.sort.created_at": "Date created",
  "environmentTree.sort.title": "Environment name",
  "environmentTree.sort.type": "Type",
  "environmentTree.sort.created_at": "Created",
  "environmentTree.sort.deployed_at": "Deployed",
  "environmentTree.sort.url": "URLs",
  "environmentTree.line.root": "Main branch",
  "environmentTree.line.root.inactive": "Inactive Main branch",
  "environmentTree.line.child.inactive": "Inactive child branch",
  "environmentTree.line.route.ssl_encrypted": "SSL encrypted",
  "environmentTree.line.commit": "Commit: {sha}",
  "environmentTree.line.parent": "Parent branch",
  "environmentTree.line.parent.inactive": "Inactive Parent branch",
  "environmentTree.line.deployment.inprogress": "Deploying...",
  "environmentTree.line.deployment.activity.inprogress": "Activity in progress",
  "environmentTree.line.deployment.last.failed": "Last deployment failed",
  "environmentTree.line.deployment.last.not_found": "No deployment found",
  "environmentTree.line.deployment.last.success": "Last deployment successful",
  "environmentTree.line.deployment.is.paused": "Environment is paused",
  "security.header": "Authentication Settings",
  "security.description":
    "Multi-factor authentication (MFA) provides additional security for your account. With MFA enabled, you log in to the site with a verification code in addition to your username and password.",
  "security.manage.header.disabled": "MFA Application",
  "security.manage.header.enabled": "MFA Enabled",
  "security.manage.setup": "Set up application",
  "security.manage.disable": "Disable MFA",
  "security.manage.description":
    "Generate verification codes from a mobile or desktop application.",
  "security.manage.modal.title": "Remove MFA",
  "security.manage.modal.text":
    "Are you sure you wish to remove Multi Factor Authentication?",
  "security.manage.modal.confirm": "Yes, remove",
  "security.manage.modal.cancel": "Cancel",
  "security.setup.heading": "MFA application setup ",
  "security.setup.header": "Install authentication code application",
  "security.setup.textcode": "Authenticator text code",
  "security.setup.help":
    "Enter this code into your two-factor authentication app or scan the QR code below.",
  "security.setup.qr_code": "QR Code",
  "security.setup.verificationcode": "Verification Code",
  "security.setup.verificationcode.placeholder": "Enter code here",
  "security.setup.submit": "Verify & Save",
  "security.setup.reset": "Cancel",
  "security.recovery.header": "Recovery Codes",
  "security.recovery.description":
    "Pre-generated, one-time-use codes intended as a fallback should other methods be unavailable.",
  "security.recovery.reset": "Get new recovery codes",
  "security.authenticators.title": "Featured authenticators",
  "security.authenticators.description":
    "You can enable MFA with these authenticators or one of your preference.",
  "security.authenticators.footer":
    "You can use any OTP compatible app to enable the MFA.",
  no_integration: "No integrations",
  add_integration_to_project:
    "Connect a Git service, health checks, or webhooks to your project",
  cli_integration:
    "Our Command Line Interface (CLI) will walk you through setting up various integrations. You can also learn more about integrations in our <a>documentation</a>. To get started, use the following command",
  "settings.backupSchedules.title": "Backup schedules",
  "settings.backupSchedules.description":
    "Configure your backup schedules for each environment type for this project.",
  "settings.backupSchedules.more_info":
    "Learn more about Backup schedules in our",
  "settings.backupSchedules.backup.environment": "Environment type",
  "settings.backupSchedules.backup.frequency": "Frequency",
  "settings.backupSchedules.backup.total": "Total Backups",
  "settings.backupSchedules.manage_link": "Manage",
  "settings.backupSchedules.tooltip.title": "Total backups",
  "settings.backupSchedules.tooltip.description":
    "The total of all schedules plus the maximum manual backups possible for each environment of  this type.",
  "integrations.title": "Integrations",
  "integrations.add": "Add integration",
  "integrations.no_integration": "No integrations",
  "integrations.add_integration_to_project":
    "Connect a Git service, health checks, or webhooks to your project",
  "integrations.cli_integration":
    "Our Command Line Interface (CLI) will walk you through setting up various integrations. You can also learn more about integrations in our <a>documentation</a>. To get started, use the following command",
  "integrations.category.git": "Git Service",
  "integrations.category.health": "Health Notification",
  "integrations.category.custom": "Custom",
  "integrations.category.blackfire": "Monitoring",
  "integrations.category.observability_suite": "Log forwarding",
  "integrations.category.monitoring": "Monitoring",
  "integrations.category.httplog": "HTTP log",
  "integrations.getting_started.title": "Select an integration to get started",
  "integrations.getting_started.intro":
    "Email integrations are created by default that send health notifications to your project admins. Select an integration below to add it to your project.",
  "integration.title": "Details",
  "integration.info.title": "Integrations Settings",
  "integration.log_forwarding_form_title":
    "Enter the information for your {type} integration in the fields below.",
  "integration.log_forwarding_form_cost_notice":
    "* Outgoing bandwidth from log forwarding integrations are included in usage costs.",
  "integration.info.content":
    "Connect and manage your integrations here or customize them through the CLI. Note that some integrations can not be verified.",
  "integration.table.headers.integration": "Integration",
  "integration.table.headers.category": "Category",
  "integration.table.headers.id": "ID",
  "integration.info.link":
    "Learn more about integrations in our <a>documentation</a>",
  "integration.activity": "Activity",
  "integration.verify": "Verify integration",
  "integration.health.from_address": "From address",
  "integration.health.recipients": "Recipients",
  "integration.health.url": "Webhook URL",
  "integration.health.shared_key": "Shared key",
  "integration.health.routing_key": "Routing key",
  "integration.health.channel": "Channel",
  "integration.git.repository": "Repository",
  "integration.git.id": "ID",
  "integration.git.base_url": "Enterprise base URL",
  "integration.git.settings": "Project settings",
  "integration.git.fetch_branches":
    "Fetch branches from the {name} repository to your project.",
  "integration.git.prune_branches":
    "Remove branches from your project that have disappeared from {name}.",
  "integration.git.build_merge_requests":
    "Create new branches and run the build for each pull request.",
  "integration.git.build_wip_merge_requests":
    "Create new branches and run the build for each draft pull request.",
  "integration.git.merge_requests_clone_parent_data":
    "Re-sync environment data on every pull request build.",
  "integration.git.build_draft_pull_requests":
    "Create new branches and run the build for each draft pull request.",
  "integration.git.pull_requests_clone_parent_data":
    "Clone parent environment’s data when creating a pull request environment.",
  "integration.git.build_pull_requests":
    "Create new branches and run the build for each pull request.",
  "integration.git.build_pull_requests_post_merge":
    "Build the result of merging the pull request.",
  "integration.git.resync_pull_requests":
    "Re-sync environment data on every pull request build.",
  url: "URL",
  "integration.custom.events": "Events",
  "integration.custom.states": "States",
  "integration.custom.environments": "Environments",
  "integration.custom.excluded_environments": "Excluded environments",
  "integration.custom.supported_runtimes": "Supported runtimes",
  "integration.blackfire.description":
    "Monitor, validate and improve site performance with Blackfire, a {appName} product.",
  "integration.blackfire.url": " Learn more.",
  "integration.blackfire.project.variables.url": " View project variables.",
  "integration.blackfire.environment.variables.url":
    " View {environment} environment variables.",
  "integration.blackfire.warning":
    "Blackfire variables in an environment are overriding this integration.",
  "integration.blackfire.warning.multiple":
    "Blackfire variables in an environment are overriding this integration. View or manage these variables in: ",
  "integration.blackfire.multiple.project.environments.url":
    "Project variables",
  "integration.blackfire.multiple.main.environments.url": "Main",
  "integration.blackfire.info.title": "Integrations settings",
  "integration.blackfire.info.description":
    "You can customize your integrations through our Command Line Interface (CLI).",
  "modal.integration.info.title": "Learn more",
  "modal.integration.info.content":
    "You can use this terminal command to set up new integrations with your project.",
  "modal.integration.button": "Got it!",
  "modal.ide.update": "Update and close",
  "activities.empty": "No activity",
  "activities.update_filters":
    "Update your filters to show other activity types.",
  connect_with: "Connect with",
  disconnect: "Disconnect",
  not_connected: "Not connected",
  connected: "Connected",
  provider: "Provider",
  remove_connected_account: "Remove connected account",
  are_you_sure_disconnect_provider:
    "Are you sure you want to disconnect your <b>{provider}</b> account?",
  yes: "Yes",
  connected_accounts: "Connected accounts",
  you_can_connect_external_provider:
    "You can connect your account with these external providers.",
  "tickets.list.quicklinks.header": "Quick Links",
  "tickets.list.quicklinks.paragraph":
    "Can’t find what you’re looking for? Try our quick links below to learn more or connect with your {appName} peers in the community forum.",
  "tickets.list.quicklinks.gettingstarted": "Getting started",
  "tickets.list.quicklinks.live": "Going live",
  "tickets.list.quicklinks.tutorials": "Tutorials",
  "tickets.list.quicklinks.reference": "Reference & APIs",
  "tickets.list.quicklinks.announcements": "Announcements",
  "tickets.list.quicklinks.qa": "Q&A",
  "tickets.list.quicklinks.requestfeature": "Request a feature",
  "tickets.list.quicklinks.featureroadmap": "Feature roadmap",
  "tickets.list.community.header": "Community Forum",
  "tickets.list.community.paragraph":
    "Get help from our community on a wide range of topics, make feature requests and more!",
  "tickets.list.community.explore": "Explore the forum",
  "tickets.list.morehelp": "Still need more help?",
  "tickets.list.discord": "Visit our public Discord channel",
  "tickets.list.header": "Support tickets",
  "tickets.list.table.ticket_id": "#",
  "tickets.list.table.priority": "Priority",
  "tickets.list.table.subject": "Subject",
  "tickets.list.table.subscription": "Project",
  "tickets.list.table.updated": "Updated",
  "tickets.list.table.status": "Status",
  "tickets.sort": "Sort by",
  "tickets.show": "Show",
  "tickets.filterBy": "Filter by",
  "tickets.filter": "Filter tickets",
  "tickets.new": "New ticket",
  "tickets.new.mobile": "New",
  "tickets.more": "Show more",
  "tickets.notickets": "No tickets",
  "tickets.notickets.description.issues":
    "If you’re having any issues please <a>create a support ticket</a> and we’ll take a look at it for you.",
  "tickets.notickets.description.discord":
    "You can also check out our public <a>Discord channel</a> for more support and help.",
  "tickets.create": "Create a ticket",
  submit: "Submit",
  "tickets.open.project": "Project",
  "tickets.open.project.placeholder": "Select project",
  "tickets.open.priority": "Select priority",
  "tickets.open.priority.placeholder": "Select priority",
  "tickets.open.category": "Select category",
  "tickets.open.category.placeholder": "Select category",
  "tickets.open.priorityDialog.text":
    "Not sure which level of urgency your ticket requires? Take a look at our",
  "tickets.open.priorityDialog.link": "documentation",
  "tickets.open.subject": "Ticket subject",
  "tickets.open.description": "Description",
  "tickets.open.description.placeholder":
    "Describe the issue in as much detail as possible",
  "tickets.open.url": "Affected URL",
  "tickets.open.url.placeholder": "Enter URL",
  "tickets.open.invalidurl": "The format of the affected URL is not valid",
  "tickets.open.environment": "Environment",
  "tickets.open.environment.placeholder": "Select environment",
  "tickets.open.attach": "Attach file",
  "tickets.open.attachments": "Attachments",
  "tickets.open.attach.fileLimit": "Exceeds 20MB/file limit",
  "tickets.open.attach.fieldLimit": "Exceeds 50MB field limit",
  "tickets.open.general.question": "General question (not project related)",
  "tickets.open.bundle_size": "{MB}MB/{limit}MB total",
  "tickets.priority.professional.contact":
    "Upgrade to Enterprise for SLA Support",
  "tickets.priority.low.label": "Low",
  "tickets.priority.low.description":
    "Does not significantly impact a production site or the use of non-production environments.",
  "tickets.priority.low.premier": "SLA 72 hours",
  "tickets.priority.sticker.low.premier": "72 hours",
  "tickets.priority.low.enterprise": "No SLA",
  "tickets.priority.low.elite": "No SLA",
  "tickets.priority.normal.label": "Normal",
  "tickets.priority.normal.description":
    "Minor effect on a production site or blocking development of non-production environments.",
  "tickets.priority.normal.premier": "SLA 72 hours",
  "tickets.priority.normal.enterprise": "SLA 24 business hours",
  "tickets.priority.normal.elite": "SLA 8 hours, 24x7",
  "tickets.priority.sticker.normal.premier": "72 hours",
  "tickets.priority.sticker.normal.enterprise": "24 business hours",
  "tickets.priority.sticker.normal.elite": "8 hours, 24x7",
  "tickets.priority.high.label": "High",
  "tickets.priority.high.description":
    "Severe failures or high-impact issues affecting a production site.",
  "tickets.priority.high.premier": "SLA 48 hours",
  "tickets.priority.high.enterprise": "SLA 8 business hours",
  "tickets.priority.high.elite": "SLA 4 hours, 24x7",
  "tickets.priority.sticker.high.premier": "48 hours",
  "tickets.priority.sticker.high.enterprise": "8 business hours",
  "tickets.priority.sticker.high.elite": "4 hours, 24x7",
  "tickets.priority.urgent.label": "Urgent",
  "tickets.priority.urgent.description":
    "Total failure or unavailability of a production site - page a support engineer.",
  "tickets.priority.urgent.premier": "SLA 4 hours",
  "tickets.priority.urgent.enterprise": "SLA 1 hour, 24x7",
  "tickets.priority.urgent.elite": "SLA 45 minutes, 24x7",
  "tickets.priority.sticker.urgent.premier": "4 hours",
  "tickets.priority.sticker.urgent.enterprise": "1 hour, 24x7",
  "tickets.priority.sticker.urgent.elite": "45 minutes, 24x7",
  "tickets.priority.contact.sales": "Contact sales to upgrade",
  "tickets.sticker": " {priority} priority SLA : ",
  "tickets.sticker.low": " Tickets with low priority do not include an SLA. ",
  "tickets.sticker.professional.support": "{supportTier} support.",
  "tickets.status.pending.label": "Awaits reply",
  "tickets.status.open.label": "Open",
  "tickets.status.hold.label": "On-hold",
  "tickets.status.solved.label": "Solved",
  "tickets.status.closed.label": "Closed",
  "tickets.status.deleted.label": "Deleted",
  "tickets.sortby.status.label": "Status",
  "tickets.sortby.updated.label": "Last updated",
  "tickets.sortby.created.label": "Date created",
  "tickets.sortby.project.label": "Project id",
  "tickets.sortby.priority.label": "Priority",
  "tickets.sortby.ticket_id.label": "#",
  "tickets.sortby.subject.label": "Alphabetically A-Z",
  "tickets.status.new.label": "New",
  "tickets.view.info.header": "Ticket #{ticketId}",
  "tickets.view.info.projectId": "Project ID",
  "tickets.view.info.region": "Region",
  "tickets.view.info.environment": "Environment",
  "tickets.view.info.priority": "Priority",
  "tickets.view.description": "Description",
  "tickets.view.solve": "Solve ticket",
  "tickets.view.followup": "Create follow-up",
  "tickets.view.reopen": "Re-open ticket",
  "tickets.comments.header": "Comments",
  "tickets.comments.add": "Add a comment",
  "tickets.comments.send": "Send",
  "tickets.comments.comment.placeholder": "Add a comment",
  "tickets.comments.recent": "Recent comments",
  "tickets.comments.support_tag": "Support",
  "tickets.view.markedas": "Ticket marked <status></status> on {date}.",
  "tickets.project.support": "Project support",
  "tickets.general.support": "General support",
  "trustcenter.title": "Trust center",
  "trustcenter.content":
    "Have a security, privacy, legal, or other compliance-related question? Check out our Trust Center for detailed information.",
  "trustcenter.link": "Visit trust center",
  "invitation.add": "Add",
  "invitation.pending": "Invite pending",
  "invitation.permisssions.missing":
    "You don't have enough permissions to add a user",
  "invitation.details.pending": "Pending invitation",
  "invitation.details.email": "Email",
  "invitation.table.admin": "Admin",
  "invitation.table.project": "Project",
  "invitation.table.environments": "Environments",
  "invitation.info": "Invited by {name} on {date}",
  "invitation.resend": "Resend invitation",
  "invitation.resend_success": "Resent invitation",
  "invitation.revoke": "Revoke invitation",
  "invitation.revokeModalTitle": "Revoke invitation",
  "invitation.revokeModalBody":
    "Are you sure you want to revoke the invitation for {email}? ",
  "invitation.manualResendModalBody":
    "Are you sure you want to resend the invitation for {email}? ",
  "invitation.display_name_will_display":
    "Display name will display if invitation is accepted",
  "invitation.pendingFail":
    "Your invitation is still being processed, please try again later.",
  "invitation.pendingLoading": "Checking access...",
  "invitation.confirmationModalTitle": "Invitation sent",
  "invitation.confirmationModalBody":
    "An invitation has been sent to {email} and will appear in your project's access settings once accepted.",
  "invitation.resendModalTitle": "Invitation already sent",
  "invitation.resendModalBody":
    "An invitation has already been sent to {email}. Do you want to send this invitation again?",
  "settings.variables.value_error":
    "The format of your variable is not valid JSON.",
  "settings.project.variables.description":
    "Project variables are available for all environments of the project. For the changes to take effect, the environments need to be redeployed.",
  "settings.project.variables.note":
    "Learn more about variables in our <link>documentation</link>.",
  "settings.project.variables.back_to": "Back to Project variables",
  "settings.project.variables.create": "Create project variable",
  "settings.project.variables.sort.name": "Variable",
  "settings.project.variables.sort.value": "Value",
  "settings.project.variables.sort.type": "Type",
  "settings.project.variables.list.empty":
    "No project variables to display, <link>add a variable</link> to get started",
  "settings.environment.variables.list.empty":
    "No {type} variables to display, <link>add a variable</link> to get started",
  "settings.environment.variables.create": "Create environment variable",
  "settings.environment.variables.back_to": "Back to environment variables",
  "settings.variables.inherited": "Inherited",
  "settings.variables.inherited.details": "Inherited from a parent environment",
  "settings.variables.overriding": "Overriding",
  "settings.variables.overriding.details.environment":
    "Overriding the parent environment's variable",
  "settings.variables.overriding.details.project":
    "Overriding the project-level variable",
  "settings.variables.disabled": "Disabled",
  "settings.variables.override": "Save and override",
  "settings.variables.inactive": "Inactive",
  "settings.variables.inactive.details":
    "An environment variable is overriding this variable",
  "settings.variables.note":
    "<dt>Note</dt><dd>Variables beginning with <code>env:</code> will be exposed as Unix environment variables.</dd><dd>Variables beginning with <code>php:</code> will be interpreted as <code>php.ini</code> directives.</dd><dd>All other variables will be part of <code>$PLATFORM_VARIABLES</code> variable.</dd>",
  "settings.variables.modal.title": "Are you sure you want to delete this?",
  "settings.variables.modal.body": "Yes, delete the variable",
  "settings.variables.notes.title": "Note:",
  "settings.variables.notes.detail_beginning":
    "Variables beginning with <code>env:</code> will be exposed as Unix environment variables.",
  "settings.variables.notes.detail_different":
    "Variables with a different prefix and unprefixed variables will be part of the {appEnvironmentVariableName} environment variable. <link>Learn more</link>.",
  "settings.variables.notes.detail_adding":
    "Adding or updating an environment level variable will trigger a redeploy.",
  "settings.certificates.modal.body": "Yes, delete the certificate",
  "settings.deployKey.help.a": "Learn more about Deploy Keys in our",
  "settings.deployKey.help.b": "documentation",
  "settings.domains.title": "Domains",
  "settings.domains.name": "Domain",
  "settings.domains.date": "Created at",
  "settings.domains.placeholder": "yourdomain.com",
  "settings.domains.default": "Default",
  "settings.domains.upgrade_plan": "Upgrade plan",
  "settings.domains.add.title": "Add a default domain",
  "settings.domains.add.new.title": "Add domain",
  "settings.domains.custom.add.new.title": "Add custom domain",
  "settings.domains.add.button": "Add domain",
  "settings.domains.save.button": "Add domain",
  "settings.domains.prompt_upgrade.plan_owner":
    "Upgrade to a production plan to add a custom domain.",
  "settings.domains.prompt_upgrade":
    "Ask the organization owner to upgrade the plan to add a custom domain.",
  "settings.domains.prompt_support":
    "To add a custom domain please open a support ticket.",
  "settings.domains.info":
    "You can only add custom domains to your production environment from this page. To add a custom domain to a preview environment, go to your environment settings.",
  "settings.domains.info.enter.domain": "Enter the domain or sub-domain.",
  "settings.domains.learn_more":
    "Learn more about Domains in our <link>documentation</link>",
  "settings.domains.learn_more.add_domain":
    "Learn more about domains in our <link>how-to-guide</link>.",
  "settings.domains.contact_support":
    "If you are an Enterprise or Elite customer, please <link>contact support</link> to enable custom domains.",
  "settings.domains.traffic":
    "Traffic from additional domains will be redirected to this default domain.{br}Do not add www. if your routes.yaml file already specifies it.{br}If you have multiple subdomains defined in your routes.yaml file, just enter the part you want to map to '{default}'.",
  "settings.domains.config.title": "Configure your domain",
  "settings.domains.config.line_1":
    "Add the CNAME record below to your DNS provider. Check out <a>how-to set the CNAME record for your DNS provider</a>.",
  "settings.domains.config.line_2":
    "If you want to use the same domain in several projects, point a <a>TXT record</a> to the platformsh.site URL below.",
  "settings.domains.cname": "CNAME record",
  "settings.domains.cname.target": "CNAME target",
  "settings.domains.delete": "delete domain",
  "settings.domains.make_default": "Make default domain",
  "settings.domains.modal.delete.title": "Delete domain",
  "settings.domains.modal.delete.body":
    "Are you sure you want to delete the domain <b>{domain}</b>? Please note that this will redeploy your environment.",
  "settings.domains.modal.delete.body.default.switch":
    "Are you sure you want to delete the default domain <b>{domain}</b>? Please note, <b>{otherDomain}</b> will be a new default domain for your project.",
  "settings.domains.modal.delete.body.default.select":
    "Are you sure you want to delete the default domain <b>{domain}</b>? Please note, a new default domain is selected in the field below.",
  "settings.domains.modal.delete.body.custom_domains":
    "If this domain has preview environment domains attached to it, they will also be deleted. Preview environments will not be automatically redeployed. Consider redeploying these environments so that routes are updated correctly.",
  "settings.domains.modal.delete.subtitle.attached_domains":
    "Your domain <b>{domain}</b> is attached to preview environment domain: ",
  "settings.domains.modal.delete.body.attached_domains":
    "You must delete those attached preview environment domain before you can delete  <b>{domain}</b>",
  "settings.domains.modal.delete.select": "Default domain",
  "settings.domains.modal.delete.confirm": "Yes, delete",
  "settings.domains.delete.domain":
    "Are you sure you want to delete the domain {domainName}?",
  "settings.domains.add.body":
    "By default, your environment has a <i>.platformsh.site</i> URL. If you want to change the web address, you need to add a custom domain, such as <i>example.com.</i>.",
  "settings.domains.add.staging.body":
    "By default, your environment has a <i>.platformsh.site</i> URL. If you want to change the web address, you need to add a custom domain, such as <i>staging.example.com.</i>",
  "settings.domains.configuration.description":
    "Before adding a domain, copy the target below and add it to your DNS provider as a CNAME record. <link>Learn more</link>",
  "settings.domains.add.domain.learn_more":
    "https://docs.platform.sh/domains/steps/custom-non-production-domains.html",
  "settings.domains.stcker.no_permissions":
    "Ask your project admin to add a custom domain.",
  "settings.domains.stcker.no_permissions_prod_domain":
    "Ask your project admin to add a domain.",
  "settings.domains.stcker.no_production_plan":
    "Upgrade to a production plan to add a custom domain.",
  "settings.domains.stcker.no_production_domain":
    "You need to add a production domain first before adding a preview environment domain.",
  "settings.domains.stcker.no_capability":
    "An unexpected error has occurred, please open a Support Ticket",
  "settings.domains.empty.upgrade.title": "Upgrade for custom domains",
  "settings.domains.empty.upgrade.body":
    "Upgrade to Enterprise to unlock custom domains on preview environments.",
  "settings.domains.button.add_production_domain": "Add production domain",
  edit_user_organization:
    "Edit the permissions of your user for this organization",
  "settings.variables.description":
    "Project variables are available for all project environments and during the build process.",
  "settings.environment.variables.description":
    "Environment variables are available for this environment and its children if made inheritable. Child environments must be redeployed for the variable to be available.",
  "settings.variables.variables": "Variables",
  "settings.variables.details":
    "Variables beginning with <code>env:</code> will be exposed as Unix environment variables{br} Variables beginning with <code>php:</code> will be interpreted as <code>php.ini</code> directives.{br} All other variables will be part of the environment <code>{appEnvironmentVariableName}</code> variable",
  "settings.variables.edit_name":
    "Variable names can not be edited once created. To adjust one, you’ll need to create another and delete the one you’re replacing",
  "settings.variables.empty":
    "No variables to display, add a variable to get started",
  "members.confirmationDeletenModalTitle": "Remove user",
  "member.confirmationUpdatePermissionsModalTitle": "Edit organization roles",
  "members.confirmationDeleteModalBody":
    "Are you sure you want to remove <b>{email}</b> from the organization <b>{organizationName}</b>? This will remove this {count, plural, one{user} other{users}} from all projects within this organization",
  "member.confirmationUpdatePermissionModalBody":
    "Are you sure you want to {action} <b>{addedRoles}</b> {rolePluralAdded} {bothActions} <b>{removedRoles}</b> {rolePluralRemoved} for the user <b>{username}</b>({email})? {plural} affect all projects within the organization.",
  "settings.variables.add.title": "Add variable",
  "settings.organization_settings": "Organization settings",
  "settings.details": "Details",
  "settings.organization_intruduction_text":
    "Your organization is now the owner of your projects and is used for billing and user management. Your new streamlined user management interface allows you to invite your contributors and edit their permissions all from one place.",
  "settings.introducing_organizations": "Welcome to Organizations!",
  "settings.details.informations":
    "Your organization label and URL are the unique identifiers of your organization. You can change these any time in the Settings of the organization. Remember that once a label or URL is taken it is no longer available.",
  "settings.variables.add.button": "Create variable",
  "settings.variables.enabled": "Enabled",
  "settings.variables.inherited_from": "Inherited from: {from}",
  "settings.variables.overriding_from.environment": "Overriding: {from}",
  "settings.variables.overriding_from.project": "Overriding: project variable",
  "settings.variables.name": "Variable name",
  "settings.variables.during_run":
    "<b>Available during runtime:</b> Make this variable available during the runtime of this project",
  "settings.variables.during_run.more":
    "Runtime variables are available to your application while it's running and/or deploying.",
  "settings.variables.during_build":
    "<b>Available during buildtime:</b> Make this variable available during the build hook step of your <code>{appConfigFile}</code>",
  "settings.variables.during_build.more":
    "Build time variables change the result of your build. Use them when your framework or application requires variable changes between environments.",
  "settings.variables.is_json":
    "<b>JSON value:</b> Interpret as a JSON-serialized value instead of a simple string",
  "settings.variables.is_json.more":
    "To learn more about the JSON variable setting read our documentation.",
  "settings.variables.sensitive":
    "<b>Sensitive variable:</b> Do not display this variable in the user interface",
  "settings.variables.sensitive.more":
    "Sensitive variables are not displayed in the console interface but are still readable from within the application.",
  "settings.variables.inheritable":
    "<b>Make inheritable:</b> Make child environments inherit this variable",
  "settings.variables.inheritable.more":
    "When enabled all child environments of this environment will inherit this variable",
  "settings.environment.variables.list.environment":
    "{title} variables {count}",
  "settings.environment.variables.list.project": "Project variables {count}",
  "settings.variables.labels.is_sensitive": "Sensitive",
  "settings.variables.labels.visible_build": "Build",
  "settings.variables.labels.visible_runtime": "Runtime",
  "settings.variables.labels.inherited": "Inherited",
  "settings.variables.labels.is_inheritable": "Inheritable",
  "settings.variables.labels.is_json": "Json",
  "settings.variables.warning":
    "When you edit a project variable your changes are applied to every environment.",
  "settings.environment.variables.warning":
    "When you edit a parent variable its changes are applied to every child environment unless it has been overridden. This will trigger a redeploy to commit your changes.",
  "settings.environment.variables.warning.child":
    "Editing a variable will override it on this environment and its future children.",
  "organization.tier.description":
    "Projects created within this organization will inherit the {tier} features.",
  "organization.billing.title": "Organization overview",
  "organization.billing.current_spend_this_month": "Current spend this month",
  "organization.billing.last_update_at_value": "Last updated at {value}",
  "organization.billing.current": "Current",
  "organization.billing.future": "Future",
  "organization.billing.type": "Type",
  "organization.billing.unit_price": "Unit price",
  "organization.billing.quantity": "Quantity",
  "organization.billing.current_spend": "Current spend",
  "organization.billing.current_spend.description":
    "The cost that has accumulated so far this month. This includes any changes made prior to today that will be reflected on your monthly invoice.",
  "organization.billing.prorated_quantity": "Prorated quantity",
  "organization.billing.prorated_quantity.description":
    "Based on an average of past changes and the current settings applied for all remaining days in the month.",
  "organization.billing.projected_total": "Projected total",
  "organization.billing.projected_total.title.current":
    "This month’s projected total",
  "organization.billing.projected_total.title.future":
    "Future months’ projected total",
  "organization.billing.projected_total.description.current":
    "Based on the usage to date and estimating the rest of the month using the daily average.",
  "organization.billing.projected_total.description.future":
    "Based on your existing allocations or daily average, plus any add-ons for one month.",
  "organization.billing.projects": "Projects",
  "organization.billing.users": "Users",
  "organization.billing.user_management": "User management",
  "organization.billing.per.user": "{price} per user",
  "organization.billing.per.user.addon": "+{price} per user",
  "organization.billing.subtotal": "Subtotal",
  "organization.billing.voucher": "Vouchers",
  "organization.billing.discount": "Discounts",
  "organization.billing.discount.allowance_discount": "First Project Incentive",
  "organization.billing.discount.allowance_discount.trial":
    "First Project Incentive*",
  "organization.billing.discount.startup_discount": "Startup Discount",
  "organization.billing.discount.enterprise_discount": "Enterprise Discount",
  "organization.billing.month": "{month} {type}",
  "organization.billing.this_month": "This month",
  "organization.billing.future_months": "Future months",
  "organization.billing.this_months_projection": "This month's projection",
  "organization.billing.future_months_projection": "Future months projection",
  "organization.billing.monthly_estimate.current": "Projected total",
  "organization.billing.monthly_estimate.next": "Projected total",
  "organization.billing.invoice": "Previous invoice",
  "organization.billing.btn.view_invoice": "View invoice",
  "organization.billing.btn.download": "Download",
  "organization.billing.free": "Free",
  "organization.billing.subscribe.title": "Upgrade from trial",
  "organization.billing.subscribe.description":
    "Save up to $19 every month on your first user license and first project fee with <Link>First Project Incentive</Link>.*",
  "organization.billing.subscribe.description.terms":
    "*<Link>Terms and conditions apply</Link>",
  "organization.billing.subscribe.check1": "Preview environments",
  "organization.billing.subscribe.btn.upgrade": "Upgrade",
  "organization.billing.subscribe.check2": "Observability",
  "organization.billing.subscribe.check3": "Team management",
  "organization.billing.subscribe.btn.subscribe": "Subscribe",
  "organization.billing.subscribe.btn.subscribe.tooltip":
    "Ask your admin to subscribe",
  "organization.billing.subscribe.btn.learnmore": "Learn more",
  "organization.billing.subscribe.btn.disclaimer":
    "*Contact organization owner to subscribe",
  "organization.billing.estimate.title.current": "Estimated invoice total",
  "organization.billing.estimate.description.current":
    "These totals are estimates based on changes made so far this month - plus the current resource allocation if nothing changes for the rest of the month.",
  "organization.billing.estimate.title.next": "Future projected monthly total",
  "organization.billing.estimate.description.next":
    "These totals are what you would expect to pay for one month with your existing resource allocation plus any add-ons, including VAT.",
  "organization.billing.invoice.next.month": "{month} project breakdown",
  "organization.billing.invoice.next.description":
    "Future projected costs are estimates based on your existing resource allocation plus any add-ons for one month.",
  "organization.billing.invoice.next.vat.description":
    "Future projected costs are estimates based on your existing resource allocation plus any add-ons for one month. When applicable, VAT is included in the costs listed below.",
  "organization.billing.breakdown.title": "{name} billing",
  "organization.billing.breakdown.set.alert": "Set alert",
  "organization.billing.breakdown.current.alert": "Alert at {alertThreshold}",
  "organization.billing.breakdown.user_licenses": "User licenses",
  "organization.billing.breakdown.users": "{number} user{s}",
  "organization.billing.breakdown.manage_users": "Manage users",
  "organization.billing.breakdown.viewers": "Viewers",
  "organization.billing.breakdown.support": "Support",
  "organization.billing.breakdown.support.sla": "Support SLA - Standard",
  "organization.billing.breakdown.global_spend":
    "10% global spend for 8 hour response",
  "organization.billing.breakdown.project_billing": "Project billing",
  "organization.billing.breakdown.project_settings": "Project settings",
  "organization.billing.modal.title": "User management add on",
  "organization.billing.modal.description":
    "Upgrade today to enhance your user's collaboration with flexible permissions and teams, and strengthen your organization's security by enforcing MFA.",
  "organization.billing.modal.current": "(Current)",
  "organization.billing.modal.default": "Default",
  "organization.billing.modal.default.detail":
    "Use only default permissions that are included with user licenses.",
  "organization.billing.modal.standard": "Standard user management",
  "organization.billing.modal.standard.detail":
    "+{price}/user - includes flexible permissions, Teams, and MFA",
  "organization.billing.modal.comply.trial":
    "I understand that I'm committing to this upgrade for at least 30 days after my free trial has ended",

  "organization.billing.modal.comply":
    "I understand that I'm committing to this upgrade for at least 30 days",
  "organization.billing.modal.note":
    "Note: User management changes cannot be downgraded for 30 days.",
  "organization.billing.upgrading": "Upgrade",
  "organization.billing.downgrading": "Downgrade",
  "organization.billing.modal.remaining":
    "{days} {days, plural, one{day} other{days}} left in minimum commitment",
  "organization.billing.trial_discount_not_included":
    "The trial discount is not included.",
  "project.billing.title": "Project overview",
  "project.billing.breakdown.project.settings": "Project settings",
  "project.billing.breakdown.project.view": "View project",
  "project.billing.view_all_value_environments":
    "View all {value} {value, plural, one{environment} other{environments}}",
  "project.billing.hide_environments": "Hide environments",
  "project.billing.row.resource_allocation": "Total resource allocation",
  "project.billing.metric": "Metric",
  "project.billing.unit_price": "Unit price",
  "project.billing.current_accumulation": "Current accumulation",
  "project.billing.current_allocation": "Current allocation",
  "project.billing.daily_average": "Daily average",
  "project.billing.daily_average.description":
    "Based on the average of the usage over the last 30 days.",
  "project.billing.projected_total": "Projected total",
  "project.billing.resources": "Resources",
  "project.billing.traffic": "Traffic",
  "project.billing.project_add_ons": "Project add-ons",
  "project.billing.observability": "Observability",
  "project.billing.build_resources": "Build resources",
  "project.billing.origin_traffic": "Origin traffic",
  "project.billing.bandwidth": "Bandwidth",
  "project.billing.fastly": "CDN Traffic <small>(Fastly)</small>",
  "project.billing.logs_forwarding": "Logs forwarding",
  "project.billing.prorated_quantity": "Prorated quantity",
  "project.billing.quantity": "Quantity",
  "project.billing.current_spend": "Current spend",
  "project.billing.current_spend.description":
    "The cost that has accumulated so far this month. This includes any changes made prior to today that will be reflected on your monthly invoice.",
  "project.billing.application_resources": "Application resources",
  "project.billing.service_resources": "Service resources",
  "project.billing.cpu": "CPU",
  "project.billing.value_cpu": "{value} CPU",
  "project.billing.value_gb": "{value} GB",
  "project.billing.value_tb": "{value} TB",
  "project.billing.value_gb_ram": "{value} GB RAM",
  "project.billing.project.base": "Project base",
  "project.billing.paused.environment": "Environment is paused",
  "project.billing.project_fee": "Project fee",
  "project.billing.project.fee.included": "10 GB included",
  "project.billing.build.minutes": "Build minutes",
  "project.billing.value.build.minutes": "{minutes} minutes",
  "project.billing.application": "Application",
  "project.billing.usage": "Usage",
  "project.billing.memory": "Memory",
  "project.billing.service": "Service",
  "project.billing.storage": "Storage",
  "project.billing.disk.storage": "Disk Storage",
  "project.billing.backups": "Backups",
  "project.billing.backup.storage": "Backup Storage",
  "project.billing.environment": "Environment",
  "project.billing.type": "Type",
  "project.billing.ram": "RAM",
  "project.billing.disk": "Disk",
  "project.billing.by.resource": "By resource",
  "project.billing.by.environment": "By environment",
  "project.billing.resources_by_environment": "Resources by environment",
  "project.billing.build": "Build",
  "project.billing.label.build_cpu": "CPU",
  "project.billing.label.build_memory": "Memory (1 TB)",
  "project.billing.label.ingress_requests": "Requests",
  "project.billing.label.egress_bandwidth": "Bandwidth",
  "project.billing.label.fastly_requests": "Requests",
  "project.billing.label.fastly_bandwidth": "Bandwidth",
  "project.billing.label.logs_fwd_content_size": "Outbound payload",
  "project.billing.label.cpu_app": "CPU",
  "project.billing.label.memory_app": "Memory (1 GB)",
  "project.billing.label.cpu_services": "CPU",
  "project.billing.label.memory_services": "Memory (1 GB)",
  "project.billing.label.storage_app_services": "Disk storage",
  "project.billing.label.backup_storage": "Backups",
  "project.billing.value_slash_hour": "{value} / hour",
  "project.billing.value_cpu_hours": "{value} CPU hours",
  "project.billing.value_slash_1k_after_500k": "{value} / 1K (after 500K)",
  "project.billing.value_slash_1m": "{value} / 1M",
  "project.billing.value_slash_gb_after_10gb": "{value} / GB (after 10 GB)",
  "project.billing.value_slash_tb_after_10gb": "{value} / TB (after 10 GB)",
  "project.billing.value_requests": "{value} requests",
  "project.billing.value_m_requests": "{value}M requests",
  "project.billing.value_slash_gb": "{value} / GB",
  "project.billing.value_slash_tb": "{value} / TB",
  "project.billing.value_slash_month": "{value} / month",
  "project.billing.enterprise_only": "Enterprise only",
  "project.billing.na": "N/A",
  "project.billing.origin_traffic_note.short":
    "Origin traffic values are for the current month and updated daily.",
  "project.billing.origin_traffic_note.long":
    "Origin traffic values are for the current month and updated daily. Overages will be invoiced. Contact your account manager for quota and prepaid details.",
  "project.billing.cdn_traffic_note.short":
    "CDN usage is usage is for the current month and updated daily.",
  "project.billing.cdn_traffic_note.long":
    "CDN usage values are for the current month and updated daily. Overages will be invoiced. Contact your account manager for quota and prepaid details.",
  "organization.billing.organization_addons": "Organization add-ons",
  "project.billing.project_addons": "Project add-ons",
  "organization.billing.invoice.description":
    "This breakdown is based on the usage to date and then using averages to estimate remaining costs for the month.",
  "project.billing.invoice.description":
    "This breakdown is based on the usage to date and current allocation, and then uses averages to estimate remaining costs for the month.",
  "organization.billing.user_management.title": "User management",
  "organization.billing.user_management.body":
    "Upgrade today to enhance your user's collaboration with flexible permissions and teams, and strengthen your organization's security by enforcing MFA.",
  "organization.billing.premium_support.title": "Support SLA",
  "organization.billing.premium_support.body":
    "Upgrade to receive service-level agreements with faster response times for your support tickets.",
  "project.billing.continuous_profiling.title": "Continuous profiling",
  "project.billing.continuous_profiling.body":
    "Retain 30 days of continuous profiling data for {price} per month.",
  "organization.billing.upgrade": "Upgrade",
  "organization.billing.enable": "Enable",
  "organization.billing.enterprise_message":
    "Please note that discounts and contract details are not yet reflected in these billing pages. You can review this directly with your account manager.",
  "organization.billing.onboarding_training": "Onboarding - Training",
  "organization.billing.other": "Other",
  "project.billing.onboarding_setup": "Onboarding - Setup",
  "project.billing.enterprise_message":
    "Please note that discounts and contract details are not yet reflected in these invoices. You can review this directly with your account manager.",
  "billing_alerts_modal.title": "Set billing alert",
  "billing_alerts_modal.description":
    "Create an alert to monitor the <strong>projected monthly cost</strong> for {organizationName}. Users with the <code>Manage billing</code> permission will receive an email when your organization's costs reach the threshold specified below.",
  "billing_alerts_modal.note":
    "Please note: The projected monthly cost is an estimate.",
  "billing_alerts_modal.label": "Monthly threshold",
  "billing_alerts_modal.submit": "Create alert",
  "billing_alerts_modal.delete": "Delete alert",
  "billing_alerts_modal.error": "You must input a value to create an alert.",
  "billing_alerts_modal.notification.success.title":
    "New billing alert created",
  "billing_alerts_modal.notification.success.description":
    "Billing alert is set at {alertThreshold} for this organization",
  "billing_alerts_modal.notification.delete.title": "Billing alert deleted",
  "billing_alerts_modal.notification.delete.description":
    "A billing alert has been removed from this organization.",
  "template.modal.title.blank": "Create a blank project",
  "template.modal.overview": "Overview",
  "template.modal.cancel": "Cancel",
  "template.modal.save.blank": "Create blank project",
  "template.modal.save.template": "Select template",
  "metrics.title": "Metrics",
  "metrics.title.cpu": "CPU",
  "metrics.title.memory": "Memory",
  "metrics.title.disk": "Disk",
  "metrics.description.disk": "<b>{usage}</b> / {total} allocated disk space",
  "metrics.description.cpu": "Current CPU usage",
  "metrics.description.memory": "Current memory usagae",
  "metrics.deployment.toggle":
    "Showing only successful push, merge, and sync activities",
  "profiling.title": "Continuous profiling",
  "environment.access.redeploymessage":
    "For changes to take effect please redeploy this environment",
  "billing.commitments": "",
  "billing.commitments.current_commitments": "Current commitments",
  "billing.commitments.current_commitments.description":
    "The commitments are based on contracts that are currently active.",
  "billing.commitments.value_per_month": "{value} per month",
  "billing.commitments.value_discount": "{value} discount",
  "billing.commitments.view_details": "View details",
  "billing.commitments.value_per_month_commitment":
    "{value} per month commitment",
  "billing.commitments.explanation":
    "Earn the full value of your discount on your organizations spend by reaching your commitment. To change your commitment or discount structure contact your account manager. If you have questions regarding your invoice, you can reach out to the finance team.",
  "billing.commitments.monthly": "Monthly",
  "billing.commitments.monthly_commitment": "Monthly commitment",
  "billing.commitments.monthly_commitment.description":
    "A breakdown of the committed spend and discounts that can be expected on monthly invoices.",
  "billing.commitments.commitment_value": "Commitment value",
  "billing.commitments.monthly_discount": "Monthly discount",
  "billing.commitments.net_commitment": "Net commitment",
  "billing.commitments.contract_total": "Contract total",
  "billing.commitments.contract_total.description":
    "A total of the committed spend and discounts for the entire duration of the contract.",
  "billing.commitments.total_commitment_value": "Total commitment value",
  "billing.commitments.total_discount": "Total discount",
  "billing.commitments.total_net_commitment": "Total Net commitment",
  "billing.commitments.commitment_details": "Commitment details",
  "billing.commitments.commitment_details.description":
    "Details shown are determined by the commitment structure agreed upon in the contract.",
  "billing.commitments.discount_type": "Discount type",
  "billing.commitments.commitment_period": "Commitment period",
  "billing.commitments.commitment_period.description":
    "The duration in which the commitment must be reached.",
  "billing.commitments.value_months":
    "{value} {value, plural, one {month} other {months}}",
  "billing.commitments.total_contract_duration": "Total contract duration",
  "billing.commitments.contract_starting": "Contract starting",
  "billing.commitments.contract_ending": "Contract ending",
  "billing.commitments.value_commitment": "{value} Commitment",
  "billing.details.note.country":
    "<Link>Create a support ticket</Link> to change your country",
  "billing.details.errors.inputs": "Fill all required inputs",
  "billing.details.errors.email": "An email is required",
  "billing.details.errors.name": "A name is required",
  "billing.details.message.card": "Click update credit card to edit",
  "billing.details.message.sepa": "Click update bank accounts to edit",
  "billing.details.errors.name_line": "A billing address is required",
  "billing.details": "details",
  "billing.details.past.invoices": "Past invoices",
  "billing.details.past.invoice": "Past invoice",
  "billing.details.back.to.past.invoices": "Back to past invoices",
  "billing.details.back.to.upcoming.invoice": "Back to upcoming invoice",
  "billing.details.estimated.payment": "Estimated payment",
  "billing.details.upcoming.invoice": "Upcoming invoice",
  "billing.details.upcoming.invoice.description":
    "Your upcoming invoice is an estimate based on your current usage and add-ons for one month. \n To learn more about our pricing, see our {pricingPage}.",
  "billing.invoices.list.empty.title": "You have no invoices",
  "billing.invoices.list.empty.description":
    "Find monthly invoices for any projects that you own here after you create a project.",
  "billing.invoices.list.recurring.title": "Recurring {appName} subscription",
  "billing.invoices.list.previous.title": "Previous",
  "billing.plan.list.empty.title": "You have no project plans",
  "billing.plan.list.empty.description":
    "Find plan information here for any projects that you own.",
  "billing.plan.description.tier.enterprise":
    "Enterprise organization tiers include 1-hour urgent SLA. Upgrade to Elite for 45-minute urgent SLA.",
  "billing.plan.description.tier.elite":
    "Elite organization tiers include 45-minute urgent SLA.",
  "billing.plan.description.tier.professional":
    "To add a support SLA, upgrade your organization tier to Enterprise or Elite.",
  "billing.plan.link.tier.professional": "Learn more",
  "billing.plan.link.tier.elite": "Upgrade organization tier",
  "billing.plan.support": "{type} support",
  resend: "Resend",
  no_edit_or_view: "You cannot edit or view the {editType} for another user.",
  "settings.plan.project.delete.button": "Delete project",
  "settings.plan.project.delete.confirm":
    "Are you sure you want to delete your project <strong>{projectTitle}</strong>?{br} Please review the details of your project before deleting and type your project name to confirm this action.",
  "settings.plan.project.modal.title": "Delete project",
  "settings.plan.banner.trial":
    "To upgrade your plan you need to add a payment method to your billing details.",
  "settings.plan.banner.button": "Add billing details",
  "projects.hide_filter": "Hide filters",
  "projects.show_filter": "Show filters",
  "project.plan.type": "Plan",
  "project.plan.trial.info":
    "Free trials are limited to development only plans, add your billing details to change your plan.",
  "project.plan.backups.title": "Backups",
  "project.plan.backups.info":
    "Backups create a complete snapshot of the environment and can be triggered through the console or the CLI. Automated backups are run between 3am - 5am based on your project’s timezone.",
  "project.plan.backups.estimation":
    "{numberAutomated} automated + {numberManual} manual included",
  "project.plan.backups.no-backup-no-default":
    "Upgrade to an automated backup plan for your production environment.",
  "project.plan.backups.tooltip.text":
    "Contact our sales team to get access to the Premium backup plans. Those plans are available to Elite and Enterprise organizations.",
  "project.plan.backups.tooltip.text.no-backup-no-default":
    "Default automated backup plans will be applied November 30.",
  "project.plan.backups.tooltip.url.text": "Learn more",
  "project.plan.note.description":
    "*Note that you can only upgrade storage and backups at this time. Request a downgrade by submitting a",
  "project.plan.note.text": "support ticket",
  "project.plan.overage_note":
    "**Overages for Origin and CDN usage (bandwidth and requests) are are invoiced separately. Contact your account manager for quota and prepaid details.",
  "project.plan.tier.label": "{tier} Tier",
  "project.access.owner.tooltip":
    "An organization owner can't be removed from a project",
  "project.access.view": "View user",
  "project.access.leave": "Leave project",
  "project.access.leave.confirm":
    "Are you sure you want to remove <strong>{userName}</strong> from the project <strong>{projectTitle}</strong>?",
  "project.access.leave.success.title":
    "{userName} removed from {projectTitle}",
  "project.access.leave.success.description":
    "You successfully removed <strong>{userName}</strong> from the project <strong>{projectTitle}</strong>.",
  "project.access.yourself": "yourself",
  "backups.panel.header": "Backups cost breakdown",
  "backups.panel.info":
    "{estimation} per GB of storage on your production environment. Development environments remain on the Default plan.",
  "backups.storage": "Storage on Master (in GB)",
  "backups.cost.perGB": "Cost per GB (in {currency})",
  "backups.type.manual": "Manual Backups",
  "backups.type.automated": "Automated Backups",
  "backups.storage.duration": "Backup storage duration",
  "backups.frequency": "Backup frequency",
  "backups.six.hourly.duration": "4 daily backups stored for 1 day",
  "backups.daily.duration": "Daily backups stored for 1 week",
  "backups.weekly.duration": "Weekly backups stored for 1 month",
  "backups.monthly.duration": "Monthly backups stored for 1 year",
  "backups.enterprise.only": "Enterprise only",
  "backups.dropdown.Basic.plan":
    "Automated backups are stored for 2 days. You can store up to 2 manual backups at any time.",
  "backups.dropdown.Advanced.plan":
    "21 automated and 4 manual backups. {br}Each daily backup is stored for 1 week. {br}Each weekly backup is stored for 1 month. {br}Each monthly backup is stored for 1 year.",
  "backups.dropdown.Premium.plan":
    "44 automated backups and 4 manual backups. {br}Each 6-hourly backup is stored for 1 day. {br}Each daily backup is stored for 1 month. {br}Each monthly backup is stored for 1 year.",
  "backups.dropdown.Advanced.label": "Advanced",
  "backups.dropdown.Premium.label": "Premium",
  "backups.restore.modal.title": "Restore from backup",
  "backups.restore.modal.action": "Yes, restore",
  "backups.restore.modal1":
    "Are you sure you want to restore the backup {br}<strong>{id}</strong> created <strong>{created_at}</strong> with commit ID <strong>{commit_id}</strong>?",
  "backups.restore.modal2":
    "This will delete and then overwrite all data from all running services and files of the {environment} environment.",
  "backups.restore.modal3": "Note: this action can not be undone.",
  "backups.delete.modal.title.automated": "Delete automated backup",
  "backups.delete.modal.title.manual": "Delete manual backup",
  "backups.delete.modal.description.automated":
    "Are you sure you want to delete the automated backup <strong>{id}</strong> with expiration of <strong>{date}</strong>?",
  "backups.delete.modal.description.manual":
    "Are you sure you want to delete the manual backup <strong>{id}</strong>",
  "backups.delete.modal.note.automated":
    "Note: This action can not be undone and could affect your backup retention schedule. <a>{learnMore}</a>",
  "backups.delete.modal.note.manual": "Note: This action can not be undone.",
  "backups.delete.modal.learn": "Learn more",
  "backups.modal.reached.max.note":
    "Note: This backup will replace your oldest manual backup.",
  "default.included": "Default included",
  "project.plan.planType": "Plan type",
  "project.plan.trial.info.link": "Add billing details",
  "project.plan.includes.title": "Includes",
  "project.plan.includes.user": "<b>{value}</b> User",
  "project.plan.includes.envs":
    "<b>{value}</b> Development {value, plural, one {environment} other {environments}}",
  "project.plan.includes.storage": "<b>{value}</b> GB storage per environment",
  "project.plan.includes.branch": "Dev site (future production site)",
  "project.plan.includes.additional_service":
    "<b>{value}</b> additional service (MySQL...)",
  "project.plan.multi_app_multi_data_apps": "multi-apps or multi-database apps",
  "project.plan.multiple_backends": "multiple backends",
  "project.plan.complex_micro_service_applications":
    "complex micro-services applications",
  "project.plan.memory_intensive_apps": "memory intensive applications",
  "project.plan.includes.production_site": "Production site",
  "project.plan.includes.custom_domain": "Custom domain",
  "project.plan.includes.custom_domains": "Custom domains",
  "project.plan.includes.ssl": "SSL support",
  "project.plan.environment.title": "Environments",
  "project.plan.environment.estimation": "{estimation} / 3 environments",
  "project.plan.specs":
    "{cpu, plural, one {# CPU} other {# CPUs}} & {memory} GB Memory",
  "project.plan.storage.title": "Storage",
  "project.plan.storage.estimation": "{estimation} / 5 GB per environment",
  "project.plan.support.supportSLA": "Support SLA",
  "project.plan.traffic.low_med_traffic_websites":
    "low-medium traffic websites",
  "project.plan.traffic.med_higher_traffic_websites":
    "medium-higher traffic websites",
  "project.plan.traffic.higher_traffic_websites": "higher-traffic websites",
  "project.plan.users.estimation":
    "{estimation} / per user per month. Billed when added to your platform",
  "project.plan.highmem_disclaimer":
    "<b>Note:</b> Resources in high-memory plans are not always allocated by default and should only be used in particular instances. Learn more about them and how to configure them in our <link_docs>flexible resources docs</link_docs> or open a <link_tix>support ticket</link_tix> if you need assistance.",
  "project.plan.upgrade": "Upgrade plan",
  "project.plan.users.title": "Users",
  "trial-remaining.plan.name": "Free trial",
  "trial-remaining.credit.label": "Free trial credit",
  "trial-remaining.credit.value": "{credit} of free trial credit remaining",
  "trial-remaining.expiration.label": "Expires",
  "trial-remaining.button": "Upgrade now",
  "trial.modal.welcome.title": "Free trial starting",
  "trial.modal.welcome.text":
    "Creating your first project starts your free trial, where you can spend up to {amount} in the next {duration, plural, one {day} other {{duration} days}}. At the end of your trial you'll be prompted to add your billing details to keep your projects running.",
  "trial.modal.welcome.voucher":
    "<b>Have a voucher?</b> <link>Redeem now</link> to get even more benefits.",
  "invoice.monthly.total": "Total monthly payment",
  "invoice.trial.credit": "Free trial credit",
  "invoice.trial.expires": "Expires: {date}",
  "invoice.trial.remaining": "Free trial credit remaining",
  "invoice.trial.without_project":
    "<a>Create a project</a> to get the most out of your free trial before it expires.",
  "invoice.trial.with_project":
    "Add a payment method to keep your projects running after your free trial ends.",
  "invoice.included_project_title": "Projects Included",
  "invoice.enterprise_message":
    "Please note that discounts and contract details are not yet reflected in these invoices. You can review this directly with your account manager.",
  "environment.backups.empty.title": "No backups",
  "environment.backups.btn.manage_schedule": "Manage schedule",
  "environment.backups.tooltip.custom_backup_scheduling.description":
    "Backups are charged based on the total storage used. There is no limit to the number of backups or backup schedules that you can have.",
  "environment.backups.empty.details":
    "You have no recent or running backups on this environment.",
  "environment.backups.title": "Backups",
  "environment.backups.info.prod":
    "This environment has a maximum <strong>{automatedAmount} automated</strong> and <strong>{manualAmount} manual</strong> backups. Additional backups made will replace the oldest manual backup.",
  "environment.backups.info.dev":
    "This environment has a maximum <strong>{amount} manual </strong> backups. Additional backups made will replace the oldest backup.",
  "environment.backups.modal.explanation":
    "Create a complete backup of this environment. Your backup will include all persistent data from all running services and any files stored on mounted volumes.",
  "environment.backups.modal.note":
    "Note: This backup will replace your oldest stored backup.",
  "environment.backups.modal.custom_backup_schedule.note":
    "Note: Creating a backup will impact your monthly project cost. <link>View pricing page</link>",
  "environment.backups.modal.info_dialog.title": "Live Backups",
  "environment.backups.modal.info_dialog.text":
    "Manual live backups are run without any downtime to your environment, but may result in data inconsistencies. Alternatively, consistent backups run after pausing all requests which can result in downtime.",
  "environment.backups.modal.lastbackup.text": "Last backup:",
  "environment.backups.modal.lastbackup.progress": "In progress...",
  "environment.backups.modal.checkbox": "Create live backup",
  "environment.backups.backupSize": "({backupSize} GB)",
  "environment.routes.viewall": "View all routes",
  environment: "environment",
  "project.settings.basic.name": "Project name",
  "project.settings.basic.form.name": "Project name",
  "project.settings.basic.form.region": "Region",
  "project.settings.basic.form.timezone.label": "Timezone",
  "project.settings.basic.form.timezone.placeholder": "Select timezone",
  "project.settings.basic.form.timezone.info":
    "The project timezone is used to schedule backups. You can also use it to implement maintenance windows.",
  "environment.services.configuration.routes":
    "Routes describe how an incoming HTTP request is going to be handled by {appName}.<br></br>They are defined using the <code>{appRouteFilePath}</code> file in your Git repository and can be configured through code. To make changes to your route you can edit this file, commit and push it to the git remote.",
  "environment.services.configuration.routes_doc":
    "Visit our <a>documentation</a> on Routes to find examples and settings for HTTP Cache, Redirects, and Server Side Includes.",
  "invitation.something_went_wrong":
    "Something went wrong, please try again later",
  "tickets.documenttitle": "Support",
  "settings.documenttitle": "Account",
  "billing.documenttitle": "Billing",
  "environment.branch.error.title": "Environment limit reached",
  "environment.branch.error.admin.description":
    "To add more environments upgrade your plan as you have used all of your allocated environments.",
  "environment.branch.error.standard.description":
    "To add more environments upgrade your plan as you have used all of your allocated environments.  Contact your project owner to upgrade your plan settings.",
  edit: "Edit",
  "integration.add": "Add integration",
  "integration.configure.title": "Edit your integration",
  "integration.configure.intro": " ",
  "integration.configure.field.project.label": "Project",
  "integration.configure.field.project.placeholder": "Project name",
  "integration.configure.field.repository.label": "Repository",
  "integration.configure.field.repository.placeholder": "user/repo",
  "integration.configure.field.repository.git.label": "Repositories",
  "integration.configure.field.repository.git.placeholder": "Choose repository",
  "integration.configure.field.repository.git.disclaimer":
    "Repositories can only be changed by removing the existing integration and creating a new one.",
  "integration.configure.field.token.label": "Access Token",
  "integration.configure.field.token.placeholder": "Enter token",
  "integration.configure.field.base_url.label": "Custom domain",
  "integration.configure.field.base_url.placeholder":
    "Enter only if using a custom domain",
  "integration.configure.field.url.label": "URL",
  "integration.configure.field.url.placeholder": "Enter URL",
  "integration.configure.field.script.label": "Javascript code",
  "integration.configure.field.script.placeholder":
    "Enter your javascript code",
  "integration.configure.field.username.label": "Username",
  "integration.configure.field.username.placeholder": "Enter username",
  "integration.configure.field.routing_key.label": "Routing key",
  "integration.configure.field.routing_key.placeholder": "Enter routing key",
  "integration.configure.field.channel.label": "Channel",
  "integration.configure.field.channel.placeholder": "Enter channel name",
  "integration.configure.field.recipients.label": "Recipients",
  "integration.configure.field.recipients.placeholder":
    "Enter email addresses separated by commas.",
  "integration.configure.field.from_address.label": "From address",
  "integration.configure.field.from_address.placeholder": "Enter address email",
  "integration.configure.field.shared_key.label": "Shared key",
  "integration.configure.field.shared_key.placeholder": "Enter shared key",
  "integration.configure.field.app_credentials/key.label": "App credential key",
  "integration.configure.field.app_credentials/key.placeholder":
    "Enter your credential key",
  "integration.configure.field.app_credentials/secret.label":
    "App credential secret",
  "integration.configure.field.app_credentials/secret.placeholder":
    "Enter your credential secret",
  "integration.configure.field.excluded_environments.label":
    "Excluded environments to not execute the hook on",
  "integration.configure.field.excluded_environments.placeholder":
    "List environment IDs to exclude.",
  "integration.configure.field.excluded_environments.info.title":
    "Excluded environments",
  "integration.configure.field.excluded_environments.info.text":
    "You can separate these with commas to list multiple types.",
  "integration.configure.field.states.label": "States to report",
  "integration.configure.field.states.placeholder":
    "List your states to report, e.g. pending, in_progress, complete - Defaults to “complete”..",
  "integration.configure.field.states.info.title": "States to report",
  "integration.configure.field.states.info.text":
    "You can separate these with commas to list multiple types.",
  "integration.configure.field.environments.label": "Included environments",
  "integration.configure.field.environments.placeholder":
    "List environment IDs to include, defaults to “*”",
  "integration.configure.field.environments.info.title":
    "Included environments",
  "integration.configure.field.environments.info.text":
    "You can separate these with commas to list multiple types.",
  "integration.configure.field.events.label": "Events to report",
  "integration.configure.field.events.placeholder":
    "List your events to report, e.g. environment.push - Defaults to “*”",
  "integration.configure.field.events.info.title": "Events to report",
  "integration.configure.field.events.info.text":
    "You can separate these with commas to list multiple types.",
  "integration.configure.field.info.authenticated_as":
    "Authenticated as <b>{name}</b>",
  "integration.configure.field.info.change_token": "Change Token",
  "integration.configure.option.fetch_branches":
    "Fetch branches from the remote repository to your project.",
  "integration.configure.option.prune_branches":
    "Remove branches from your project that have disappeared remotely (requires the fetch branches option to be enabled).",
  "integration.configure.option.build_draft_pull_requests":
    "Create new branches and run the build for each draft pull request.",
  "integration.configure.option.build_pull_requests_post_merge":
    "Build the result of merging the pull request.",
  "integration.configure.option.pull_requests_clone_parent_data":
    "Clone parent environment’s data when creating a pull request environment.",
  "integration.configure.option.resync_pull_requests":
    "Re-sync environment data on every pull request build.",
  "integration.configure.option.build_pull_requests":
    "Create new branches and run the build for each pull request.",
  "integration.configure.option.build_merge_requests":
    "Create new branches and run the build for each merge request.",
  "integration.configure.option.build_wip_merge_requests":
    "Create new branches and run the build for each WIP pull request.",
  "integration.configure.option.merge_requests_clone_parent_data":
    "Clone parent environment’s data when creating a merge request environment.",
  "integration.configure.github.intro":
    "The GitHub integration allows you to manage your {appName} environments directly from your GitHub repository.",
  "integration.configure.github.sub_intro":
    "<a>Generate a new token</a> in GitHub. If you have an enterprise account go to: Settings page / Developer settings / Personal access tokens",
  "integration.configure.github.scopes":
    "<code>admin:repo_hook</code> to automatically create web hooks {br}<code>repo</code> to integrate with your repositories{br}Add <code>read:org</code> to integrate with your organization´s repositories",
  "integration.configure.github.field.base_url.placeholder":
    "Enter only if using a custom domain",
  "integration.configure.gitlab.intro":
    "The GitLab integration allows you to manage your {appName} environments directly from your GitLab repository.",
  "integration.configure.gitlab.sub_intro":
    "<a>Generate a new token</a> in GitLab. If you have an enterprise account go to: Settings page / Developer settings / Personal access tokens.",
  "integration.configure.gitlab.scopes":
    "<code>api</code> to access your API{br}<code>read_repository</code> to read the repository",
  "integration.configure.bitbucket.intro":
    "You can get the information below from an Oauth consumer in your Bitbucket account.",
  "integration.configure.bitbucket_server.intro":
    "You can get the information below from an Oauth consumer in your Bitbucket account.",
  "integration.configure.webhook.intro":
    "Webhooks allow you to set up integrations, which subscribe to certain activities on in {appName} projects, such as creating or merging branches, synchronizing data with parent environments, or activating new environments.",
  "integration.configure.health_webhook.intro":
    "Webhooks allow you to set up integrations, which subscribe to certain activities on in {appName} projects, such as creating or merging branches, synchronizing data with parent environments, or activating new environments.",
  "integration.configure.health_pagerduty.intro":
    "Create a new <a>PagerDuty integration</a> using the Events API v2, then copy the routing key for the integration and enter that below.",
  "integration.configure.health_slack.intro":
    "With a <a>custom bot user</a> for your Slack group, the API token is the Bot User OAuth Access Token provided by Slack.",
  "integration.configure.health_email.intro":
    "A notification will trigger an email to be sent from an address of your choice to one or more recipient addresses.",
  "integration.configure.script.intro":
    "Activity Scripts respond to activities in {appName} projects, such as creating or merging branches, synchronizing data with parent environments, or activating new environments. You can write code that is triggered by these activities and takes action in response. {br} Activity Scripts are powered by a lightweight JavaScript engine, and has runtime access to objects representing your project and environment. Find out more in our <a>documentation</a>.",
  "integration.configure.ensure_scopes":
    "<b>Ensure the token has the following scopes:</b>",
  integrations: "Integrations",
  "integration.setup.categories": "Categories",
  "integration.setup.categories.all": "All categories",
  "integration.setup.categories.search.placeholder": "Search integration",
  "integration.setup.categories.filter": "Filter",
  "integration.setup.categories.label": "Filter categories",
  "integration.setup.categories.git.title": "Git service",
  "integration.setup.categories.monitoring.title": "Monitoring",
  "integration.setup.categories.monitoring.sentence": " ",
  "integration.setup.categories.observability_suite.sentence":
    "Available with the observability suite. <a>Learn more</a>",
  "integration.setup.categories.usage_billed.sentence":
    "Usage costs based on outgoing bandwidth. <a>Learn more</a>",
  "integration.setup.categories.git.sentence":
    "You can only have one git service connected at any time",
  "integration.setup.categories.git.info.title": "Git Service limitation",
  "integration.setup.categories.git.info.text":
    "Only one Git service can be integrated at any one time.",
  "integration.setup.categories.health.title": "Health notifications",
  "integration.setup.categories.health.sentence": " ",
  "integration.setup.categories.custom.title": "Custom",
  "integration.setup.categories.custom.sentence": " ",
  "integration.setup.categories.observability_suite.title": "Log forwarding",
  "integration.setup.title": "Add Integration",
  "integration.setup.add": "+ Add",
  "integration.setup.connected": "Connected",
  "integration.setup.select": "Select integration",
  "integration.setup.configure": "Configure your integration",
  "integration.setup.modal.git.title": "Replace your Git service",
  "integration.setup.modal.git.sentence":
    "Are you sure you want to replace your existing <b>{current}</b> integration with <b>{new}</b>? Your {current} integration will be removed and the project’s repository will no longer be in sync with your {current} repository. ",
  "integration.type.bitbucket": "Bitbucket",
  "integration.type.httplog": "HTTP log",
  "integration.type.sumologic": "Sumo Logic",
  "integration.type.newrelic": "NewRelic",
  "integration.type.bitbucket.description":
    "Integrate with public or private Bitbucket repositories.",
  "integration.type.bitbucket_server": "Bitbucket server",
  "integration.type.bitbucket_server.description":
    "Integrate with a self-hosted version of Bitbucket.",
  "integration.type.github": "GitHub",
  "integration.type.github.description":
    "Integrate with public or private GitHub repositories.",
  "integration.type.gitlab": "GitLab",
  "integration.type.gitlab.description":
    "Integrate with public or private GitLab repositories.",
  "integration.type.hipchat": "HipChat",
  "integration.type.hipchat.description": "FIXME",
  "integration.type.webhook": "Custom webhook",
  "integration.type.webhook.description":
    "Send information or messages to another app, service or web endpoint.",
  "integration.type.health.email": "Email",
  "integration.type.health.email.description":
    "Get health notifications for your project delivered by email.",
  "integration.type.health.pagerduty": "PagerDuty",
  "integration.type.health.pagerduty.description":
    "Get health notifications for your project delivered to your PagerDuty service.",
  "integration.type.health.slack": "Slack",
  "integration.type.health.slack.description":
    "Get health notifications for your project delivered to a Slack channel of your choice.",
  "integration.type.health.webhook": "Health webhook",
  "integration.type.health.webhook.description":
    "Send information or messages to another app, service or web endpoint.",
  "integration.type.script": "Activity Scripts",
  "integration.type.blackfire": "Blackfire",
  "integration.type.syslog": "Syslog endpoint",
  "integration.type.splunk": "Splunk",
  "integration.type.script.description":
    "Write code triggered by various activities to extend an integration or build your own.",
  "integration.remove.title": "Remove integration",
  "integration.remove.sentence":
    "Are you sure you want to remove the <b>{integration}</b> integration from project <b>{project}</b>? ",
  "log_forwarding.observability_required_message":
    "Log forwarding integrations require the Observability Suite for access.",
  "integration.getting_started.observability_suite_description":
    "These integrations require an upgrade to access. The Observability Suite includes access to them all.",
  "integration.getting_started.observability_suite_title":
    "Observability integrations",
  "manage.account": "Manage your account",
  "menu.projects": "Projects",
  "menu.settings": "Settings",
  "menu.logout": "Logout",
  "menu.account": "Account",
  "menu.billing": "Billing",
  "menu.support": "Support",
  "menu.users": "Users",
  "menu.teams": "Teams",
  "menu.my_profile": "My Profile",
  "menu.documentation": "Documentation",
  "menu.select_organization": "Select Organization",
  "menu.organization": "Organization",
  "menu.all_organization_project": "All organizations projects",
  "help.discord": "Discord community",
  "help.submit_idea": "Submit an idea",
  "access.title": "Access",
  "access.help.text.a": "Learn more about Project and User permissions in our",
  "access.help.text.b": "documentation",
  "access.alert":
    "For the changes to apply to SSH access, you need to redeploy each environment.",
  "access.apply_all": "Apply to all",
  "access.project_admin": "Project admin",
  "access.options.no_access": "No access",
  "access.options.select_permission": "Select role",
  "access.options.admin": "Admin",
  "access.options.viewer": "Viewer",
  "access.options.contributor": "Contributor",
  "access.options.development": "Development",
  "access.options.staging": "Staging",
  "access.options.production": "Production",
  "access.options.no-access": "No access",
  "access.environment.description":
    " Add a new user to your environment and assign their permission level.",
  "access.environment.show.more": "Show all +{numbers}",
  "access.environment.show.less": "Show less",
  "access.environment.permissions": "Environment permissions",
  "access.environment_types.permissions": "Environment type roles",
  "access.environment_types.description":
    "Roles are set per environment type. e.g. when you set the role of a user as viewer on a development environment type, they will inherit that role on all other development environment types.",
  "access.environment.permissions.env": "Environment",
  "access.environment.permissions.env_types": "Environment types",
  "access.environment.permissions.type": "Permission type",
  "access.removeUserModalTitle": "Are you sure you want to remove this user?",
  "access.removeUserModalBody": "Yes, remove user access to this project for",
  invitation_pending: "Invitation pending",
  invitation_error: "Invitation error",
  "environment.access.environment_types":
    "Permission changes to this environment will apply to all other environments of this type.",
  "project.access.environment_types":
    "Permission changes to environments will apply to all other environments of the same type.",
  "access.project.description":
    "Add a new user to your project and assign their permission levels for each environment.",
  "access.project.permissions": "Project roles",
  "project.accesslist.add_user": "Add user",
  "project.accesslist.add_team": "Add team",
  "project.access.remove_user": "Remove user",
  "project.accesslist.add_user_to_project": "Add user to project",
  "project.accesslist.addbyadmin": "Ask your project admin to add a user",
  "project.accesslist.description":
    "Configure your users access to all or specific environments of this project.",
  "project.variable.access":
    "To add or edit variables you need project admin access on this project",
  "environment.variable.access":
    "To add or edit variables you need project admin access on this environment",
  "access.already_exists":
    "<b>{user}</b> has already been added to this <b>{project}</b>",
  "project.accesslist.already_exists":
    "This user has already been added to this project. You can edit this user's access below.",
  "environment.accesslist.add_user": "Add user",
  "environment.accesslist.description":
    "Configure your users access to this environment and/or its child environments.",
  "invitation.role.admin": "Project admin",
  "invitation.role.viewer": "viewer",
  "invitation.role.contributor": "contributor",
  "invitation.environment": "Environment",
  "invitation.environment_types": "Environment types",
  "invitation.environment.permission": "Environment permissions",
  "invitation.environment_types.permission": "Environment type roles",
  "invitation.environment.type": "Roles",
  "system_status.title": "System status",
  "system_status.anchor": "View status",
  "system_status.link": "Visit status page",
  "system_status.activities": "Current activity",
  "services.workers.title": "Workers <b>({count})</b>",
  "services.workers.none": "You do not currently have any workers to display.",
  "services.crons.title": "Crons <b>({count})</b>",
  "services.crons.none": "You do not currently have any crons to display.",
  "services.metrics.error": "Metrics collection was not able to load",
  "services.metrics.title": "Metrics",
  "services.metrics.cpu": "CPU",
  "services.metrics.ram": "RAM",
  "services.metrics.disk": "Disk",
  "services.metrics.gb": "GB",
  "services.metrics.worker": "Worker",
  "breadcrumb.billing": "Billing",
  "breadcrumb.billing.plan": "Plans",
  "breadcrumb.billing.plan.id": "Subscription #{id}",
  "breadcrumb.billing.history": "Invoices",
  "breadcrumb.billing.history.id": "Invoice #{id}",
  "breadcrumb.billing.vouchers": "Vouchers",
  "breadcrumb.billing.vouchers.id": "Vouchers",
  "breadcrumb.organization.billing": "Billing",
  "breadcrumb.organization.billing.plan": "Plans",
  "breadcrumb.organization.billing.plan.id": "Subscription #{id}",
  "breadcrumb.organization.billing.history": "Invoices",
  "breadcrumb.organization.billing.history.id": "Invoice #{id}",
  "breadcrumb.organization.billing.vouchers": "Vouchers",
  "breadcrumb.organization.billing.vouchers.add": "Redeem",
  "breadcrumb.organization.settings": "Organization settings",
  "breadcrumb.organization.settings.users": "Organization users settings",
  "breadcrumb.organization.settings.teams": "Teams",
  "breadcrumb.organization.settings.users.invitations":
    "Organization invitations settings",
  "breadcrumb.organization.usage.billing": "Billing",
  "breadcrumb.organization.project.usage.billing": "Project",
  "breadcrumb.settings.users.id": "{id}",
  "breadcrumb.project": "Project",
  "breadcrumb.projects": "Projects",
  "breadcrumb.settings": "Account settings",
  "breadcrumb.profile": "My profile",
  "breadcrumb.settings.accounts": "Connected accounts",
  "breadcrumb.settings.security": "Security",
  "breadcrumb.settings.security.setup": "MFA application set up",
  "breadcrumb.settings.tokens": "API tokens",
  "breadcrumb.settings.ssh-keys": "SSH Keys",
  "breadcrumb.tickets": "Support",
  "breadcrumb.tickets.open": "Create ticket",
  "breadcrumb.tickets.id": "Ticket #{id}",
  "project.morelinks.id": "ID {id}",
  "project.morelinks.edit": "Edit plan",
  "project.morelinks.wizard": "Setup wizard",
  "project.morelinks.delete": "Delete",
  "project.morelinks.view": "View project",
  "project.morelinks.configure_resources": "Configure resources",
  "project.morelinks.delete_modal.title": "Delete project",
  "project.morelinks.delete_modal.sentence":
    "Are you sure you want to delete your project <b>{sub}</b>? {br} {br} Please note that deleting your project is irreversible and all data associated with this project will be deleted, including backups. Lastly, at the end of the month you will be charged for any remaining project costs.",
  "metrics.dedicated.heading": "Infrastructure metrics",
  "metrics.grid.heading": "Metrics",
  "metrics.split.heading": "Infrastructure metrics",
  "metrics.split.web": "Web hosts",
  "metrics.split.unified": "Service hosts",
  "metrics.currentavg": "Current avg.",
  "metrics.currentaverage": "Current average",
  "metrics.ram.title": "RAM",
  "metrics.ram.dialog.title": "RAM usage",
  "metrics.ram.dialog.text":
    "The RAM (Memory) used on apps and system processes.",
  "metrics.cpu.title": "CPU",
  "metrics.cpu.dialog.title": "CPU usage",
  "metrics.cpu.dialog.text":
    "The amount of CPU used on user and system processes.",
  "metrics.disk.title": "Disk",
  "metrics.disk.dialog.title": "Disk {mountpoint}",
  "metrics.disk.dialog.text.generic":
    "Average and host disk utilization on the {mountpoint} folder.",
  "metrics.disk.dialog.text.root":
    "Average and host disk utilization on the {mountpoint} folder - Infrastructure disk, including /tmp.",
  "metrics.disk.dialog.text.exports":
    "Average and host disk utilization on the {mountpoint} folder for all other services.",
  "metrics.timeframe.info.seconds":
    "data points every {time, plural, one {# second} other {# seconds}}",
  seconds_ago: "{time, plural, one {# second} other {# seconds}} ago",
  minutes_ago: "{time, plural, one {# minute} other {# minutes}} ago",
  "metrics.timeframe.info.minutes":
    "data points every {time, plural, one {# min} other {# mins}}",
  "metrics.timeframe.info.minute":
    "data points every {time, plural, one {# min} other {# mins}}",
  "metrics.info.zoom": "Maximum zoom has been reached",
  "metrics.report.support.title": "Contact support",
  "metrics.report.support.text": "Having trouble? ",
  "wizard.finish": "Finish",
  "wizard.steps.dropdown": "{current} of {total}",
  "wizard.list.steps.start": "What to expect",
  "wizard.list.steps.finish": "Setup steps",
  "wizard.list.steps.optional": "(Opt)",
  "wizard.intro.title":
    "Congrats! Your new project {name} has just been created.",
  "wizard.intro.sentence":
    "To get your project set up and running, step through our wizard or jump to the steps you need.",
  "wizard.intro.start": "Start",
  "wizard.outro.title": "Awesome sauce!{br}{name}'s now initialized on {app}",
  "wizard.outro.title.first_line": "Awesome sauce!",
  "wizard.outro.title.second_line": "{name}'s",
  "wizard.outro.title.third_line": "now initialized on {app}",
  "wizard.outro.sentence":
    "You’re good to go! Check out our documentation for common next steps like adding a service and adding users to your project. When you’re ready to go live{plan, select, development { you'll need to upgrade to a production plan} other {, <a>add a domain</a>}}.",
  "wizard.outro.view_doc": "View documentation",
  "wizard.ssh.saved": "SSH Key has been added",
  "wizard.project.provisioning.continue": "Continue Later",
  "wizard.project.provisioning.setting.project": "Setting up your project",
  "wizard.project.provisioning.enter.credentials":
    "Enter authentication credentials",
  "wizard.project.provisioning.adobe.credentials":
    "Adobe Commerce Cloud requires that you have proper credentials to download the base package. Please get your credentials at <link>magento.com</link> and paste them here. Those will automatically be added to your <code>auth.json</code> file in your Git repository. ",
  "wizard.project.provisioning.username": "Username (public repo key)",
  "wizard.project.provisioning.password": "Password (private repo key)",
  "wizard.project.provisioning.call.project":
    "What do you want to call your project ?",
  "wizard.project.provisioning.start": "Where do you want to start ?",
  "wizard.project.provisioning.blank": "Create a blank site from a template",
  "wizard.project.provisioning.existing.code": "Import your existing code",
  "wizard.project.provisioning.importing": "Importing your code.",
  "wizard.project.provisioning.repository": "Your Git repository is",
  "wizard.project.provisioning.further":
    "Before we go further, you should push some code to your Git repository.",
  "wizard.project.provisioning.remote": "git remote add magento-cloud {url}",
  "wizard.project.provisioning.push": "git push -u magento-cloud master",
  "wizard.project.provisioning.template":
    "We can automatically generate and setup for you a website based on some preconfigured settings and templates.",
  "wizard.project.provisioning.supports":
    "Magento Cloud supports PHP, NodeJS, Ruby (beta) and Python (beta) applications. To deploy an existing application you need to upload your code, database and files, and add some configuration files which define your infrastructure. Please check our detailed documentation at <link>https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/overview.html</link> to get started.",
  "wizard.project.provisioning.link.support":
    "https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/overview.html",
  "wizard.project.provisioning.link.support.url":
    "https://www.magentocommerce.com/magento-connect/customerdata/accessKeys/list/",
  "wizard.project.provisioning.push.repository":
    "Push an <strong>existing repository</strong> in command line",
  "wizard.project.provisioning.services":
    "Remember to add a <em>.magento.app.yaml</em> to configure your app, <em>.magento/services.yaml</em> to set up services (databases, caches, search engines ...), and <em>.magento/routes.yaml</em> to define how an incoming URL is processed.",
  "wizard.project.provisioning.moreinfo":
    "More information can be found in our",
  "wizard.project.provisioning.moreinfo.url":
    "https://experienceleague.adobe.com/docs/commerce.html",
  "wizard.step.optional": "(Optional)",
  "project.card.tag.no-access": "No access",
  my_organizations: "My organizations",
  "services.noconfiguration":
    "Console does not currently output the content of <code>{appConfigFile}</code> files if they are stored in subdirectories of the repository. To see your complete app configuration in Console, move the content of your <code>{appConfigFile}</code> files to <code>{appApplicationFilePath}</code>.",
  "no-access": "No access",
  label: "Label",
  add_users: "Add users",
  "organizations.settings.add_users_text":
    "Invite new or existing {appName} users to your organization.",
  view_billing_details: "View billing details",
  view_billing: "View billing",
  "organizations.settings.view_billing_details_text":
    "Manage your organizations billing details.",
  "organizations.settings.invoices_text":
    "View your organizations latest invoices.",
  "organizations.settings.delete_modal.title": "Delete organization",
  "organizations.settings.delete_modal.sentence":
    "You are about to delete the organization <b>{sub}</b>. If this organization owns a project, or has unpaid invoices, you need to delete the projects and pay the remaining invoices before doing so. This process is not reversible.",
  "organizations.settings.delete": "Delete",
  view_invoices: "View invoices",
  delete_organization: "Delete organization",
  organization_url: "Organization URL",
  "organization_label.edit": "Edit",
  required_field: "This field is required",
  "field.is_required": "{label} is required.",
  "footer.message": "Version {appVersion} {footerText} Platform.sh",
  security_contact: "Security contact",
  "security_contact.placeholder": "Email Address",
  "metrics.grid.view": "View graphs",
  "metrics.grid.hide": "Hide graphs",
  "metrics.dedication.cpu.title": "CPU",
  "metrics.dedication.memory.title": "RAM",
  "metrics.dedication./mnt.title": "/mnt",
  "metrics.dedication./tmp.title": "/tmp",
  "metrics.timepast": "Past {duration} {durationText}",
  "metrics.thresholds.critical": "critical",
  "metrics.thresholds.warning": "warning",
  "metrics.thresholds.burst": "burst",
  "metrics.error.empty.header": "Your metrics are almost here!",
  "metrics.error.empty.paragraph":
    "Metrics collection will start once you redeploy your environment.",
  "metrics.grid./tmp": "Temporary Disk",
  "metrics.grid./tmp.tooltip.title": "Shared /tmp disk",
  "metrics.grid./tmp.tooltip.description":
    "Some detailed information about this feature.",
  "metrics.grid./mnt": "Persistent Disk",
  "metrics.dedication./tmp.tooltip":
    "The temporary (non-persistent) mount allocated to the {serviceKind} container across all hosts.",
  "metrics.dedication./mnt.tooltip":
    "The persistent writable mount allocated to the {serviceKind} container across all hosts.",
  "metrics.dedication.memory.tooltip":
    "The amount of RAM (Memory) allocated to the {serviceKind} container across all hosts.",
  "metrics.dedication.cpu.tooltip":
    "The amount of CPU allocated to the {serviceKind} container across all hosts.",
  "metrics.grid./tmp.tooltip":
    "The temporary (non-persistent) mount allocated to the {serviceKind} container.",
  "metrics.grid./mnt.tooltip":
    "The persistent writable mount allocated to the {serviceKind} container.",
  "metrics.grid.memory.tooltip":
    "The amount of RAM (Memory) allocated to the {serviceKind} container.",
  "metrics.service.emtpy":
    "There is no metrics data for {serviceKind}/{serviceName}",
  "metrics.grid.cpu.tooltip":
    "The amount of CPU allocated to the {serviceKind} container.",
  "metrics.service.share.copy": "Click to copy share link",
  "metrics.service.share.copied": "Copied",
  "metrics.error.generic.header": "Something went wrong...",
  "metrics.error.generic.paragraph":
    "Metrics page is temporarily unavailable, please try again in a few minutes.",
  "metrics.error.generic.link":
    "If the issue persists, <a>open a support ticket</a>",
  add_user_organization: "Invite one or more users to your organization.",
  email_address: "Email address",
  manage_users: "Manage users",
  invite_users: "Invite users",
  "access.admin": "Admin",
  "access.projects:create": "Create projects",
  "access.projects:list": "List projects",
  "access.members": "Manage users",
  "access.billing": "Manage billing",
  "access.plans": "Manage plans",
  "plan.manage": "Manage Plan",
  "access.all": "All permissions",
  members_email_address: "Enter email address, separate multiple with a comma",
  "organizations.empty_invitations": "You have no pending invitations",
  "organizations.empty_users": "No users",
  "organizations.add_modal.info":
    "To learn more about organization permissions read our {documentationLink}",
  "organizations.permissions": "Organization permissions",
  "list.projects.permission": "List projects permission",
  "organization.permissions.billing.info":
    "This permission can edit and manage the billing details of this organization.",
  "organization.permissions.plans.info":
    "This permission can edit and manage the project plans on all projects in this organization.",
  "organization.permissions.members.info":
    "This permission can edit and manage the users in this organization.",
  "organization.permissions.projects:create.info":
    "This permission can edit and manage the projects in this organization.",
  "organization.permissions.projects:list.details":
    "If a member has the manage plans or billing permission, they will automatically be able to see every organization project.",
  "organization.permissions.projects:list.details.noplans":
    "If a member has the billing permission, they will automatically be able to see every organization project.",
  "organizations.edit_user": "Edit user",
  "access.owner": "Admin (Owner)",
  "organizations.delete_user": "Remove from organization",
  no_users: "No users",
  invite: "Invite",
  "account.connected": "{accountName} is connected",
  "account.connect": "connect {accountName}",
  download_build_log: "Download .txt log",
  copy_build_log: "Copy log",
  copy_build_url: "Copy URL",
  "cli.clone.description":
    "Clone your codebase and boost your development with the official tool to manage your projects",
  "cli.install": "Install CLI",
  "git.clone.description": "Clone your codebase using Git and an SSH Key.",
  "git.learnmore": "Learn More",
  cli: "cli",
  "source_ops.variable.name": "Variable name",
  "source_ops.variable.name.placeholder": "Variable Name",
  "source_ops.variable.value": "Value",
  "source_ops.variable.value.placeholder": "Enter Value",
  "source_ops.configure.title": "Configure your source operation",
  "source_ops.runtime.configure.title": "Configure your runtime operation",
  "source_ops.configure.message":
    "You’ve not yet configured your environment to run a Source operation.",
  "source_ops.runtime.configure.message":
    "You’ve not yet configured your environment to run a Runtime operation.",
  "source_ops.configure.learnmore":
    "Learn how to configure yours in our <link>documentation.</link>",
  "source_ops.upgrade.title": "Upgrade to run your source operations",
  "source_ops.upgrade.runtime.title": "Upgrade to run your runtime operations",
  "source_ops.upgrade.message":
    "If you want to run source operations, you must upgrade to our Enterprise tier.{br}Contact our sales team or check our Pricing page.",
  "source_ops.upgrade.runtime.message":
    "If you want to run runtime operations, you must upgrade to our Enterprise tier.{br}Contact our sales team or check our Pricing page.",
  view_pricing: "View Pricing",
  override_variables: "Add/Override variables",
  "source_ops.define_variables_info":
    "Define the variables used by your operation, or override an existing environment variable. The variables will be automatically prefixed with ('env:').",
  select_operation: "Select operation",
  operation: "Operation",
  operations: "Operations",
  run: "Run",
  "command.hide": "Hide command",
  "command.view": "View Command",
  applications: "Application/s",
  "source_ops.runnable":
    "You can run the source operation {operation} on this environment.",
  "source_ops.runtime.runnable":
    "The runtime operation {operation} will be run in the environment {environment}.",
  variables_info:
    "Variables are optional, depending on how your operation is configured.",
  "source_ops.select_operation_many":
    "Select a source operation to run in the environment {environment}.",
  "source_ops.runtime.select_operation_many":
    "Select a runtime operation to run in the environment {environment}.",
  "source_ops.run": "Run source operation{operation}",
  "source_ops.runtime.run": "Run runtime operation{operation}",
  run_source_link_message: "Run source operation",
  run_runtime_link_message: "Run runtime operation",
  ssh_deploy_key: "SSH Deploy Key",
  CLI_command_name: "platform",
  app_name: "Platform.sh",
  see_our_documentation: "See our documentation to learn more.",
  "integration.forward_logs_to_newrelic":
    "Forward logs from your project to New Relic",
  "integration.forward_logs_to": "Forward logs from your project to {name}",
  create_organization: "Create organization",
  "organization.input.label.placeholder": "Organization name",
  "organization.input.url.placeholder": "Organization URL",
  "organization.url.preview.placeholder": "[your-url]",
  "organization.input.name.validation-message":
    "Use 3-39 alphanumeric characters with single dashes between words. No consecutive dashes or dashes at the start/end.",
  "organization.create.prompt.tooltip-message":
    "Create your Organization before you continue",
  "organization.new": "New organization",
  "organization.intruduction.illustration-alt":
    "organization introduction illustration",
  source_operation: "Source Operation",
  source_operation_executed: "executed source operation",
  source_operation_on_env: "on environment",
  runtime_operation: "Runtime Operation",
  all_operations: "Operations",
  "settings.menu.project_settings": "Project Settings",
  "settings.menu.environment_settings": "Environments",
  "settings.menu.general": "General",
  "settings.menu.access": "Access",
  "settings.menu.certificates": "Certificates",
  "settings.menu.domains": "Domains",
  "settings.menu.deploy_key": "Deploy Key",
  "settings.menu.backup_schedules": "Backup schedules",
  "settings.menu.integrations": "Integrations",
  "settings.menu.variables": "Variables",
  "settings.menu.resources": "Resources",
  "settings.certificates.modal.delete": " for the domains",
  "settings.resources.title": "Resources",
  "settings.buildResources.title": "Build resources",
  "settings.buildResources.description":
    "Configure the resources for the build container. 2.5 CPU hours and 5 RAM hours are included with each project for free.",
  "settings.buildResources.cpu.input.label": "CPU",
  "settings.buildResources.ram.input.label": "RAM",
  "settings.buildResources.ram.input.MB": "MB",
  "settings.buildResources.note":
    "Note: Changing build container sizes will impact your monthly project cost. <link>View pricing</link>",
  "subscription.button.delete": "Yes, Delete Project",
  "subscription.input.delete.confirm-name-label": "Enter project name",
  "subscription.delete.caution":
    "Please note that deleting your project is irreversible and all data associated with this project will be deleted, including backups. Furthermore, at the end of the month you will be charged for any remaining project costs.",
  "filter.domain": "Domain",
  cleared_the: "cleared the",
  for: "for",
  "filter.upgrade": "Upgrade",
  filter: "Filter",
  "filter.title": "Filter by",
  all_hosts: "All hosts",
  filter_hosts: "Filter hosts",
  host: "Host",
  "filter.integration": "Integrations",
  clear_all: "Clear all",
  clear: "Clear",
  "menu.staff_test_mode": "Staff test mode",
  "test_mode.active_label": "Active",
  "test_mode.title": "Staff test mode",
  "test_mode.description":
    "Apply test settings to change the appearance, free trial status or system status of console. These will remain until cleared manually.",
  "test_mode.theming.title": "Theming",
  "test_mode.theming.select_mode": "Mode",
  "test_mode.trial_modes.title": "Trial modes",
  "test_mode.trial_modes.start_date": "Start date",
  "test_mode.trial_modes.expiration_date": "Expiration date",
  "test_mode.trial_modes.select_status": "Status",
  "test_mode.system_status.title": "System status",
  "test_mode.system_status.select_status": "Status",
  "test_mode.page_refresh":
    "A page refresh is required to clear all settings as they're saved to your session.",
  "simplemenu.placeholder": "Search projects",
  "simplemenu.default": "Project Title",
  "simplemenu.live_message":
    "project menu updated to show results that contain {searchTitle}. {listCount} results found.",
  "simplemenu.no-result": "No results.",
  "blackfire.title": "Blackfire",
  "integration.observability_suite": "Observability Suite",
  "integration.observability_suite.info_title":
    "All included in Observability Suite",
  "integration.observability_suite_upgrade_title":
    "Upgrade to the Observability Suite",
  "integration.observability_suit.info_learn_about":
    "Learn about observability suite",
  "blackfire.card.message.subscribed":
    "Monitor, validate and improve site performance with Blackfire.",
  "blackfire.card.message.grid.unsubscribed":
    "Learn more about how to monitor, validate and improve site performance with Blackfire.",
  "blackfire.card.message.dg2":
    "Blackfire is available as part of the Observability Suite. If you haven't subscribed to Blackfire yet, contact us to learn more.",
  "blackfire.card.message.not_configured":
    "Configure Blackfire to enable the Observability suite features on this environment.",
  "blackfire.card.cta.launch": "Launch Blackfire",
  "blackfire.card.cta.contact_us": "Contact us",
  "blackfire.card.cta.learn_more": "Learn more",
  "organization.members.cannoteditown":
    "You cannot manage your own permissions",
  "blackfire.add": "Add Blackfire",
  learn_more: "Learn more",
  "blackfire.integration.enabled_message":
    "Blackfire is enabled for this project. You can now monitor, validate and improve site performance at Blackfire.io",
  "blackfire.integration.upgrade_message":
    "Blackfire monitoring can be added by visiting Blackfire.io, or contact sales to upgrade to the full Observability Suite.",
  "blackfire.integration.upgrade_title": "Upgrade to Blackfire",
  "organizations.members.cannoteditown":
    "You cannot manage your own permissions",
  "organizations.permissions.description":
    "Users are automatically assigned all organization permissions. To customize permissions,",
  "organizations.permissions.link": "upgrade to our Standard add-on.",
  "organizations.permissions.note":
    "Note: The add-on is free during your free trial. After the trial, adding users \nwill increase your monthly costs. To learn more, visit our ",
  "organization.members.caneditassigned":
    "You can only assign permissions that you have been granted.",
  "organization.members.cannoteditaccess":
    "Contact your org admin to make necessary changes.",
  "kyc_verification.methods.title": "Verification methods",
  "kyc_verification.methods.intro": "Where do you want your code sent?",
  "kyc_verification.methods.help":
    "Need more help? <a>Contact our support team</a>.",
  "kyc_verification.methods.sms.label": "Text message (SMS)",
  "kyc_verification.methods.sms.label_details": "We'll text you a code.",
  "kyc_verification.methods.call.label": "Phone call",
  "kyc_verification.methods.call.label_details": "We'll call you with a code.",
  "kyc_verification.methods.submit": "Resend code",
  "kyc_verification.methods.continue": "Continue",
  "kyc_verification.phone.title": "Verify your number",
  "kyc_verification.phone.intro":
    "Can we have your number? We just need to verify you’re human after all. And we promise it won’t be stored. You’ll be sent a verification code to enter here and that’s it!",
  "kyc_verification.phone.fields.phone.label": "Phone number",
  "kyc_verification.phone.fields.phone.placeholder": "Enter phone number",
  "kyc_verification.phone.switch_method": "Other verification methods",
  "kyc_verification.phone.submit": "Send code",
  "kyc_verification.code.title": "Enter the code",
  "kyc_verification.code.intro.sms":
    "Enter the code we sent over SMS to {phone}.",
  "kyc_verification.code.intro.call":
    "We'll call you at {phone}. Enter the code below.",
  "kyc_verification.code.help":
    "Didn't get a code? <a>Choose another method</a>.",
  "kyc_verification.code.submit": "Confirm",
  "kyc_verification.ticket.title": "Account verification needed",
  "kyc_verification.ticket.intro":
    "It looks like your account requires additional verification. Please contact our support team to activate your new {app_name} account.",
  "kyc_verification.ticket.back": "Cancel",
  "kyc_verification.ticket.contact": "Contact support",
  "organization.members.teams": "Teams",
  "organization.member.teams.add": "Add user to teams",
  "organization.member.teams.removeFromTeam": "Remove from team",
  "organization.member.teams.remove.modal.title": "Remove user",
  "organization.member.teams.remove.modal.description":
    "Are you sure you want to remove <b>{username}</b> from the team <b>{teamname}</b>?",
  "organization.teams.member.add.notification.success.description":
    "{count} {count, plural, one{user} other{users}} added to the team <b>{team}</b>",
  "organization.teams.member.add.notification.error.title":
    "Failed to invite to team",
  "organization.teams.member.add.notification.error.description":
    "Unable to add <b>{username}</b> to team <b>{team}</b><br></br>{description}",
  "phone_verification.methods.title": "Verification methods",
  "phone_verification.methods.intro": "Where do you want your code sent?",
  "phone_verification.methods.help":
    "Need more help? <a>Contact our support team</a>.",
  "phone_verification.methods.sms.label": "Text message (SMS)",
  "phone_verification.methods.sms.label_details": "We'll text you a code.",
  "phone_verification.methods.call.label": "Phone call",
  "phone_verification.methods.call.label_details":
    "We'll call you with a code.",
  "phone_verification.methods.submit": "Resend code",
  "phone_verification.methods.continue": "Continue",
  "phone_verification.phone.title": "Verify your number",
  "phone_verification.phone.intro":
    "Can we have your number? We just need to verify you’re human after all. And we promise it won’t be stored. You’ll be sent a verification code to enter here and that’s it!",
  "phone_verification.phone.fields.phone.label": "Phone number",
  "phone_verification.phone.switch_method": "Other verification methods",
  "phone_verification.phone.submit": "Send code",
  "phone_verification.code.title": "Enter the code",
  "phone_verification.code.intro.sms":
    "Enter the code we sent over SMS to {phone}.",
  "phone_verification.code.intro.call":
    "We'll call you at {phone}. Enter the code below.",
  "phone_verification.code.help":
    "Didn't get a code? <a>Choose another method</a>.",
  "phone_verification.code.submit": "Confirm",
  "certificates.back_to": "Back to certificates",
  "certificates.add_certificate": "Add certificate",
  "certificates.add": "Add",
  "certificates.description":
    "{appName} automatically generates TLS certificates for all of your applications to enable HTTPS.",
  "certificates.description2":
    "You can also provide custom certificates on this page.",
  "certificates.info": "Learn more about Certificates in our",
  "certificates.documentation": "documentation",
  "certificates.no_certificates": "No certificates.",
  "certificates.sort.name": "Certificates",
  "certificates.sort.expiryDate": "Expiry date",
  dedicated: "Dedicated",
  grid: "Grid",
  "blackfire.card.cta.not_subscribed": "Contact us",
  "blackfire.card.cta.not_configured": "Learn more",
  time_input_tip: "Enter time in 24-hour (23:59) format",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  apply: "Apply",
  app_manager: "App manager",
  template_filter_all_tag: "All types",
  "download.pdf": "Download PDF",
  "organization.member.owner": "Owner",
  "plans.blackfire.pro_addon_description":
    "Optimize app performance for a better user experience",
  "plans.blackfire.enterprize_addon_description":
    "Monitor, profile, and test your application. Included with the Observability Suite.",
  "plans.blackfire.addon_add_prompt_continue_title":
    "Continue with Blackfire enabled",
  "plans.blackfire.addon_support_prompt_continue_title":
    "Continue with Blackfire enabled",
  "plans.blackfire.addon_support_prompt_continue_description":
    "Blackfire currently only supports {blackfireSupportLanguages} applications, but we didn’t detect any of these on your project. Are you sure you want to save the project with Blackfire enabled?",
  "plans.blackfire.addon_add_prompt_continue_description":
    "Blackfire currently only supports {blackfireSupportLanguages}. Are you sure you want to add Blackfire to your subscription?",
  "plans.blackfire.addon_add_prompt_continue_description_note":
    "<strong>Note: </strong> User and environment data will be synchronized to <a>Blackfire.io</a>. Existing environments must be redeployed to activate the synchronization.",
  "plans.blackfire.pro_addon_acquire_info":
    "Blackfire is a Platform.sh product that provides actionable performance insights for {blackfireSupportLanguages} projects. The profiler is included with every add-on on all plans. Production plans get access to monitoring and alerting.",
  "plans.blackfire.enterprize_addon_acquire_info":
    "Blackfire is a Platform.sh product and is part of the Observability Suite upgrade. Contact our sales team to upgrade.",
  "plans.blackfire.enterprize_addon_included_info":
    "Blackfire is a Platform.sh product. This project already includes Blackfire through the Observability Suite upgrade.",
  "organization.permissions.billing.definitionTerm": "Manage billing",
  "blackfire.io": "https://blackfire.io",
  "organization.permissions.billing.definitionDetails":
    "Manage the billing details of this organization.",
  "organization.permissions.admin.definitionTerm": "Admin",
  "organization.permissions.admin.definitionDetails":
    "Manage the organization and grants access to all organization permissions.",
  "organization.permissions.plans.definitionTerm": "Manage plans",
  "organization.permissions.plans.definitionDetails":
    "Edit and manage project plans.",
  "organization.permissions.members.definitionTerm": "Manage users",
  "organization.permissions.members.definitionDetails":
    "Edit and assign permissions that you have been granted to users within this organization",
  "organization.permissions.projects:create.definitionTerm": "Create projects",
  "organization.permissions.projects:create.definitionDetails":
    "Create new projects within the organization.",
  "organization.permissions.projects:list.definitionTerm": "List projects",
  "organization.permissions.projects:list.definitionDetails":
    "View all projects within the organization.",
  "organization.invitation.resend.confirm.title": "Resend invitation",
  "organization.invitation.resend.confirm.body":
    "Are you sure you want to resend the invitation for {email}?",
  "organization.invitation.revoke.confirm.title": "Revoke invitation",
  "organization.invitation.revoke.confirm.body":
    "Are you sure you want to revoke the invitation for {email}?",
  "organization.invitation.revoke.success.title":
    "{count, plural, one{Invitation} other{Invitations}} revoked",
  "organization.invitation.revoke.success.description": "{email} revoked.",
  "organization.invitation.revoke.rejected.title": "Invitation revoke failure",
  "organization.invitation.revoke.rejected.description":
    "{email} could not be revoked.",
  "organization.invitation.failure.title": "Invitation failure",
  "organization.invitation.failure.description":
    "Invitation to {email} failed.",
  "organization.invitation.sent.title":
    "{count, plural, one{Invitation} other{Invitations}} sent",
  "organization.invitation.sent.description": "{email} invited.",
  "organization.invitation.resent.title":
    "{count, plural, one{Invitation} other{Invitations}} re-sent",
  "organization.invitation.resent.description":
    "{count, plural, one{Invitation} other{Invitations}} to {email} resent.",
  "organization.member.bulk.editSelected": "Edit selected",
  "organization.member.bulk.remove.fulfilled.title":
    "{count, plural, one{User} other{Users}} removed",
  "organization.member.bulk.remove.fulfilled.description": "{email} removed.",
  "organization.member.bulk.remove.rejected.title":
    "Failure removing {count, plural, one{user} other{users}}",
  "organization.member.bulk.remove.rejected.description":
    "{email} could not be removed.",
  "organization.member.bulk.selected_count": "{count} selected",
  "organization.member.bulk.remove_selected":
    "Remove {count, plural, one{user} other{users}}",
  "organization.member.bulk.revoke_selected": "Revoke selected",
  "organization.member.bulk.resend_selected": "Resend selected",
  "organization.member.bulk.revoke_error_tooltip":
    "You cannot revoke any invitations that have errors",
  "organization.member.bulk.team.invite": "Add to team",
  "organization.member.bulk.team.searchTeam": "Type to search or select teams",
  "organization.member.bulk.team.searchUser": "Type to search or select users",
  "organization.member.bulk.team.searchUser.loading":
    "Loading organization members...",
  "organization.project.access.searchUser": "Type to search or select users",
  "organization.member.bulk.team.searchProject":
    "Type to search or select projects",
  "organization.member.bulk.team.allTeams": "All teams",
  "organization.member.bulk.team.allProjects": "All existing projects",
  "organization.member.bulk.team.selectedTeams":
    "{count} {count, plural, one{team} other{teams}} selected",
  "organization.member.bulk.team.selectedProjects":
    "{count} {count, plural, one{project} other{projects}} selected",
  "organization.member.bulk.team.create": "Create team",
  "organization.member.bulk.team.rejected.title":
    "Failed to add {member} to {team}",
  "organization.member.bulk.team.fulfilled.title": "Members added",
  "organization.member.bulk.team.fulfilled.description":
    "{memberCount} users successfully added to {teamCount} teams",
  "access.team.bulk.fulfilled.title": "Access updated",
  "access.team.bulk.fulfilled.description":
    "Project added to {count} {count, plural, one{team} other{teams}}.",
  "organization.filter.permission.title": "Filter by permission",
  "organization.sort.title": "Sort by",
  "organization.member.sort.createdAsc": "Created at (Asc)",
  "organization.member.sort.createdDesc": "Created at (Desc)",
  "organization.invitation.sort.updatedAsc": "Updated at (Asc)",
  "organization.invitation.sort.updatedDesc": "Updated at (Desc)",
  "organization.member.deleteconfirmation.title":
    "{email} successfully deleted",
  "organization.member.viewProject": "View project",
  "organization.member.editPermissions": "Edit permissions",
  "organization.member.removeProject": "Remove from project",
  "organization.member.level.user": "User license",
  "organization.member.level.user.tooltip":
    "You are billed a $10 user license for this user.",
  "organization.member.level.viewer": "Viewer",
  "organization.member.level.viewer.tooltip":
    "You aren’t billed a $10 user license for viewers.",
  "organization.projectaccess.updateSuccess.title": "Access updated",
  "organization.projectaccess.updateError.title":
    "Failed to update project access",
  "organization.projectaccess.deleteModalTitle": "Remove user",
  "organization.projectaccess.deleteModalBody":
    "Are you sure you want to remove <b>{userName}</b> from project <b>{projectName}</b>? ",
  "organization.projectaccess.edituserpermissions.modal.title":
    "Edit {projectName} permissions",
  "organization.projectaccess.edituserpermissions.modal.description":
    "Manage the project permissions assigned to user <b>{userName}</b> ({userEmail}).",
  "select_environment.pre_label": "Environment",
  select_environment: "Select environment",
  template_filter_all_language: "All languages",
  project_variables: "Project variables",
  select_organization: "Select organization",
  organizations: "Organizations",
  organization: "Organization",
  organizations_description:
    "Select an organization for managing your project’s billing and users’ permissions.{br}{br} If you need to create a new organization go  <a>here</a>.",
  "setup.cli.install.unix.label": "OSX or Linux Installer",
  "setup.cli.install.unix.code": "brew install platformsh/tap/platformsh-cli",
  "setup.cli.install.windows.label": "Windows Installer",
  "setup.cli.install.windows.code":
    "scoop bucket add platformsh https://github.com/platformsh/homebrew-tap.git",
  username_error_message:
    "The username must be between 3-39 characters long and can only contain lowercase alphanumerical characters, and non-consecutive dashes.",
  invalid_username: "Invalid username",
  username_length: "Must be between 3-32 characters",
  no_access_environment_decativate:
    "Ask your project admin to deactivate environment",
  no_access_environment_decativate_local_target:
    "Ask your project admin to deactivate environment",
  no_access_environment_reactivate:
    "Ask your project admin to reactivate environment",
  code: "code",
  code_trigger_btn: "Code",
  delete_branch_link_cli: "CLI command",
  delete_branch_link:
    "https://docs.platform.sh/environments/deactivate-environment.html#delete-the-branch",
  "platform_delete_env_cmd.integration":
    "git push {repository} --delete  {envName}",
  platform_delete_env_cmd: "git push --delete {cli_name} {envName}",
  app_environment_variable_name: "$PLATFORM_VARIABLES",
  configuration_folder: ".platform",
  app_config_file: ".platform.app.yaml",
  app_service_file_path: ".platform/service.yaml",
  app_services_file_path: ".platform/services.yaml",
  app_routes_file_path: ".platform/routes.yaml",
  app_applications_file_path: ".platform/applications.yaml",
  "trial_state.trial_ending": "Trial expires soon",
  "trial_state.suspended": "Suspended",
  "trial_state.free_trial": "Free trial",
  minutes: "Minutes",
  start_trial: "Start your 14-day free trial",
  start_trial2: "The fastest way to build digital products",
  try_platform: "try {app_name} today with no strings attached!",
  all_free_trial_include: "all free trials include",
  dev_envs: "development environments",
  of_storage: "of storage",
  try_today: "try our free trial today",
  start_free_trial: "Start a free trial",
  try_for_free: "try for free",
  try_us_for_free: "try us for free",
  signup_tac: "By signing up you agree to the",
  tac: "Terms & Conditions",
  sign_up: "Sign up",
  or_use_email: "or use email",
  email_placeholder: "Email address",
  password_placeholder: "Password",
  set_password: "Let’s get your account set up. Create your password to begin.",
  check_inbox: "check your inbox",
  check_inbox_resent:
    "Check your inbox and if you still haven’t received an email please",
  contact_support: "contact our support team",
  verify_account:
    "verify your account by clicking the link in the confirmation email we just sent you.",
  havent_receive: "haven’t received your email yet?",
  resend_email: "resend email",
  return_website: "Return to our website",
  return_login: "return to our log in",
  fastest_way: "The fastest way to build digital products",
  build_run: "Sign up to try it for free (no credit card required)",
  build_run_scale: "Build, run, and scale your apps—end to end.",
  with_psh_you_can: "With {app_name} you can",
  clone: "Clone instantly",
  deploy: "Deploy at scale anywhere!",
  signin_tac: "by signing in you agree to our",
  dont_have_account_yet: "don’t have an account yet? Sign up for free today!",
  dont_have_account: "don’t have an account?",
  welcome: "welcome to {app_name}",
  signin_with_linked_account: "Sign in with your account",
  login_with_email: "log in using your email",
  log_in: "Log in",
  sign_in: "Sign in",
  verify: "Verify",
  verification_code: "Verification code",
  tfa: "Two-Factor Authentication",
  tfa_description:
    "Verify your account with the credentials from your two-factor authentication app.",
  remember_this_browser: "Remember this browser for 1 month",
  reset_password: "Reset password",
  reset_password_title: "Reset your {app_name} password",
  reset_password_description:
    "Enter the email address associated with your account and we’ll send an email with instructions to reset your password.",
  email_username: "Email",
  email_username_placeholder: "Email address",
  sign_up_linked_account: "sign up with an account",
  use_your_email: "Sign up using your email",
  one_user: "1 User",
  three_dev_env: "3 Development environments",
  "5_gb_storage": "5 GB of storage",
  already_have_account: "already have an account?",
  by_signing_up_you_agree: "By signing up you agree to the",
  terms_conditions: "Terms of Service",
  help_support: "Help & Support",
  cant_verify_tfa: "can’t retrieve your TFA code?",
  use_recovery_code: "use your recovery code",
  recovery_code: "Recovery code",
  use_tfa: "Use my TFA verification code",
  wait_i_remember: "wait, I remember my password",
  forgot_password: "I forgot my password",
  to_reset_your_password:
    "To reset your password click the link in the confirmation email we just sent you.",
  your_password_reset: "Your password was successfully reset!",
  your_can_now_use_new_password: "You can now use your new password to log in.",
  return_to_log_in: "Return to our log in",
  register_meta_title: "Register on {app_name}",
  register_title:
    "<nowrap>One platform to build, run,</nowrap> and scale your websites & web apps",
  log_in_title: "Log in to {app_name}",
  company_url: "Company URL",
  company_http: "https://",
  username_notice: "Your username will also become your unique {app_name} URL",
  username_guidelines:
    "Your username cannot include spaces or special characters. This will be displayed as your URL. e.g.",
  test_ideas: "{app_name} is the end-to-end web platform",
  end_to_end: "end-to-end",
  web_platform: "web platform",
  easily_add: "Easily add & manage your services",
  global_security: "Global security, 24/7",
  consent_description: "Authorize {app_name} to use your account",
  consent_user: "{user_name} ({user_email})",
  yes_authorize: "Yes, authorize",
  sorry_link_expired: "Sorry but it looks like your link has expired.",
  to_receive_new_link:
    "To receive a new link via email you’ll need to reset your password again.",
  signup_link_expired: "Sorry but it looks like your link has expired.",
  please_signup_again:
    "Please sign up again to receive your activation link in your inbox.",
  change_email_link_expired:
    "Your verification link has expired. These are only valid for 24 hours. Please log in to try again.",
  email_confirmation: "Email confirmation",
  email_success_change: "Your email was successfully changed!",
  can_use_new_email: "You can now use your new email address to log in.",
  not_found_title: "Page not found",
  internal_server_title: "Something’s not right",
  internal_server_desc:
    "Try checking your internet connection, disable any ad blockers and if all else fails - get in touch so we can take a closer look.",
  forgot_your_password: "Forgot your password",
  forgot_your_password_federated:
    "Your password cannot be reset on {app_name} as your account is subject to third-party authentication. Use the link below to access your authentication provider",
  go_to_your_sso_provider: "Go to your SSO provider",
  when_ready:
    "When you’re ready to launch, get multicloud production hosting and 24/7 support – for 1 site or 1,000",
  git_driven:
    "Git-driven development workflow with instant environment cloning",
  git_driven_short: "Git-driven development workflow",
  hight_performance: "High performance container based multicloud hosting",
  built_for: "Built for Node.js, Python, Java and more",
  pricing: "<b>Free for 30 days, then starts at $12 per month</b>",
  service_built: "Services built-in, no add-ons required",
  support_php: "Support for PHP, Java, Node.js, Python and more",
  built_in: "Built-in CI/CD",
  multicloud: "Multicloud production hosting",
  manage: "Manage one site or 1,000",
  signup_try: "Sign up to try {app_name} for free",
  no_credit_card: "(no credit card required)",
  no_credit_card_signup: "No credit card required",
  invitation_error_title: "Sorry, but..",
  invitation_error_desc:
    "You need to sign up using the email address that you received your invitation link from.",
  back_to_console: "Back to console",
  log_out: "Log out",
  password_guidelines_title: "Password requirements",
  password_guidelines:
    "Must be 10 characters or more and contain at least one of each of the following: uppercase, lowercase, number and symbol.",
  "password_guidelines.characters": "Use at least 10 characters",
  "password_guidelines.uppercase": "Add one uppercase letter",
  "password_guidelines.lowercase": "Add one lowercase letter",
  "password_guidelines.symbol": "Add a symbol",
  "password_guidelines.number": "Add a number",
  customer_g2:
    "{app_name} is a leader in Cloud Platform as a Service according to our customers on G2.",
  privacy_policy: "Privacy Policy",
  "password.error.lowercase": "Add one lowercase letter",
  "password.error.uppercase": "Add one uppercase letter",
  "password.error.digit": "Add a number",
  "password.error.special_char": "Add a symbol",
  "password.error.long": "Use at least 10 characters",
  one_more_step: "One more step",
  finish_country_setup: "Please add your country to finish your account setup.",
  finish_setup: "Finish setup",
  three_trial: "3 free development environments for 30 days",
  admin_change: "Ask your project admin to make a change",
  hide_all_instances: "hide all",
  hipaa: "HIPAA",
  ssh_documentation: "Documentation",
  show_all_instances_info:
    "...(showing {shown_instances} of {all_instances_number} instances)",
  show_all_instances: "show all",
  search_apps: "Search apps",
  app: "App",
  worker: "Worker",
  no_results: "No results",
  "source_ops.configure_env.learnmore":
    "Learn how to configure your environment to run Source Operations in our <link>documentation </link>.",
  "source_ops.runtime.configure_env.learnmore":
    "Learn how to configure your environment to run Runtime Operations in our <link>documentation </link>.",
  select: "Select",
  application_webworker: "Application / worker",
  "source_ops.runtime.worker":
    "Choose an application or worker to run this operation during runtime.",
  docs: "Docs",
  not_included: "Not included",
  "source_ops.configure.pro.learnmore": "Learn more in our ",
  "source_ops.configure.pro.upgrade":
    "If you want to run source operations instead, you must upgrade to our Enterprise tier. Contact our <linkSalesTeam>sales team</linkSalesTeam> or check our <linkPricingPage>Pricing page</linkPricingPage>",
  username_characters: "Only lowercase letters, numbers, hyphens are allowed",
  contact_org_owner:
    "Contact your organization owner to make necessary changes.",
  "teams.create.form.projectpermissions": "Project roles",
  "teams.create.form.projectadmin": "Project admin",
  "teams.create.form.environmenttypepermissions": "Environment type roles",
  "teams.create.form.submit": "Create team",
  "teams.create.form.cancel": "Cancel",
  "teams.create.form.teamname.label": "Team name",
  "teams.create.form.teamname.placeholder": "Enter new team name",
  "teams.create.success.title": "Team created",
  "teams.create.success.description":
    "Team <b>{label}</b> has been successfully created.",
  "teams.edit.form.submit": "Save",
  "teams.edit.form.cancel": "Cancel",
  "teams.edit.form.delete": "Delete team",
  "teams.edit.success.title": "Team updated",
  "teams.edit.error.title": "Unable to update team",
  "teams.edit.error.description": "Couldn't update team <b>{label}</b>.",
  "teams.users.add.title": "Add users to your team",
  "teams.users.add.button": "Add users",
  "teams.users.add.button.disabled":
    "You need to create a team before you can add users.",
  "teams.members.table.header.name": "Name",
  "teams.members.table.header.email": "Email",
  "teams.members.row.edit": "Edit user",
  "teams.members.row.delete": "Remove user",
  "teams.member.delete.modal.title": "Remove user",
  "teams.member.delete.modal.description":
    "Are you sure you want to remove <b>{username}</b> from the team <b>{teamname}</b>? ",
  "teams.member.delete.modal.confirmation.title": "User removed",
  "teams.member.delete.modal.confirmation.description":
    "User <b>{username}</b> removed from the team <b>{teamname}</b>.",
  "teams.projects.table.header.name": "Name",
  "teams.projects.table.header.id": "Project id",
  "teams.projects.row.edit": "Edit project",
  "teams.projects.row.delete": "Remove project",
  "teams.projects.add.button": "Add projects",
  "teams.projects.add.button.disabled":
    "You need to create a team before you can add projects.",
  "teams.projects.add.title": "Add projects to your team",
  "teams.projects.add.modal.confirmation.title": "Project access added",
  "teams.projects.delete.modal.confirmation.title": "Project access removed",
  "teams.projects.delete.modal.confirmation.description":
    "Project <b>{projectname}</b> removed from the team <b>{teamname}</b>.",
  "teams.projects.delete.modal.title": "Remove project",
  "teams.projects.delete.modal.description":
    "Are you sure you want to remove the project <b>{projecttitle}</b> from the team <b>{teamname}</b>? ",
  "teams.list.table.header.name": "Name",
  "teams.list.table.header.users": "Users",
  "teams.list.table.header.permissions": "Project roles",
  "teams.list.row.adduser": "Add user",
  "teams.list.row.edit": "Edit team",
  "teams.list.row.delete": "Delete team",
  "teams.list.title": "Manage teams",
  "teams.list.projects.count":
    "{count} {count, plural, one{Project} other{Projects}}",
  "teams.add_users.button": "Add users",
  "teams.add_users.title": "Add users to {teamname}",
  "teams.member.bulk.selectedMembers":
    "{count} {count, plural, one{user} other{users}} selected",
  "members.inviteToTeamTitle": "Add to teams",
  "teams.modal.delete.title": "Delete team",
  "teams.modal.delete.message":
    "Are you sure you want to delete <b>{teamName}</b> from the organization <b>{organizationName}</b>? This will remove this team from all projects and users within this organization.",
  "teams.delete.fail.description": "{team} could not be removed.",
  "teams.delete.success.description": "{team} has successfully been deleted.",
  "breadcrumb.settings.teams.-.id": "Create team",
  "access.team.delete.modal.description":
    "Are you sure you want to remove the team <b>{teamname}</b> from the project <b>{projectname}</b>?",
  "access.team.delete.modal.notification.title": "Project removed from team",
  "access.team.delete.modal.notification.description":
    "<b>{projectname}</b> removed from <b>{teamname}</b>",
  "teams.org.cta.title": "Add teams to your organization",
  "teams.org.cta.description":
    "Enterprise and elite plans now have access to teams. ",
  "teams.new.feature.tag": "New feature",
  "teams.manage.access.banner":
    "Manage user access on all projects at once in the new centralized Users page. {checkItOut}",
  "teams.cta.upgrade.plan":
    "Upgrade to Enterprise or Elite to add Teams to your organization, so you can manage groups of users more easily. {learnmore}",
  "teams.cta.check.it.out": "Check it out",
  "teams.feature.project.access":
    "Gain easier project access control all from one place",
  "teams.feature.group.users": "Group users with the same permissions",
  "teams.feature.multiple.users.permissions":
    "Manage project permissions for multiple users at once",
  "teams.feature.bulk.add.users": "Bulk add users to teams",
  "teams.member.permissions": "Permissions",
  "teams.create.form.teamname.error": "This field is required.",
  "auth.left_panel.title":
    "A highly flexible PaaS to develop diverse applications",
  "auth.left_panel.description":
    "Do more with {appName}. Quickly build, run, and scale all your applications—on a single platform.",
  "auth.left_panel.items":
    "<item>Composable infrastructure</item><item>Preview environments (including all your data) in seconds</item><item>Built-in security and observability</item><item>On any major cloud provider</item><item>Stack agnostic: Python, Node.js, PHP, and 70+ more</item>",
  "auth.left_panel.disclaimer":
    "*Limited to USD $100 resource usage/resource limitation, or equivalent in AUD, CAD, EUR, GBP, depending on the billing currency applicable to your account",
  "welcome.title": "Welcome to {app_name}",
  "welcome.description":
    "Let’s get your account set up. Tell us where you are, and create your password to begin.",
  "welcome.select_country": "Select country",
  "setup.verify.title": "Verification",
  "setup.verify.description":
    "To help fight abuse, please provide a valid credit card.<br></br><strong>You will not be charged unless you choose to subscribe to Upsun later on.</strong>",
  "setup.verify.select_country": "Select country",
  select_org_info_dialog:
    "Select an organization for managing your project’s billing and users’ permissions.<br></br><br></br>If you need to create a new organization go <a>here</a>.",
  "setup.overview.free_trial.title": "Project overview",
  "setup.overview.free_trial.note":
    "Your new project will be created with the minimum resources required to run your selected apps and services effectively.",
  "setup.overview.free_trial.card.title": "You’re starting on our Free trial",
  "setup.overview.free_trial.card.subtitle":
    "14 days with $100 to spend across your organization",
  "setup.overview.free_trial.card.description":
    "Your free trial will automatically end in 14 days or as soon as usage reaches $100, whichever event occurs first.",
  "setup.overview.free_trial.footer.note":
    "Note: Any modifications to your account may impact your monthly charges, which are calculated on an hourly, prorated basis throughout the month.",
  "setup.overview.free_trial.footer.link":
    "To learn more about our pricing visit our <link>pricing page</link>.",
  "setup.overview.paying.note":
    "Your new project will be created with the minimum resources required to run your selected apps and services effectively. ",
  "setup.overview.paying.estimation.title": "Estimated monthly project cost",
  "setup.overview.paying.estimation.subtitle":
    "Based on monthly usage of 732 hours",
  "setup.overview.paying.estimation.application": "Application 1 CPU, 1GB Ram",
  "setup.overview.paying.estimation.sercvice": "Service 1 CPU, 1GB Ram",
  "setup.overview.paying.estimation.storage": "Storage 1GB",
  "setup.overview.paying.estimation.fee": "Project fee",
  "setup.overview.paying.estimation.user": "User",
  "setup.overview.paying.estimation.total": "Total monthly estimate",
  "setup.overview.paying.estimation.application.value": "$35 USD",
  "setup.overview.paying.estimation.sercvice.value": "$52 USD",
  "setup.overview.paying.estimation.storage.value": "$366 USD",
  "setup.overview.paying.estimation.fee.value": "$9 USD",
  "setup.overview.paying.estimation.user.value": "$19 USD",
  "setup.overview.paying.estimation.total.value": "$811 USD",
  "setup.overview.paying.footer.note":
    "Note: These costs are estimates only and based on what you choose to use each month.<br></br>Any modifications to your account may impact your monthly charges, which are calculated on an hourly, prorated basis throughout the month.",
  "setup.overview.paying.footer.link":
    "To learn more about our pricing, see our <link>pricing page</link>.",
  lets_get_started: "Let’s get started!",
  how_do_you_want_to_start: "How do you want to start?",
  "demo_project.title": "Deploy {appName} Demo",
  "demo_project.description":
    "Explore {appName}'s core features with the {appName} Demo App.",
  "demo_project.create": "Get started",
  "create_from_scratch.title": "Deploy from a local Git repository",
  "create_from_scratch.description":
    "Manually push your code to {appName}. Integrate GitHub or others for CI/CD later.",
  "create_from_scratch.create": "Get started",
  "migrate.title": "Sync your GitHub repository with {appName}",
  "migrate.description":
    "Automatically deploy to {appName} from GitHub pull requests and branches.",
  "migrate.create": "Get started",
  "kickstart.title": "Kickstart a new repository",
  "kickstart.description":
    "Manually push your code to {appName}, and integrate your Git provider",
  "kickstart.create": "Get started",
  "create_first_organization.subtitle": "Welcome to {appName}!",
  "create_first_organization.title": "Create your organization",
  "create_first_organization.details":
    "Your organization is the workspace where your projects will live. Expecting to join your team’s existing organization? Ask an organization admin to send you an invite.",
  "create_first_organization.name.info":
    "Organizations own projects. You may choose to create a personal organization for your projects, or one for your companies projects.",
  "create_first_organization.path.info":
    "This is the unique url path to your organization’s projects.",
  "continue_on_desktop.title": "Let’s get started",
  "continue_on_desktop.subtitle": "Continue on desktop",
  "continue_on_desktop.description":
    "For the full {app_name} experience, jump over to {link} on your desktop to continue working with your terminal (or CLI).",
  git_clone: "git clone",
  "onboarding.setup.create_project_organization": "{app_name} organization",
  "onboarding.setup.brew": "Homebrew",
  "onboarding.setup.shell": "Shell script",
  "onboarding.setup.scoop": "Scoop",
  "onboarding.setup.install": "Install CLI",
  "onboarding.setup.title": "Deploy to {app_name}",
  "onboarding.setup.cli.title": "Prepare project locally",
  "onboarding.setup.access_project.title": "Access your project",
  "onboarding.setup.access_project.description":
    "Your project is ready and has been deployed to this URL:",
  "onboarding.setup.config.title": "Add {app_name} configuration",
  "onboarding.setup.config.description.migrate":
    "Your project is now integrated to your Git repository. Let’s configure and validate your project configuration files. All commands should be run within your local project directory.",
  "onboarding.setup.config.description":
    "Let’s configure and validate your project configuration files. All commands should be run within your local project directory.",
  "onboarding.setup.cli.description":
    "Follow the steps below to get your project up and running.",
  "onboarding.setup.cli.brew": "Homebrew",
  "onboarding.setup.cli.shellScript": "Shell script",
  "onboarding.setup.cli.scoop": "Scoop",
  "onboarding.setup.cli.later_configuration":
    "<link>Skip this step</link> to configure your project later",
  "onboarding.setup.cli.skip": "Skip these steps",
  "onboarding.setup.migrate.create_branch.title": "Create {app_name} branch",
  "onboarding.setup.migrate.create_branch.code":
    "git checkout -b {cli_command}-deployment",
  "onboarding.setup.migrate.create_config.title":
    "Create {app_name} configuration",
  "onboarding.setup.migrate.create_config.code": "{cli_command} project:init",
  "onboarding.setup.migrate.create_config.dialog.title":
    "Create {app_name} configuration",
  "onboarding.setup.migrate.create_config.dialog":
    "The files created here instruct {app_name} on how to deploy your app.",
  "onboarding.setup.migrate.commit_config.title":
    "Commit {app_name} configuration",
  "onboarding.setup.migrate.commit_config.code":
    "git add {config_dir_name} \ngit commit -m 'Add {app_name} configuration files'",
  "onboarding.setup.migrate.commit_config.dialog.title":
    "Commit {app_name} configuration",
  "onboarding.setup.migrate.commit_config.dialog":
    "Make your app architecture available to {app_name}.",
  "onboarding.setup.migrate.deploy.title":
    "Push your changes to your new GitHub branch",
  "onboarding.setup.migrate.deploy.dialog.title":
    "Push your changes to your new Github branch",
  "onboarding.setup.migrate.deploy.dialog":
    "This branch will be created as an environment on {app_name}",
  "onboarding.setup.migrate.deploy.code":
    "git push --set-upstream origin {cli_command}-deployment",
  "onboarding.setup.migrate.activate.title":
    "Activate the {app_name} environment",
  "onboarding.setup.migrate.activate.dialog.title":
    "Activate the {app_name} environment",
  "onboarding.setup.migrate.activate.dialog":
    "The GitHub branches that you create are available as {app_name} production, staging, and test environments",
  "onboarding.setup.migrate.activate.code":
    "{cli_command} environment:activate",
  "onboarding.setup.migrate.install.title":
    "Install {app_name} CLI using Homebrew (MacOS and Linux)",
  "onboarding.setup.migrate.install.footnote":
    "If not using Homebrew or on Windows, refer to the <a>CLI documentation</a>.",
  "onboarding.setup.migrate.install.code":
    "brew install platform/tap/platform-cli",
  "onboarding.setup.migrate.clone.title":
    "If you haven’t already, clone repository locally",
  "onboarding.setup.migrate.clone.code": "{url} \n&& cd {directory}",
  "onboarding.setup.migrate.connect.title": "Connect directory to {app_name}",
  "onboarding.setup.migrate.connect.code":
    "{cli_command} project:set-remote {projectId}",
  "onboarding.setup.migrate.configure.title": "Configure your project",
  "onboarding.setup.migrate.configure.code": "{cli_command} project:init",
  "onboarding.setup.migrate.commit.title":
    "Commit your files and deploy your application to {app_name}",
  "onboarding.setup.migrate.commit.code":
    "git add .\ngit commit -m 'Add {app_name} configuration files'\n{cli_command} push\n{cli_command} activity:log",
  "onboarding.setup.scratch.create_config.dialog.title":
    "Create {app_name} configuration",
  "onboarding.setup.scratch.create_config.dialog":
    "The files created here instruct {app_name} on how to deploy your app.",
  "onboarding.setup.scratch.clone.title":
    "If you haven’t already, clone your repository locally",
  "onboarding.setup.scratch.clone.code":
    "cd '{your local app repository directory}'",
  "onboarding.setup.scratch.clone.dialog.title": "Clone repository locally",
  "onboarding.setup.scratch.clone.dialog":
    "To configure your project for {app_name}, you'll need access to it on your machine.",
  "onboarding.setup.scratch.install.title":
    "Install {app_name} CLI using Homebrew (MacOS and Linux)",
  "onboarding.setup.scratch.install.footnote":
    "If not using Homebrew or on Windows, refer to the <a>CLI documentation</a>.",
  "onboarding.setup.scratch.install.code":
    "brew install platform/tap/platform-cli",
  "onboarding.setup.scratch.init.title":
    "Initialize your git repository (Optional)",
  "onboarding.setup.scratch.init.code": "git init",
  "onboarding.setup.scratch.create_config.title":
    "Create {app_name} configuration",
  "onboarding.setup.scratch.create_config.code": "{cli_command} project:init",
  "onboarding.setup.scratch.deploy.title": "Deploy your changes to {app_name}",
  "onboarding.setup.scratch.deploy.code": "{cli_command} push",
  "onboarding.setup.scratch.commit_config.title":
    "Commit {app_name} configuration",
  "onboarding.setup.scratch.commit_config.code":
    "git add {config_dir_name}  \ngit commit -m 'Add {app_name} configuration files'",
  "onboarding.setup.scratch.deploy.dialog.title": "Deploy application",
  "onboarding.setup.scratch.deploy.dialog":
    "Deploy your version-controlled infrastructure to {app_name} using: <code>{cli_command} push --activate</code>  <br></br>This is similar to a git push, but also activates your environment.",
  "onboarding.setup.scratch.commit_config.dialog.title":
    "Commit {app_name} configuration",
  "onboarding.setup.scratch.commit_config.dialog":
    "Make your app architecture available to {app_name}.",
  "onboarding.setup.scratch.connect.title": "Connect directory to {app_name}",
  "onboarding.setup.scratch.connect.code":
    "{cli_command} project:set-remote {projectId}",
  "onboarding.setup.scratch.configure.title": "Configure your project",
  "onboarding.setup.scratch.configure.code": "{cli_command} project:init",
  "onboarding.setup.scratch.commit.title":
    "Commit your files and deploy your application to {app_name}",
  "onboarding.setup.scratch.commit.code":
    "git add .\ngit commit -m 'Add {app_name} configuration files'\n{cli_command} push",
  "onboarding.setup.demo.deployed.title": "Your demo project has been deployed",
  "onboarding.setup.demo.deployed.description":
    "Open the demo guide to start exploring {app_name} features by visiting your project’s public URL",
  "onboarding.setup.demo.configure.title": "Create {app_name} configuration",
  "onboarding.setup.demo.configure.code": "{cli_command} project:init",
  "onboarding.setup.demo.commit.title": "Commit {app_name} configuration",
  "onboarding.setup.demo.commit.code":
    "{cli_command} add {config_dir_name} \n'git commit -m 'Add {app_name} configuration files'",
  "onboarding.setup.demo.install.title":
    "Install {app_name} CLI locally using Homebrew (MacOS and Linux)",
  "onboarding.setup.demo.install.footnote":
    "If not using Homebrew or on Windows, refer to the <a>CLI documentation</a>.",
  "onboarding.setup.demo.install.code.brew":
    "brew install platform/tap/platform-cli",
  "onboarding.setup.demo.install.code.shell":
    "curl -fsSL https://raw.githubusercontent.com/platformsh/cli/main/installer.sh | VENDOR={cli_command} bash",
  "onboarding.setup.demo.install.code.scoop":
    "scoop bucket add platformsh https://github.com/platformsh/homebrew-tap.git scoop install platform",
  "onboarding.setup.demo.clone.title": "Clone repository locally",
  "onboarding.setup.demo.clone.code":
    "git clone https://github.com/platformsh/demo-project.git \n{cli_name}-demo && cd {cli_name}-demo",
  "onboarding.setup.demo.connect.title": "Connect directory to {app_name}",
  "onboarding.setup.demo.connect.code":
    "{cli_command} project:set-remote {projectId}",
  "onboarding.setup.demo.connect.dialog.title":
    "Connect directory to {app_name}",
  "onboarding.setup.demo.connect.dialog":
    "Associate your local repository with your  {app_name} project to enable <code>{cli_command} push</code>, <code>{cli_command} db:dumb</code> and other powerful CLI commands.",
  "onboarding.setup.demo.deploy.title": "Deploy your changes to {app_name}",
  "onboarding.setup.demo.deploy.code": "{cli_command} push",
  "onboarding.setup.demo.create_branch.title":
    "Connect directory to {app_name}",
  "onboarding.setup.demo.create_branch.code":
    "{cli_command} project:set-remote {projectId}",
  "onboarding.setup.demo.create_branch.dialog.title":
    "Create {app_name} branch",
  "onboarding.setup.demo.create_branch.dialog":
    "A dedicated branch allows you to explore {app_name} without impacting your existing production and staging branches.",
  "onboarding.setup.waiting_for_project": "Waiting for project information...",
  "onboarding.setup.clone.dialog":
    "Setup your project locally to stage changes and prepare your {app_name} deployments.",
  "onboarding.setup.connect.dialog.title": "Connect directory to {app_name}",
  "onboarding.setup.connect.dialog":
    "Associate your local repository with your {app_name} project to enable <code>{cli_command} push</code>, <code>{cli_command} db:dump</code> and other powerful CLI commands.",
  "onboarding.setup.install.dialog.title": "Install CLI",
  "onboarding.setup.install.dialog":
    "Manage your {app_name} projects directly from your terminal. Anything you can do within the Console can be done with the CLI.",
  "onboarding.setup.clone.dialog.title": "Clone repository locally",
  "onboarding.setup.configure.dialog":
    "Prepare your {app_name} infrastructure to be version-controlled.",
  "onboarding.setup.commit.dialog":
    "Commit & deploy your newly version-controlled infrastructure to {app_name}.",
  "onboarding.setup.is_being_created.demo":
    "Creating a project for your demo app...",
  "onboarding.setup.is_being_created.migrate":
    "Your project is being created from your <br></br>{repository} GitHub repository.",
  "onboarding.setup.is_being_created.scratch":
    "A project is being created to host your application.",
  "onboarding.setup.is_waiting_for_push.created":
    "Your project has been created.",
  "onboarding.setup.is_waiting_for_push.push":
    "Waiting for your first successful git push.",
  "onboarding.setup.is_deploying": "Your project is deploying...",
  "onboarding.setup.is_waiting_for_push.error":
    "Congrats, your project is connected. Now let’s ensure your project is configured for {app_name}.<br></br>Use <code>{cli_command_name} project:init</code> to create a new {app_name} configuration, or <code>{cli_command_name} validate</code> to check your existing configuration.",
  "onboarding.setup.is_waiting_for_push.error.help":
    "You can find out more detail on the error by checking the deployment logs below.",
  "onboarding.setup.finished.title.start": "Your project is ready!",
  "onboarding.setup.finished.description":
    "Your project has been built using the default resource sizes for your application and service containers.",
  "onboarding.setup.resources.updating": "Updating resources..",
  "onboarding.setup.done.title": "Explore your application",
  "onboarding.setup.finished.title.end": "Explore your app",
  "onboarding.setup.finished.demo.detail":
    "Launch the demo guide to start exploring {app_name}.",
  "onboarding.setup.done.demo.title": "Congrats! Your demo project is ready!",
  "onboarding.setup.done.open": "Open demo app",
  "onboarding.setup.done.demo.description":
    "Launch the demo guide to start exploring {app_name} features",
  "onboarding.setup.done.demo.code": "{cli_command} url --primary",
  "onboarding.setup.done.demo.tooltip":
    "Please copy the `{cli_command} demo:start` command before proceeding",
  "onboarding.setup.done.manage": "Manage project",
  "onboarding.setup.done.view": "View project",
  "onboarding.setup.done.view.tooltip":
    "First, click the public URL above to launch the demo app",
  "onboarding.setup.done.configure": "Resize apps and services",
  "onboarding.setup.error.provisioningFailure":
    "Sorry! We had a technical glitch and couldn’t create {project_name}. Please start again by creating a new project.",
  "onboarding.setup.error.provisioningFailure.create": "Create new project",
  "onboarding.setup.error.project_creation.title":
    "Sorry something went wrong on our side.",
  "onboarding.setup.error.project_creation.button": "Retry",
  "onboarding.setup.error.deployment.title": "Sorry something went wrong.",
  "onboarding.setup.error.deployment.button": "Manage project",
  "onboarding.setup.tip.1":
    "Configure your app and service container CPU, RAM and Disk storage how you like.",
  "onboarding.setup.tip.2":
    "Scale your applications horizontally by increasing the number of instances.",
  "onboarding.setup.tip.3":
    "Create new teams and grant permissions to manage all of your contributors at once.",
  "onboarding.setup.tip.4":
    "Did you know you can integrate with GitHub, GitLab and Bitbucket?",
  "onboarding.migrate.title": "Migrate your project",
  "onboarding.migrate.note": "Where is your code hosted?",
  "onboarding.migrate.permissions.notice":
    "Once authenticated, {app_name} will be able to:",
  "onboarding.migrate.permissions.1":
    "Fetch Git branches from GitHub into {app_name}.",
  "onboarding.migrate.permissions.2":
    "Pull requests on GitHub will create an {app_name} preview environment.",
  "onboarding.migrate.permissions.3":
    "GitHub pull request changes will redeploy {app_name} preview environments.",
  "onboarding.migrate.permissions.4":
    "Deleting GitHub branches will remove {app_name} environments.",
  "onboarding.migrate.github.connect": "Connect with GitHub",
  "onboarding.migrate.github.create": "Create with GitHub",
  "onboarding.migrate.gitlab": "Coming soon",
  "onboarding.migrate.bitbucket": "Coming soon",
  "onboarding.repository.title": "Create project from repository",
  "onboarding.repository.organization": "GitHub organization",
  "onboarding.repository.repository": "GitHub repository",
  "onboarding.repository.select_repository": "Select repository",
  "onboarding.repository.note":
    "If your organization or repository is not displayed <link>update your GitHub permissions</link>.",
  "onboarding.resources.count": "Your resources{resourceCount}",
  "onboarding.resource.values": "{cpu}{ram}{disk}",
  "project.setup.tips.info": "Let’s launch your {app_name} demo project",
  "project.setup.tips.description":
    "Follow these steps to start exploring {app_name} features.",
  "project.setup.tips.install_cli": "Install the CLI",
  "project.setup.tips.clone": "Clone and access your project",
  "project.setup.tips.start_project": "Start your demo project",
  "project.setup.tips.push": "Push your code to {app_name}",
  "project.setup.tips.steps":
    "Follow these steps to get started and deploy your code to {app_name}.",
  "project.setup.tips.connect": "Connect your local project to {app_name}",
  "project.setup.tips.connect_command":
    "{cli_command} project:set-remote {projectId}",
  "project.setup.tips.deploy": "Deploy to {app_name}",
  "project.setup.tips.deploy.demo_command": "{cli_command} push",
  "project.setup.tips.launch": "Launch Demo Guide (open project in browser)",
  "project.setup.tips.launch_command": "{cli_command} url --primary",
  "project.setup.tips.configurations":
    "Learn more about project configurations",
  "project.setup.tips.deploy_commands":
    '{cli_command} project:init\ngit commit -m "Add {app_name} configuration"\n{cli_command} push',
  "project.setup.tips.clone_command":
    "git clone https://github.com/platformsh/demo-project.git upsun-demo\ncd upsun-demo",
  "project.setup.tips.connect_repo": "Connect directory to {app_name}",
  "project.setup.tips.connect_repo.command":
    "{cli_command} project:set-remote {projectId}",
  "project.setup.demo_start": "{cli_command} demo:start",
  "project.setup.cli.install.shell":
    "curl -fsSL https://raw.githubusercontent.com/platformsh/cli/main/installer.sh | VENDOR={cli_command} bash",
  "project.setup.cli.install.brew":
    "brew install platformsh/tap/platformsh-cli",
  "project.setup.cli.install.scoop":
    "scoop bucket add platformsh https://github.com/platformsh/homebrew-tap.git scoop install platform",
  login_message:
    "By logging in, including with my linked account or my Platform.sh <br></br> account, I agree to the",
  "subscribe.stepper.review": "Review",
  "subscribe.stepper.billingDetails": "Billing details",
  "subscribe.review.title": "Upgrade from trial",
  "subscribe.review.descriptionActive":
    "To continue to use {app_name}, upgrade now. You can manage your organization and projects billing at any time via your settings. The cost is estimated.³",
  "subscribe.review.descriptionExpired":
    "To continue to use {app_name}, upgrade now. You can manage your organization and projects billing at any time via your settings. The cost is estimated.³",
  "subscribe.review.organization": "Organization",
  "subscribe.review.projects": "Projects",
  "subscribe.review.users": "Users",
  "subscribe.review.support": "Standard Support ²",
  "subscribe.review.teams": "Teams",
  "subscribe.review.monthly": "/month ³",
  "subscribe.review.cancel": "Cancel",
  "subscribe.review.addBillingDetails": "Add billing details",
  "subscribe.review.monthlyEstimate": "Monthly total estimate³",
  "subscribe.review.userLicenses": "User licenses",
  "subscribe.review.usersWithCount":
    "{count} {count, plural, one{user} other{users}}",
  "subscribe.review.userViewers": "Viewers",
  "subscribe.review.terms.note_1":
    "Note ¹ : The discount provided is based on prorated periods with existing active projects and excludes VAT. If the discount applied to your total bill exceeds the amount of your bill, you won’t be entitled to receive the difference in cash or credit. If you abuse this offer in any way, it will be rescinded immediately. By participating in this offer, you agree to be bound by the current terms and conditions as well as any future amendments. Upsun reserves the right to change or amend the terms of this offer at any time without prior notice. All changes will be effective immediately upon posting on our website or through direct communication.",
  "subscribe.review.terms.note_2":
    "Note ² : Standard support fee is 10% global spend of the organization.",
  "subscribe.review.terms.note_3":
    "Note ³ : These costs are estimates only and are based on the services currently activated on your account. Any modifications to your account may impact your monthly charges. You can view and manage your project billing in your Organization billing page. Learn more by visiting our <link>pricing page</link>.",
  "subscribe.review.terms.supportFee":
    "** Standard support fee is 10% global spend of the organization",
  "subscribe.review.free": "Free",
  "subscribe.review.notrial":
    "The organization <b>{organization}</b> has no active trials.",
  "subscribe.billingDetails.title": "Billing details",
  "subscribe.billingDetails.cancel": "Cancel",
  "subscribe.billingDetails.subscribe": "Subscribe",
  "subscribe.billingDetails.upcomingInvoice": "Upcoming invoice",
  "subscribe.billingDetails.estimatedInvoice": "Estimated invoice",
  "subscribe.billingDetails.thanks.title": "Thanks for subscribing!",
  "subscribe.billingDetails.thanks.description":
    "You’ll be redirected to your projects page shortly - <a>click here</a> if that doesn’t work.",
  "setup.details.footer_note":
    "After the trial, if you subscribe to Upsun, your project will be billed by hourly prorated usage each month.",
  your_projects: "Your projects",
  "trial_dialog.create_project":
    "Create a project to get the most out of your free trial.",
  "trial_dialog.resources_limit":
    "Your free trial includes {projects, plural, =0 {} one {# project, } other {# projects, }}{environments, plural, =0 {} one {# environement, } other {# environments, }}{cpu} CPU, {memory}GB RAM and {storage}GB Disk.",
  "trial.dialog.sellables.user_management_disabled":
    "Ask your admin to enable user management add-on",
  "trial_dialog.sellables":
    "<linkUserManagement>User management</linkUserManagement> and <linkContinuousProfiling>Continuous profiling</linkContinuousProfiling> are free during your free trial",
  "trial_dialog.remaining_days":
    "{count, plural, =0 {# Day} one {# Day} other {# Days}} left",
  "trial_dialog.remaining_environments": "{used}/{limit} environment used",
  "trial_dialog.project_being_created": "Project being created",
  "trial_dialog.consumption": "Resource consumption",
  "trial_dialog.more_time": "Need more time? Reach out to <a>support</a>",
  no_options: "No options",
  "http.bandwidth.info":
    "Total bandwidth Your total bandwidth is all of the bandwidth used in the timeframe selected.",
  bandwidth: "Bandwidth",
  total_bandwidth: "Total bandwidth",
  http_request: "HTTP requests",
  http_traffic: "HTTP traffic",
  "cdn_traffic_sticker.billing":
    "Please note that CDN traffic is not reflected on this page, only origin traffic. You can find CDN Bandwidth and Requests totals on the <link>project billing page</link>.",
  "cdn_traffic_sticker.overview":
    "You can find CDN Bandwidth and Request totals on the <link>Project Overview</link>",
  observability_sandbox: "Obs. Sandbox",
  "http.status_codes": "Status codes",
  "http.top_count_impact_url": "Top {count} URL Impact",
  "http.top_timestamp_impact_url": "Top {count} URL timestamp Impact",
  "http.url_impact": "URL impact",
  "http.url_impact.info":
    "Impact is the percentage of all requests that come from this URL.",
  "http.requests": "Requests",
  "http.response_time.avg": "Response time (Average)",
  "http.response_time.p95": "Response time (P95)",
  "http.method": "Method",
  "http.impact": "Impact",
  "http.no_impact_url": "No URLS available",
  server_caches: "Server caches",
  "server_caches.info":
    "The data points on this graph are based on Extended Traces.",
  "1XX_information": "1XX Information",
  "2XX_success": "2XX Success",
  "3XX_redirect": "3XX Redirect",
  "4XX_client_error": "4XX Client error",
  "5XX_server_error": "5XX Server error",
  no_data: "No data available.",
  outgoing: "Outgoing",
  incoming: "Incoming",
  enable_continuous_profiling: "Enable continuous profiling",
  view_profiling: "View profiling",
  profiling_not_supported: "Profiling not supported",
  service: "Services",
  profiling: "Profiling",
  services_profiling_not_supported: "Services not supported",
  services_profiling_not_supported_description:
    "Services do not currently support profiling.",
  service_not_supported: "Not yet supported",
  service_not_supported_description:
    "Application support is being added as quickly as possible. Check back later to see if your application is supported.",
  deployment_service_load_error:
    "There was error loading your deployments deployments",
  "profiling.blackfire_info":
    "Blackfire is a Platform.sh product that supports PHP and Python projects. It is included in your subscription, so you can use Blackfire.io to monitor, profile, and test your application.",
  "learn_more_at_blackfire.io": "Learn more about Blackfire.io",
  services_profiling_error: "We encountered an error",
  services_profiling_error_info_description:
    "We've encountered an error while attempting to retrieve information about this service. Please contact If this issue persists",
  services_profiling_error_info_title: "Application Service Loading Error",
  profiling_not_enabled: "Profiling not enabled",
  profiling_enable_info_description:
    "For more information on how to enable continuous profiling for Node.js and Go applications, read our documentation",
  contact_us: "Contact us",
  instances: "instances",
  average: "Average",
  terms_and_acceptance_checkbox:
    "I accept the <TermsLink>Terms of Service</TermsLink>, the <AUPLink>AUP</AUPLink> and <PrivacyLink>Privacy Policy</PrivacyLink>",
  marketing_acceptance_checkbox:
    "Stay up to date with {app_name} news and offers",
  "login_with_platformsh_account.terms_tooltip":
    "Please accept the terms before you sign up",
  your_activity: "Your activity",
  project_setting: "Project settings",
  project_access: "Project access",
  "billing.invoice.support": "Support",
  "billing.invoice.project_discount": "discount",
  "billing.invoice.project_green_discount_description":
    "3% of CPU, memory, and build resources",
  "billing.invoice.project_subtotal": "{projectName} subtotal",
  "billing.invoice.project_total": "{projectName} total",
  "billing.invoice.projects": "Projects",
  "billing.invoice.organization": "Organization",
  "billing.invoice.invoice_number": "Invoice number",
  "billing.invoice.duration": "Duration",
  "billing.invoice.total": "Total",
  "billing.invoice.estimated_total": "Estimated total",
  "billing.details.payment_method.note":
    "Add your address before adding your payment method",
  "payment_elements.description":
    "Only one payment method can be saved to your account at any time.",
  "payment_elements.saved.description": "Your current payment method",
  "payment_elements.checkbox":
    "I agree to pay my monthly usage fees. I understand I can change plans and add options through the month which may incur additional fees.",
  "payment_elements.note":
    "After submission, you will be redirected to securely complete next steps.",
  "payment_elements.loading_note":
    "You are redirecting to securely complete next steps.",
  "payment_elements.address_disclaimer":
    "*Add your address before adding your payment method",
  "payment_elements.credit-card": "Credit card",
  "payment_elements.expiration": "Expiration",
  "payment_elements.mandate": "Mandate reference",
  "payment_elements.update": "Update",
  "payment_elements.escalate_credit_card":
    "Use my credit card saved from identity verification",
  "green_discount.title": "Green discount",
  "green_discount.description":
    "Projects deployed on carbon-efficient regions receive at 3% discount on CPU, memory, and build resources.",
  "onboarding.follow_steps": "Next, follow the steps on the left.",
  detele_account: "Delete account",
  "modal.delete_account.title": "Delete account",
  "modal.delete_account.descritpion1":
    "Deleting your Platform.sh account automatically deletes all your linked Upsun, Ibexa Cloud, Pimcore PaaS, and Shopware PaaS accounts.",
  "modal.delete_account.descritpion2":
    "Our support team will begin to process your account deletion shortly. If you proceed, you’ll be redirected next to our support page to track the status of your request. To minimize additional accrued charges while your deletion request is pending, please delete any active projects on your accounts.",
  "modal.delete_account.sticker":
    "Important note: If you want to transfer the ownership of any organization to a new owner, please submit a new ticket prior to deleting your account.",
  "modal.delete_account.submit_request": "Submit request",
  "delete_account.description":
    "Please delete my account and associated personal information including all organizations, projects and backups owned by my account.",
  "delete_account.subject": "Delete my account",
  "onboarding.setup.access_project.not_ready":
    "Your project has been created and is ready to host your application! Follow the steps above to get started.",
  "onboarding.setup.access_project.not_ready.links.title":
    "Here are some useful links for you to explore:",
  "onboarding.setup.access_project.links.title.configure_app":
    "Configure your app to use {app_name}",
  "onboarding.setup.access_project.links.title.create_service":
    "Create a service (Redis, PostgreSQL)",
  "onboarding.setup.access_project.links.title.manage_variables":
    "Manage environment variables",
  "onboarding.setup.access_project.links.title.manage_directories":
    "Manage writable directories (Mounts)",
  "onboarding.setup.access_project.links.title.manage_hooks":
    "Manage build hooks",
  "onboarding.setup.access_project.links.learn_more":
    "Learn more about what you can do in {app_name}",
  plan_environment_confirmation:
    "The selected plan has different options for environments. Please confirm the new number of environments and cost differences before saving.",
  "onboarding.migrate.error.invalid_state":
    "GitHub validation error: Please try connecting again.",
  "onboarding.migrate.error.exchange_failed":
    "GitHub token exchange failed: Please check your credentials and retry.",
  "onboarding.migrate.error.duplicate_installation":
    "That GitHub organization is already associated with another Upsun organization.",
  "onboarding.migrate.error.invalid_installation":
    "GitHub rejected the connection request. Please contact your GitHub repository admin. <link>Learn more</link>",
  "create_project_button.disabled.tooltip":
    "Contact your admin for permission to create projects.",
  "create_project_button.restricted_org.title": "Inactive organization",
  "create_project_button.restricted_org.member.description":
    "You cannot create projects at this time because your organization is inactive.",
  "create_project_button.restricted_org.member.disclaimer":
    "Contact your organization admin to resolve this issue.",
  "create_project_button.restricted_org.admin.description":
    "You cannot create projects at this time because your organization is inactive.",
  "create_project_button.restricted_org.contact_support": "Contact support",
  "create_project_button.trial_limit_reached.title":
    "Your trial is limited to one project",
  "create_project_button.trial_limit_reached.description":
    "To create another project, please delete your existing one or subscribe to {app_name} today to create more projects.",
  "create_project_button.trial_limit_reached.cancel": "Cancel",
  "create_project_button.trial_limit_reached.subscribe": "Subscribe",
  "create_project_button.generic.title": "You can not create a project",
  "create_project_button.create_first_organization.title":
    "Create an organization to get started!",
  "create_project_button.create_first_organization.description":
    "To manage projects, billing, and more, you must first create an organization. Organizations may be used for personal projects or for your company. Create your first organization to enable projects, teams, and more!",
  "create_project_button.create_first_organization.cta": "Create organization",
  total_usage: "Total Usage",
  group_by_type: "Group by type",
  show: "Show",
  allocation: "Allocation",
  cpu_resource_allocation_info:
    "The total amount of CPU allocated to all the application, service and worker containers on this environment",
  ram_resource_allocation_info:
    "The total amount of RAM (Memory) allocated to all the application, service and worker containers on this environment",
  disk_resource_allocation_info:
    "The total amount of the persistent writable mounts allocated to all the application, service and worker containers on this environment",
  total_allocation: "Total Allocation",
  max: "Max",
  "member.security.mfa.setup.title": "MFA application setup",
  "member.security.mfa.setup.recoverycodes": "Save recovery codes",
  "member.security.mfa.setup.recoverycodes.copy":
    "Copy these recovery codes and keep them somewhere safe. These can be used as a fallback method if you ever need to log in without your device.",
  "member.security.mfa.setup.recoverycodes.loginnote":
    "After registering your authenticator app, you will need to log in again to complete the set up.",
  "member.security.mfa.setup.recoverycodes.continue": "Continue",
  "organization.security.sellable.title": "Upgrade user management features",
  "organization.security.sellable.description":
    "Upgrade today to enhance your user's collaboration with flexible permissions and teams, and strengthen your organization's security by enforcing MFA.",
  "organization.security.sellable.enable": "Enable",
  "organization.security.sellable.learnmore": "Learn more",
  "organizations.security.title": "Security",
  "organizations.security.mfa.description":
    "Multi-factor authentication (MFA) provides additional security for your organization. You can enforce users within your organization to enable MFA on their account.",
  "organizations.security.mfa.enabled": "MFA Enabled",
  "organizations.security.sso.enabled": "SSO Enabled",
  "organizations.security.mfa.disabled": "Disabled",
  "organizations.security.mfa.enable.title": "MFA required",
  "organizations.security.mfa.enable.description":
    "All users within your organization will be required to have MFA enabled on their account. Users will not be able to access projects within your organization until they enable MFA.",
  "organizations.security.mfa.enable.enablefirst":
    "To enable this setting, you must <a>set up MFA</a> on your account first.",
  "organizations.security.mfa.enable.action.enable": "Enable MFA",
  "organizations.security.mfa.enable.success.title": "MFA successfully enabled",
  "organizations.security.mfa.disable.success.title":
    "MFA successfully disabled",
  "organizations.security.mfa.remind.success.title": "Reminder sent",
  "organizations.security.mfa.remind.success.description.multiple":
    "An email reminder has been sent to enable MFA.",
  "organizations.security.mfa.remind.success.description.single":
    "An email reminder has been sent to {userName} to enable MFA.",
  "organizations.security.mfa.remind.error.title":
    "An error occurred while sending the reminder",
  "organizations.security.mfa.users.title": "User security settings",
  "organizations.security.mfa.users.header.name": "Name",
  "organizations.security.mfa.users.header.email": "Email",
  "organizations.security.mfa.users.header.mfastatus": "MFA status",
  "organizations.security.mfa.users.header.lastlogin": "Last login",
  "organizations.security.mfa.users.view": "View user",
  "organizations.security.mfa.users.remind": "Remind",
  "organizations.security.mfa.users.remind.disabled":
    "MFA enforcement is not enabled for the organization",
  "organizations.security.mfa.users.selected": "{count} selected",
  "organization.member.mfastatus.mfaenabled": "MFA enabled",
  "organization.member.mfastatus.mfadisabled": "MFA disabled",
  "organization.member.mfastatus.ssoenabled": "SSO enabled",
  "auth.401.title": "Re-authentication required",
  "auth.401.title.recent_auth": "Re-authentication required",
  "auth.401.title.org_sso": "SSO required",
  "auth.401.recent_auth":
    "For security purposes, you will be redirected to verify your password.",
  "auth.401.org_sso":
    "For security purposes, you will be redirected to set up SSO.",
  "auth.401.mfa":
    "For security purposes, you will be redirected to set up MFA.",
  "organizations.security.menu": "Security",
  "breadcrumb.organization.settings.security": "Security",
  "add_on.success_message":
    "This add-on was successfully enabled and will be reflected on your current month projection within the next hour.",
  "add_on.continous_profiling_enabled":
    "Continuous profiling add-on was enabled",
  "add_on.continous_profiling_add_on": "Continuous profiling add-on",
  "add_on.continous_profiling_enable_description":
    "Enable to retain continuous profiling data for 30 days.",
  "add_on.continous_profile_retain_message":
    "Retain 30 days of continuous profiling data.",
  request_admin_enable: "Ask your admin to enable.",
  free_during_trial: "Free during trial",
  "organization.project.access.invitemember":
    "To invite a new user to your organization, go to your <a>user management page</a>.",
  "organization.project.access.invitemember.member": "User",
  "profiling.no_services": "No apps or services",
  "profiling.no_services.display.description":
    "To view continuous profiling data, first add an application or service.",
  "profiling.no_services.display.description.link":
    "<a>Learn more about configuring apps and services.</a>",
  "billing.subscribe.description":
    "Enjoy the same, game-changing Upsun features for less. Introducing First Project Incentive.*",
  "billing.subscribe.title": "Subscribe to {app_name}",
  "onboarding.setup.access_project.scratch.provisioning":
    "Please wait a moment while your project is being provisioned...",
  "onboarding.setup.scratch.skip":
    "<a>Skip this step</a>, and finish setup later.",
  resources_not_found: "The resources could not be found",
  environment_deleted_or_inactive:
    "We couldn't find the environment you're looking for. It might have been deleted or doesn't exist. Please try refreshing your browser.",
  something_went_wrong: "Something went wrong...",
  "organizations.security.sso.required.title": "Single Sign-On (SSO) required",
  "organizations.security.sso.required.description":
    "SSO has been enabled on <strong>{name}</strong> and you are required to log in again through SSO to access this organization.",
  "organizations.security.sso.required.button": "Log in with SSO",
  "organizations.security.mfa.required.title":
    "Multi-Factor Authentication required",
  "organizations.security.mfa.quired.description":
    "The organization <strong>{name}</strong> requires you to have Multi-Factor Authentication (MFA) enabled on your account. MFA provides additional security for your account. With MFA enabled, you log in to the site with a verification code in addition to your username and password.",
  "organizations.security.mfa.required.button": "Set up application",
  "Organizations.security.mfa.setup.actions.cancel": "Cancel",
  "Organizations.security.mfa.setup.actions.save": "Verify & Save",
  "organizations.security.mfa.setup.download.title":
    "Download authenticator app",
  "organizations.security.mfa.setup.download.description":
    "You can enable MFA with these authenticator apps or one of your preference.",
  "organizations.security.mfa.setup.providers.info":
    "You can use any One Time Password (OTP) compatible app.",
  "organizations.security.mfa.setup.install.title": "Install authenticator app",
  "organizations.security.mfa.setup.install.description":
    "After registering your authenticator app, you will need to log in again to complete the set up.",
  "organizations.security.mfa.setup.install.scan.title":
    "Scan the QR code below",
  "organizations.security.mfa.setup.install.scan.label": "QR Code",
  "organizations.security.mfa.setup.install.or": "or",
  "organizations.security.mfa.setup.install.code.title":
    "Enter this code into your authenticator app",
  "organizations.security.mfa.setup.install.code.label":
    "Authenticator text code",
  "organizations.security.mfa.setup.install.verify.title":
    "Input verification code from your authenticator app.",
  "organizations.security.mfa.setup.install.verify.label": "Verification code",
  "organizations.security.mfa.setup.install.verify.placeholder":
    "Enter code here",
  upgrade_to_observability_suite_title: "Upgrade to Observability Suite",
  upgrade_to_observability_suite_description:
    "Unlock Continuous Profiling, longer timeframes for infrastructure metrics, Blackfire APM & Profiler, and logs forwarding",
  continuous_profiling_not_available: "Continuous profiling not available",
  "observability-suite_learnmore": "Learn more about the Observability Suite.",
  continuous_profiling_observability_suite:
    "Continuous profiling is a part of the Observability Suite",
  internal_only_tag: "Internal only",
  "activity.suspend.suspended": "suspended",
  "kyc_verification.phone.error":
    "There's an issue with this phone number, please check the numbers and try again",
  "menu.organization.invite_user": "Invite user",
  "menu.organization.select_org": "Select an organization to view settings",
  "organization.projects.header.name": "Project name",
  "organization.projects.header.organization": "Organization",
  "organization.projects.header.owner": "Owner",
  "organization.projects.header.region": "Region",
  "organization.projects.header.plan": "Plan",
  "organization.projects.header.id": "ID",
  user_settings: "User settings",
  "create_project_modal.title": "Create a project",
  "create_project_modal.description":
    "Continuous profiling is a project-based add-on. Please create a project to enable this feature.",
  "create_project_modal.button": "Create a project",
  "setup.sticker.details":
    "Almost there! Name your free trial project and select a region ({daysRemaining} days remaining).",
  "setup.sticker.deploy_to_upsun":
    "Congrats! Your new project has unlocked the full potential of Upsun.",
  "first_project_incentive.tag": "First Project Incentive: $19",
  "first_project_incentive.tag.indicator":
    "First Project Incentive: {allowanceDiscount} ¹",
  "billing.terms_conditions": "*<link>Terms and conditions apply</link>",
  deploy_your_application: "Deploy your application",
  "create_organization.trial_info.title": "Your free trial includes",
  "create_organization.trial_info.organization.label": "Organization",
  "create_organization.trial_info.organization.description": "1",
  "create_organization.trial_info.project.label": "Project",
  "create_organization.trial_info.project.description": "1",
  "create_organization.trial_info.users.label": "Users",
  "create_organization.trial_info.users.description": "Unlimited",
  "create_organization.trial_info.environments.label": "Running environments",
  "create_organization.trial_info.environments.description": "2",
  "create_organization.trial_info.containers.label": "Apps and services",
  "create_organization.trial_info.containers.description":
    "Unlimited (within the resources described below).",
  "create_organization.trial_info.resources.label": "Resources",
  "create_organization.trial_info.resources.description":
    "Up to 4.5 CPUs, 12 GB RAM, and 20GB disk running concurrently.",
  log_forwarding_billing_note:
    "Note: Log forwarding usage is billed based on outgoing bandwidth.",
  "banner.trial.create_project_page":
    "<billingIcon></billingIcon> Make the most of your free trial by creating a new project! ({daysRemaining} days remaining) <learnMore>Learn more</learnMore>",
  "visually_hidden.link_opens_in_new_window": "Opens a new window.",
  "ticket.general_support.enterprise_elite":
    "Enterprise and Elite SLAs only apply to Project support tickets, not to General support tickets.",
  "environment.not.redeployed":
    "Environment can not be redeployed until it is configured.",
  "dunning.update_billing": "Update billing",
  return_to_projects: "Return to projects",
  "requires_staff_verification.form.subject": "User verification",
  "requires_staff_verification.form.description":
    "I would like to be able to create projects but my user account or organization has been flagged as suspicious.\nI am a (…single developer/company) who would like to create (…Python, Flask, PHP, Django) projects.",
  source_repository_tooltip:
    "Merge is disabled as this project uses external version control. Please merge directly in your source repository and wait for the sync.",
  "automatic_org.loading": "// Upsun engine initializing",
  "environemnt.activity.deleted.tooltip": "This environment has been deleted."
};

export default en;
