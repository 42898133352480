import styled from "styled-components";

import { CloseButton, Link } from "ds/Button";
import * as Dropdown from "ds/Dropdown";
import Tag from "ds/Tag";
import * as Typography from "ds/Typography";

export const Trial = styled(Tag)`
  align-self: center;
  white-space: nowrap;
`;

export const Content = styled(Dropdown.Content)<{ shouldHide: boolean }>`
  padding: 32px;
  width: fit-content;
  max-width: 432px;
  border-radius: var(
    --section-border-radius-large-border-radius,
    var(--border-radius-large)
  );
  display: ${props => (props.shouldHide ? "none" : "block")};
`;

export const Header = styled.div`
  display: block;
  width: 100%;
  max-width: 368px;
  ${Typography.BodyText} + ${Typography.BodyText} {
    margin-top: 8px;
  }
`;

export const FreeTrialData = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 12px 0;
  border-radius: 8px;
  padding: 24px 16px;
  align-items: center;
  border: 1px solid
    var(--section-periwinkle-grey-border-color, var(--periwinkle-grey));
`;

export const Consumption = styled.div`
  display: flex;
  width: 100%;

  :not(:last-child) {
    padding-bottom: 8px;
  }
`;

export const ProjectTitle = styled(Typography.BodyText).attrs({
  size: "medium",
  weight: "semiBold"
})`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  white-space: nowrap;
  margin-right: 20px;
`;

export const ConsumptionLabel = styled(Typography.BodyText).attrs({
  size: "medium",
  weight: "semiBold"
})`
  flex: 1;
`;

export const ConsumptionValue = styled(Typography.BodyText).attrs({
  size: "small",
  weight: "semiBold"
})`
  span {
    opacity: 0.5;
  }
`;

export const Separator = styled.hr`
  margin: 24px -16px 16px -16px;
  border: none;
  height: 1px;
  background-color: var(
    --section-periwinkle-grey-background-color,
    var(--periwinkle-grey)
  );
`;
export type TrialTriggerProps = {
  $freeTrialDialogEnabled: boolean;
};

export const Trigger = styled(Dropdown.Trigger)<TrialTriggerProps>`
  cursor: ${props => (props.$freeTrialDialogEnabled ? "pointer" : "auto")};
  width: max-content;
  outline: none;
  transition: none;
  margin-top: 10px;
  height: 10px;
  display: flex;

  &:hover,
  &:focus {
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export const CloseDialog = styled(CloseButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const FreeTrialTitleButton = styled.div<{ $isColumn?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.$isColumn ? "column" : "row")};
  justify-content: space-between;
  width: 100%;
`;

export const FreeTrialRemaining = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FreeTrialTitle = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  width: 100%;
`;

export const LinkSupport = styled(Link)`
  font-weight: 600;
`;

export const CreateProject = styled(Typography.BodyText)`
  font-weight: 600;
`;

export const ProjectBeingCreatedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  margin-bottom: -8px;

  span {
    padding: 0;
  }
`;
