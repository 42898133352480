import { SettingsIcon as BaseSettingsIcon } from "@packages/ds";
import styled from "styled-components";

export const ActionButtons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  z-index: 1;
  justify-content: flex-start;
  gap: 8px;
`;

export const Wrapper = styled.div`
  #environment-actions-dropdown-window {
    width: max-content;
    .tooltip-content-wrapper {
      padding-top: 11px;
      padding-bottom: 12px;
    }
  }
`;

export const SettingsIconContainer = styled.button`
  cursor: pointer;
  height: 32px;
  width: 32px;
  margin-right: 0;
  margin-left: 12px;
  line-height: 46px;
  padding: 0;
  border: 0;
  background-color: transparent;
  border-radius: 2px;

  &:hover {
    background-color: var(
      --button-dark-tint-background-color,
      var(--dark-tint)
    );
  }
`;

export const SettingsIcon = styled(BaseSettingsIcon)`
  color: var(--mode-vector-neutral-default);
`;
