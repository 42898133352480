import React, { ReactNode } from "react";

import { ArtyBitsIcon, WarningIcon } from "@packages/ds";

import * as S from "./Banner.styles";

export enum Priority {
  critical = "critical",
  high = "high",
  information = "information",
  activity = "activity"
}

export type Props = {
  priority: keyof typeof Priority;
  text: ReactNode;
  closeBanner?: ReactNode;
  className?: string;
  includeIcons?: boolean;
};

const Banner = ({
  priority = Priority.information,
  text,
  closeBanner,
  className,
  includeIcons = true
}: Props) => {
  return (
    <S.Layout priority={priority} className={className}>
      {includeIcons &&
        (priority === Priority.information || priority === Priority.activity ? (
          <ArtyBitsIcon themeFill={"var(--icon-night-fill,var(--night))"} />
        ) : (
          <WarningIcon
            fill={`${
              priority === "critical"
                ? "var(--theme-neutral-0)"
                : "var(--theme-neutral-900)"
            }`}
          />
        ))}
      <S.TextContainer>{text}</S.TextContainer>
      {priority === Priority.information && <ArtyBitsIcon version="v2" />}
      <S.ButtonContainer priority={priority}>{closeBanner}</S.ButtonContainer>
    </S.Layout>
  );
};

export default Banner;
