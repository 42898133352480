import { SettingsIcon as BaseSettingsIcon } from "@packages/ds";
import styled from "styled-components";

import LiveBtn from "Components/LiveButton";

export const LiveButton = styled(LiveBtn)`
  margin: 0 5px 0 auto;
`;

export const SettingsIconContainer = styled.button`
  cursor: pointer;
  height: 32px;
  width: 32px;
  margin-right: 8px;
  margin-left: 12px;
  line-height: 46px;
  padding: 0;
  border: 0;
  background-color: transparent;
  border-radius: 2px;

  &:hover {
    background-color: var(
      --button-dark-tint-background-color,
      var(--dark-tint)
    );
  }
`;

export const SettingsIcon = styled(BaseSettingsIcon)`
  color: var(--mode-vector-neutral-default);
`;

export const NavigationRightItemsContainer = styled.div`
  display: flex;
`;
