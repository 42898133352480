import { HeartIcon } from "@packages/ds";
import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";

import { getTextColor } from "./util";

export const Layout = styled.footer<{ marginTop: number }>`
  font-size: 11px;
  color: ${getTextColor};
  opacity: ${props => (props.theme.name === "contrast" ? 1 : 0.5)};
  text-align: center;
  padding: 24px 0;
  margin-top: ${props => props.marginTop}px;
  ${semiBoldAlias()};
`;

export const WithIconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

export const Heart = styled(HeartIcon)`
  margin: 0 -5px 0 -5px;
`;
