import styled from "styled-components";

import * as Dropdown from "ds/Dropdown";

export const IconContainer = styled.div`
  padding-right: 8px;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: var(--mode-text-neutral-default-weak);
  &:hover,
  &:focus,
  &:active {
    color: var(--mode-text-primary-default);
  }
  width: 61px;
  cursor: pointer;
`;

export const HelpText = styled.p`
  font-size: 15px;
`;

export const DropdownTrigger = styled(Dropdown.Trigger)`
  color: var(--mode-overrides-header-text);
  margin-right: 4px;
  gap: 8px;
  &[data-state="open"] {
    color: var(--mode-text-primary-default);
  }
`;

export const DropdownSeparator = styled(Dropdown.Separator)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
