import React, { useEffect, useRef, useCallback, useState } from "react";

import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useMatch } from "react-router";
import { useLocation } from "react-router-dom";

import * as Dropdown from "ds/Dropdown";
import { useClickOutside } from "Hooks/useClickOutside";
import useMediaQuery from "Hooks/useMediaQuery";
import useUrls from "Hooks/useUrls";
import { organizationsMultiVendorOrgsSelector } from "Reducers/organization";
import { useTrialInfo } from "Reducers/organization/hooks/useTrialnfo";
import { loadOneProject, projectListSelector } from "Reducers/project/project";
import FreeTrialDialog from "src/organization/common/components/FreeTrialDialog";
import InviteModal from "src/organization/pages/settings/pages/members/components/InviteModal";
import { useAppSelector, useAppDispatch } from "Store/hooks";

import CreateProjectModal from "./CreateProjectModal";
import OrganizationMenu from "./OrganizationMenu";
import * as S from "./styles";

interface Props {
  organizationId: string;
}

const OrganizationsSwitcher = ({ organizationId }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] =
    useState(false);

  const [createOrganizationUrl] = useUrls("newOrganization");

  const match = useMediaQuery("(min-width: 764px)");
  const location = useLocation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const intl = useIntl();

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(isMenuOpen =>
      !isInviteModalOpen && isMenuOpen ? !isMenuOpen : true
    );
  }, [isInviteModalOpen]);
  const projectsList = useAppSelector(projectListSelector)[organizationId];
  const isOnOrganizationProjectsPage = useMatch("/:organizationId");

  useEffect(() => {
    if (projectsList && isOnOrganizationProjectsPage) {
      const projectId = Object.keys(projectsList)[0];

      dispatch(
        loadOneProject({
          projectId,
          organizationId,
          loadActivitiesAndSubscribe: false
        })
      );
    }
  }, [projectsList, dispatch, isOnOrganizationProjectsPage, organizationId]);
  const organizations = useSelector(organizationsMultiVendorOrgsSelector);

  const selectedOrganization = organizations?.find(
    o => o.name === organizationId
  );

  useClickOutside(wrapperRef, () => setIsMenuOpen(false));

  const getOrganizationDisplayName = () => {
    const createOrgUrl =
      location.pathname === createOrganizationUrl
        ? intl.formatMessage({ id: "create_organization" })
        : null;
    return (
      selectedOrganization?.label ||
      organizationId ||
      createOrgUrl ||
      intl.formatMessage({ id: "all_projects" })
    );
  };

  const { isTrial } = useTrialInfo();

  return (
    <S.Wrapper ref={wrapperRef}>
      {organizations.length || organizationId ? (
        match ? (
          <>
            <S.OrganizationNameWrapper>
              <Dropdown.Root open={isMenuOpen}>
                <S.DropdownTrigger
                  variant="mixed"
                  onClick={() => toggleMenu()}
                  data-heap-redact-text
                >
                  {getOrganizationDisplayName()}
                  <Dropdown.Chevron />
                </S.DropdownTrigger>
              </Dropdown.Root>
            </S.OrganizationNameWrapper>
            {isTrial && (
              <FreeTrialDialog
                organizationId={organizationId}
                setIsCreateProjectModalOpen={setIsCreateProjectModalOpen}
              />
            )}
            {isCreateProjectModalOpen && (
              <CreateProjectModal
                organizationId={organizationId}
                isCreateProjectModalOpen={isCreateProjectModalOpen}
                setIsCreateProjectModalOpen={setIsCreateProjectModalOpen}
              />
            )}
            <OrganizationMenu
              isOpen={isMenuOpen}
              onOpenChange={setIsMenuOpen}
              setIsInviteModalOpen={setIsInviteModalOpen}
            />

            {isInviteModalOpen && selectedOrganization && (
              <InviteModal
                organizationCapabilities={selectedOrganization.capabilities}
                organizationId={selectedOrganization.id}
                onOpenChange={() => setIsInviteModalOpen(state => !state)}
              />
            )}
          </>
        ) : null
      ) : null}
    </S.Wrapper>
  );
};

export default OrganizationsSwitcher;
