import { DotIcon } from "@packages/ds";
import styled from "styled-components";

export interface RequiredIconProps {
  isValid?: boolean;
}

const RequiredIcon = styled(DotIcon)<RequiredIconProps>`
  width: 12px;
  height: 12px;

  ${({ isValid = true }) =>
    isValid
      ? "fill:var(--mode-vector-primary-default)"
      : "fill:var(--mode-vector-critical-default)"};
`;

RequiredIcon.displayName = "RequiredIcon";

export default RequiredIcon;
