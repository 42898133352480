import { Action, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAllUserOrganizations } from "Api/users/getOrganizations.request";
import config from "console_config";
import { PLATFORMER_ROLES } from "Constants/constants";
import logger from "Libs/logger";
import client, { request } from "Libs/platform";
import { sha512 } from "Libs/utils";
import { getFeatureFlag } from "Reducers/featureFlags";
import {
  LOAD_ORGANIZATIONS_SUCCESS,
  LOAD_REF_ORGANIZATIONS_SUCCESS,
  LOAD_ORGANIZATION_START,
  LOAD_ORGANIZATION_FAILURE,
  LOAD_MULTI_VENDOR_ORGANIZATIONS_SUCCESS
} from "Reducers/organization";
import { loadUserProfile } from "Reducers/profile";
import { loadAllProjects } from "Reducers/project/project";
import { getRegions } from "Reducers/project/region";
import { AsyncThunkOptionType } from "Reducers/types";

import { loadSubscriptions } from "../subscription";

import type { AuthUser, Me, OrganizationProfile } from "platformsh-client";

export interface LocationAction extends Action {
  payload: {
    location: {
      pathname: string;
    };
  };
}

declare global {
  interface Window {
    heap: any;
    drift: any;
    zE: any;
  }
}
//where should it be called? trial info is in organizationprofile but we still need me
export const setUserAttributesDrift = ({
  me,
  organizationProfile,
  user
}: {
  me?: Me;
  organizationProfile?: OrganizationProfile;
  user?: AuthUser;
}) => {
  if (!config.ID_DRIFT_CHATBOT || !window.drift) return;
  window.drift.on("ready", api => {
    api.setUserAttributes({
      me_id: me?.id,
      trial_start_date:
        organizationProfile?.current_trial?.created &&
        new Date(organizationProfile?.current_trial?.created).toISOString(),
      trial_end_date:
        organizationProfile?.current_trial?.expiration &&
        new Date(organizationProfile?.current_trial?.expiration).toISOString(),
      created_at: user?.created_at && new Date(user?.created_at).toISOString()
    });
    window.drift.page();
  });
};
export const identifyDrift = (me: Me) => {
  if (!config.ID_DRIFT_CHATBOT || !window.drift || !me.id) return;

  window.drift.identify(me.id, {
    is_internal: me.roles?.some(role => PLATFORMER_ROLES.includes(role))
      ? "platformer"
      : "normal account"
  });
  window.drift.load(config.ID_DRIFT_CHATBOT);

  setUserAttributesDrift({ me });
};

// Heap: identify user
// identify user: https://developers.heap.io/reference/identify
// add user properties: https://developers.heap.io/reference/adduserproperties
const identifyHeap = (me: Me) => {
  if (!config.ID_HEAP || !window.heap) return;
  sha512(me.mail).then(hashedMail => {
    window.heap.identify(hashedMail);
    window.heap.addUserProperties({
      account_category: me?.roles?.some(role => PLATFORMER_ROLES.includes(role))
        ? "platformer"
        : "normal account"
    });
  });
};

// Heap: track event
// https://developers.heap.io/reference/track
const trackEventHeap = (name: string, event: Record<string, any>) => {
  if (!config.ID_HEAP || !window.heap) return;
  window.heap.track(name, event);
};

// Zendesk Messenger
const authenticateZendesk = () => {
  if (!config.ID_ZENDESK || !window.zE) return;

  request(`${client.getConfig().api_url}/zendesk_messenger`).then(response => {
    window.zE("messenger", "loginUser", callback => {
      callback(response.jwt);
    });
    window.zE("messenger:on", "open", () => {
      trackEventHeap("messenger", { open: true });
    });
    window.zE("messenger:on", "close", () => {
      trackEventHeap("messenger", { open: false });
    });
  });
};

export const init = createAsyncThunk<
  undefined,
  undefined,
  AsyncThunkOptionType
>("app/init", (_, { rejectWithValue, dispatch }) => {
  try {
    dispatch(getMe());
  } catch (error) {
    logger(error, {
      action: "init"
    });
    return rejectWithValue(error);
  }
});

export const getAccountInfo = createAsyncThunk<
  Me,
  undefined,
  AsyncThunkOptionType
>("app/get_account_info", async (_, { rejectWithValue }) => {
  try {
    const me = await client.getAccountInfo(true);
    const userMe = await client.getUser(me.uuid);
    me.username = userMe.username;
    return me;
  } catch (error) {
    logger(error, {
      action: "GET_ACCOUNT_INFO"
    });
    return rejectWithValue(error);
  }
});

export const getMe = createAsyncThunk<
  undefined,
  boolean | undefined,
  AsyncThunkOptionType
>("app/get_me", async (reload = false, { rejectWithValue, dispatch }) => {
  try {
    // Load the current user
    const { payload: me } = await dispatch(getAccountInfo());
    const organizationEnabled = getFeatureFlag("ENABLE_ORGANIZATION");

    me && identifyHeap(me);
    me && identifyDrift(me);
    authenticateZendesk();

    if (!reload) {
      if (!organizationEnabled) {
        // Load all the users subscriptions at first load to avoid race condition with loadSubscription
        dispatch(loadSubscriptions({ organizationId: me.username }));
      }

      if (organizationEnabled) {
        try {
          dispatch({ type: LOAD_ORGANIZATION_START });

          let filter: { vendor: Array<string> } | undefined = undefined;
          const vendors = config.CUSTOM_ORGANIZATION_VENDORS;
          if (vendors) filter = { vendor: Object.keys(vendors) };

          const allOrganizations = await getAllUserOrganizations(me?.uuid, {
            filter,
            sort: "label",
            page: {
              size: 100
            }
          });

          let res = [...allOrganizations];

          if (config.CUSTOM_ORGANIZATION_VENDORS) {
            const currentVendor = Object.entries(
              config.CUSTOM_ORGANIZATION_VENDORS || {}
            ).find(([, value]) => value === "")?.[0];
            res = res.filter(org => org.vendor === currentVendor);
          }

          dispatch({
            type: LOAD_MULTI_VENDOR_ORGANIZATIONS_SUCCESS,
            payload: allOrganizations
          });

          dispatch({
            type: LOAD_REF_ORGANIZATIONS_SUCCESS,
            payload: res
          });

          dispatch({
            type: LOAD_ORGANIZATIONS_SUCCESS,
            payload: res
          });
        } catch (err) {
          dispatch({
            type: LOAD_ORGANIZATION_FAILURE,
            payload: err
          });
          logger(err, {
            action: "LOAD_REF_ORGANIZATIONS"
          });
        }
      }
    }

    return me;
  } catch (error) {
    logger(error, {
      action: "GET_ME_START"
    });
    return rejectWithValue(error);
  } finally {
    setTimeout(() => {
      dispatch(loadUserProfile());
      dispatch(loadAllProjects());
      dispatch(getRegions());
    }, 0);
  }
});

interface AppSliceState {
  loading: boolean;
  error: unknown;
  me?: Me;
  routeHistory: {
    currentRoute: string;
    previousRoutes: string[];
  };
}

const initialState: AppSliceState = {
  loading: false,
  error: null,
  routeHistory: {
    currentRoute: "",
    previousRoutes: []
  }
};

const app = createSlice({
  name: "app",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // INIT
      .addCase(init.pending, state => {
        state.loading = true;
      })
      .addCase(init.fulfilled, state => {
        state.routeHistory.currentRoute = window.location.pathname;
      })
      .addCase(init.rejected, (state, action) => {
        state.error = action.payload;
      })
      // GET ME
      .addCase(getMe.pending, state => {
        state.loading = true;
      })
      .addCase(getMe.fulfilled, state => {
        state.loading = false;
      })
      .addCase(getMe.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // GET ACCOUNT INFO
      .addCase(getAccountInfo.pending, state => {
        state.loading = true;
      })
      .addCase(getAccountInfo.fulfilled, (state, action) => {
        state.me = action.payload;
        state.loading = false;
      })
      .addCase(getAccountInfo.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });

    builder.addCase(
      "@@router/LOCATION_CHANGE",
      (state, action: LocationAction) => {
        // This action doesn't contain the previous route so we have to log each
        // route in the store, then move it to a list that contains the history.
        // Then ensure no duplicates are added to the history.
        state.routeHistory.currentRoute = action.payload.location.pathname;
        state.routeHistory.previousRoutes = Array.from(
          new Set([
            state.routeHistory.currentRoute,
            ...state.routeHistory.previousRoutes
          ])
        );
      }
    );
  }
});

export default app.reducer;
