import React, { SVGAttributes } from "react";

import styled from "styled-components";

enum Direction {
  up = "up",
  down = "down",
  left = "left",
  right = "right"
}

export interface Props extends SVGAttributes<SVGSVGElement> {
  /** Where the tip of the chevron will be pointing to. `down` by default */
  direction?: keyof typeof Direction;
}

const getTransformBasedOnDirection = (direction: Props["direction"]) => {
  switch (direction) {
    case Direction.up:
      return "rotate(180deg)";
    case Direction.left:
      return "rotate(90deg)";
    case Direction.right:
      return "rotate(270deg)";
    case Direction.down:
    default:
      return "rotate(0)";
  }
};

const ChevronLayout = styled.svg<Props>`
  transform-origin: center;
  transform: ${({ direction }) => getTransformBasedOnDirection(direction)};
`;

export const ChevronIcon = ({ ...props }: Props) => (
  <ChevronLayout
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.90002 9.89999C6.90002 9.59999 7.00002 9.39999 7.20002 9.19999C7.60002 8.79999 8.20002 8.79999 8.60002 9.19999L12.1 12.7L15.6 9.19999C16 8.79999 16.6 8.79999 17 9.19999C17.4 9.59999 17.4 10.2 17 10.6L12.8 14.8C12.4 15.2 11.8 15.2 11.4 14.8L7.20002 10.6C7.00002 10.4 6.90002 10.2 6.90002 9.89999Z" />
  </ChevronLayout>
);
