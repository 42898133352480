import React from "react";

import { LoadingIcon } from "@packages/ds";

import Button from "ds/Button";

const LoadingButton = () => {
  return (
    <Button disabled>
      <LoadingIcon data-testid="loading_btn-spinner" />
    </Button>
  );
};

export default LoadingButton;
