import consoleConfig from "console_config";

import type { ClientConfiguration } from "platformsh-client";

const onBeforeRedirect = (location: string) => {
  if (
    location !== "/-/logout" &&
    location !== "/-/post-logout" &&
    location !== "/" &&
    location !== consoleConfig.URL_REDIRECT_AFTER_LOGOUT &&
    location !== consoleConfig.URL_LOGOUT
  ) {
    localStorage.setItem("auth-redirect-uri", location);
  }
};

// Use the default JS client on production
const config: ClientConfiguration = {
  response_mode: "web_message",
  response_type: "code",
  prompt: "none",
  api_url: consoleConfig.URL_API_PROXY as string,
  client_id: consoleConfig.ID_API_CLIENT,
  authentication_url: consoleConfig.URL_AUTH,
  authorization: consoleConfig.URL_AUTH
    ? `${consoleConfig.URL_AUTH}/oauth2/authorize`
    : `${consoleConfig.URL_ACCOUNTS}/oauth2/authorize`,
  logout_url: `${consoleConfig.URL_ACCOUNTS}/user/logout`,
  base_url: consoleConfig.URL_API_PROXY,
  ignoredSubdirectories:
    window.location.origin === consoleConfig.URL_AUTH ? ["/"] : undefined,
  onBeforeRedirect
};

export default config;
