import React from "react";

import { HelpIcon } from "@packages/ds";
import { useIntl } from "react-intl";

import { Container, HelpText, IconContainer } from "../styles";

interface Props {
  layoutRef?: any;
  setIsOpen: (value: boolean | ((arg: boolean) => boolean)) => void;
  isOpen: boolean;
}

const HelpButton = ({ setIsOpen }: Props) => {
  const intl = useIntl();

  return (
    <Container id="help-button" onClick={() => setIsOpen(value => !value)}>
      <IconContainer>
        <HelpIcon />
      </IconContainer>
      <HelpText>{intl.formatMessage({ id: "help_text" })}</HelpText>
    </Container>
  );
};

export default HelpButton;
