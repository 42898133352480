import { useCallback, useEffect } from "react";

import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "Store/hooks";

import { endProvisioningPulling, startProvisioningPulling } from "..";
import {
  loadingOrganizationProjectsSelector,
  isOrganizationProvisioningProject,
  organizationProvisioningFailureProject,
  organizationProvisioningProject
} from "../projects.selectors";
import { getProject, getProjects } from "../thunks";

// This hooks fetches all provisioning or provisioning failure projects in an org using
// the organization project endpoints. If a project in an organization is provisioning,
// that org doesn't have any other provisioning projects being checked, and the provisioning
// project was created 15 minutes ago, we dispatch a puller that pulls every 5 seconds that project
// until its status changes. This hook can be called so that if we call it in different places with
// the same org it will start the pulling only once, and will allow all of the places its being called
// by to run the onFinish handler.
export default function useProvisioningPuller(options?: {
  onFinish: () => void;
}) {
  const { organizationId: paramsOrganizationId } = useParams();
  const dispatch = useAppDispatch();
  const organizationId = paramsOrganizationId!;
  const provisioningProject = useAppSelector(state =>
    organizationProvisioningProject(state, { organizationId })
  );
  const provisioningFailureProject = useAppSelector(state =>
    organizationProvisioningFailureProject(state, {
      organizationId
    })
  );
  const isOrganizationProjectsLoading = useAppSelector(
    loadingOrganizationProjectsSelector
  );
  const hasProjectProvisioningPullingStarted = useAppSelector(state =>
    isOrganizationProvisioningProject(state, { organizationId })
  );

  const load = useCallback(() => {
    dispatch(
      getProjects({
        organizationId,
        params: {
          filter: {
            status: { in: "provisioning,provisioning failure" }
            // TODO: As soon as the filter is done on the API we can start using it, until then
            // The operation will be done on console side
            // created_at: {
            //   gt: moment().subtract(15, "minutes").toDate().toISOString()
            // }
          }
        }
      })
    );
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (isOrganizationProjectsLoading !== null) return;

    load();
  }, [dispatch, isOrganizationProjectsLoading, load, organizationId]);

  useEffect(() => {
    if (!provisioningProject) return;
    if (hasProjectProvisioningPullingStarted) return;
    dispatch(startProvisioningPulling({ organizationId }));

    dispatch(getProject({ organizationId, projectId: provisioningProject.id }));

    const intervalId = setInterval(async () => {
      const project = await dispatch(
        getProject({ organizationId, projectId: provisioningProject.id })
      ).unwrap();

      if (project.status !== "provisioning") {
        dispatch(endProvisioningPulling({ organizationId }));
        options?.onFinish();
        clearInterval(intervalId);
      }
    }, 5000);
  }, [
    dispatch,
    hasProjectProvisioningPullingStarted,
    options,
    options?.onFinish,
    organizationId,
    provisioningProject
  ]);

  return { load, provisioningProject, provisioningFailureProject };
}
