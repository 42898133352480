import React from "react";

import { Icon, WrappingIconProps } from "../Icon";

export const CloseIcon = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7046 6.29537C17.3108 5.90154 16.6723 5.90154 16.2785 6.29537L12 10.5738L7.72151 6.29537C7.32769 5.90154 6.68917 5.90154 6.29535 6.29537C5.90153 6.68919 5.90153 7.3277 6.29535 7.72153L10.5738 12L6.29535 16.2785C5.90153 16.6723 5.90153 17.3108 6.29535 17.7046C6.68917 18.0985 7.32769 18.0985 7.72151 17.7046L12 13.4262L16.2785 17.7046C16.6723 18.0985 17.3108 18.0985 17.7046 17.7046C18.0984 17.3108 18.0984 16.6723 17.7046 16.2785L13.4261 12L17.7046 7.72153C18.0984 7.3277 18.0984 6.68919 17.7046 6.29537Z"
      />
    </Icon>
  );
};
