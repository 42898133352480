import React, { useEffect, useMemo, useState } from "react";

import { Highlightable } from "@packages/ds";
import { useIntl } from "react-intl";

import MenuDropdownWrapper from "Components/MenuDropdownWrapper";
import useDecodedParams from "Hooks/useDecodedParams";
import { selectFeatureOrganizationsEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import useOrganizationFromURL from "src/organization/pages/settings/pages/members/hooks/useOrganizationFromURL";
import { useAppSelector } from "Store/hooks";

import * as S from "./SimpleMenu.styles";
import { useMeOrOrganizationProjects } from "./useMeOrOrganizationProjects";

type SimpleMenuProps = {
  isOpen: boolean;
  toggleMenu: () => void;
};

const SimpleMenu = ({ isOpen, toggleMenu }: SimpleMenuProps) => {
  const intl = useIntl();
  const { projectId } = useDecodedParams<{ projectId: string }>();
  const organization = useOrganizationFromURL();

  const [searchTitle, setSearchTitle] = useState<string>("");

  const isOrganizationsEnabled = useAppSelector(
    selectFeatureOrganizationsEnabled
  );

  const projects = useMeOrOrganizationProjects(
    organization?.id!,

    organization?.name!,
    searchTitle
  );

  useEffect(() => {
    const awayListener = (evt: Event) => {
      if (evt.cancelBubble) return false;
      setSearchTitle("");
    };

    document.addEventListener("click", awayListener);

    return () => document.removeEventListener("click", awayListener);
  }, []);

  const listFiltered = useMemo(() => {
    const title = searchTitle.toUpperCase();
    if (!projects) return [];

    if (isOrganizationsEnabled)
      return projects.filter(
        project => project.organizationId === organization?.id
      );

    return projects
      .filter(
        project =>
          !!project.title && project.title?.toUpperCase().includes(title)
      )
      .sort(
        (projectA, projectB) =>
          projectA.title?.localeCompare(projectB.title || "") || 0
      );
  }, [projects, searchTitle, organization, isOrganizationsEnabled]);

  const listCount = useMemo(() => listFiltered.length, [listFiltered]);

  const Wrapper = useMemo(
    () => (listCount > 10 ? S.Scrollbox : S.NoScroll) as React.ElementType,
    [listCount]
  );

  return (
    <MenuDropdownWrapper
      isOpen={isOpen}
      onKeyUp={evt => {
        evt.key === "Escape" && isOpen && toggleMenu();
      }}
      width="200px"
    >
      <S.SearchInput
        id="navbar-projects"
        customWidth="100%"
        onChange={(value: string) => setSearchTitle(value)}
        placeholder={intl.formatMessage({ id: "simplemenu.placeholder" })}
        value={searchTitle}
      />
      <S.MenuLayout role="menu" aria-label="projects">
        {listCount === 0 ? (
          <S.Empty>
            {intl.formatMessage({ id: "simplemenu.no-result" })}
          </S.Empty>
        ) : (
          <Wrapper>
            <ul>
              {listFiltered.map(project => (
                <li key={project.id}>
                  <S.Link
                    to={`/${project.organizationName}/${project.id}`}
                    aria-label={project.title}
                    id={`navbar-projects-${project.id}`}
                    role="menuitem"
                    $isCurrent={project.id === projectId}
                  >
                    <Highlightable
                      searchText={searchTitle}
                      textToHighlight={
                        project.title ||
                        intl.formatMessage({ id: "simplemenu.default" })
                      }
                    />
                  </S.Link>
                </li>
              ))}
            </ul>
          </Wrapper>
        )}
      </S.MenuLayout>
    </MenuDropdownWrapper>
  );
};

export default SimpleMenu;
