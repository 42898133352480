import React from "react";

import { HelpIcon } from "@packages/ds";
import { useIntl } from "react-intl";

import * as Dropdown from "ds/Dropdown";
import useMediaQuery from "Hooks/useMediaQuery";
import { getSupportUrl } from "Libs/getUrl/getUrl";
import { docsUrl } from "Libs/utils";

import * as S from "./styles";

import type { Me } from "platformsh-client";

interface Props {
  user: Me;
  accountUrl: string;
}

const HelpMenu = ({ accountUrl, user }: Props) => {
  const intl = useIntl();
  const match = useMediaQuery("(max-width: 764px)");

  const submitIdeaUrl = intl.messages["links.submit_idea"];
  const discordUrl = intl.messages["links.discord"];

  const supportUrl = getSupportUrl({
    accountUrl,
    username: user.username
  });

  return (
    !match && (
      <Dropdown.Root>
        <S.DropdownTrigger variant="mixed">
          <HelpIcon />
          {intl.formatMessage({ id: "help_text" })}
        </S.DropdownTrigger>
        <Dropdown.Content align="end">
          <Dropdown.LinkItem
            href={docsUrl}
            analyticId="help.menu.documentation"
          >
            {intl.formatMessage({ id: "menu.documentation" })}
          </Dropdown.LinkItem>

          <Dropdown.LinkItem
            to={supportUrl || ""}
            analyticId="help.menu.support"
          >
            {intl.formatMessage({ id: "menu.support" })}
          </Dropdown.LinkItem>

          {discordUrl && (
            <Dropdown.LinkItem
              href={discordUrl as string}
              analyticId="help.menu.discord"
            >
              {intl.formatMessage({ id: "help.discord" })}
            </Dropdown.LinkItem>
          )}

          {submitIdeaUrl && (
            <>
              <S.DropdownSeparator />
              <Dropdown.LinkItem
                href={submitIdeaUrl as string}
                analyticId="help.menu.submit_idea"
              >
                {intl.formatMessage({ id: "help.submit_idea" })}
              </Dropdown.LinkItem>
            </>
          )}
        </Dropdown.Content>
      </Dropdown.Root>
    )
  );
};

export default HelpMenu;
