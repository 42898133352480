import { SearchIcon } from "@packages/ds";
import styled from "styled-components";

export interface SearchIconProps {
  $isActive: boolean;
}

export const Icon = styled(SearchIcon)<SearchIconProps>`
  fill: var(--mode-vector-neutral-default);
  flex: 0 0 24px;
  cursor: pointer;

  ${({ $isActive }) =>
    $isActive
      ? `fill: var(--icon-slate-fill,var(--slate));`
      : `
      :hover {
        fill: var(--icon-color-primary-600-fill,var(--color-primary-600));
      }
  `};
`;

export default Icon;
