import React from "react";

import { Icon, WrappingIconProps } from "../Icon";

export const ChevronDownIcon = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path d="M6.90002 9.90002C6.90002 9.60002 7.00002 9.40002 7.20002 9.20002C7.60002 8.80002 8.20002 8.80002 8.60002 9.20002L12.1 12.7L15.6 9.20002C16 8.80002 16.6 8.80002 17 9.20002C17.4 9.60002 17.4 10.2 17 10.6L12.8 14.8C12.4 15.2 11.8 15.2 11.4 14.8L7.20002 10.6C7.00002 10.4 6.90002 10.2 6.90002 9.90002Z" />
    </Icon>
  );
};
