import React, { useEffect } from "react";

import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import ModalWelcome from "Components/ModalWelcome";
import { useLocalForage } from "Hooks/useLocalForage";
import { hasSafeRole } from "Libs/utils";
import {
  selectSubscribeFlowEnabled,
  selectFeatureBillingEnabled,
  selectFeatureOrganizationsEnabled,
  selectFeatureUnbrandedArtEnabled
} from "Reducers/featureFlags/featureFlags.selectors";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { useTrialInfo } from "Reducers/organization/hooks/useTrialnfo";
import { useLoadPaymentSource } from "Reducers/organization/paymentSource/hooks/useLoadPaymentSource";
import { organizationProfileSelector } from "Reducers/organization/profile";
import {
  paymentSourceLoadingSelector,
  paymentSourceSelector,
  getPaymentSource
} from "Reducers/paymentSource";
import { currentTestTrialSelector } from "Reducers/testMode";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import { PaymentMissing, Trial } from "./";

const BannerManager = ({
  isProjectOwner = true,
  organizationId,
  projects,
  user
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isTrial } = useTrialInfo();
  const useUnbrandedArt = useAppSelector(selectFeatureUnbrandedArtEnabled);
  const organizationEnabled = useAppSelector(selectFeatureOrganizationsEnabled);
  const currentTime = moment();

  //This is used just for upsun, i am using it here to disable the old trial banner until we have the new dialog on console
  const subscribeCtaEnabled = useAppSelector(selectSubscribeFlowEnabled);

  const { paymentSource, loading: loadingPayment } =
    useLoadPaymentSource(organizationId);

  const loadingPaymentSource = useAppSelector(state => {
    /**
     * @type boolean | "idle"
     */
    let isLoading = false;
    if (organizationEnabled) {
      isLoading = loadingPayment;
    } else {
      isLoading = paymentSourceLoadingSelector(state);
    }

    return isLoading;
  });

  const currentTestTrial = useAppSelector(currentTestTrialSelector);
  const billingEnabled = useAppSelector(selectFeatureBillingEnabled);

  const hasPaymentSource = useAppSelector(state => {
    let ps;
    if (currentTestTrial?.status === "payment_missing") {
      return false;
    } else if (organizationEnabled) {
      ps = paymentSource;
    } else {
      ps = paymentSourceSelector(state);
    }
    return !!ps?.type;
  });

  const organizationProfile = useAppSelector(state =>
    organizationProfileSelector(state, { organizationId })
  );

  const organization = useAppSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  useEffect(() => {
    if (
      billingEnabled &&
      !organizationEnabled &&
      loadingPaymentSource === "idle"
    )
      dispatch(getPaymentSource());
  }, []);

  // Find all projects owned by current org to determine whether to display trial welcome modal
  const organizationProjects = projects?.filter(project => {
    return project?.organization_id === organization?.id;
  });

  // Modal won't display if project more than 2 days old, or on additional projects
  let projectCreatedDate;
  if (organizationProjects?.length === 1) {
    const currentProject = organizationProjects[0];
    projectCreatedDate = currentProject?.created_at;
  }
  const showTrialModal =
    projectCreatedDate &&
    currentTime.diff(moment(projectCreatedDate), "hours") < 48;

  // Browser remembers if user closed modal already
  const {
    value: isClosed = false,
    setValue: setIsClosed,
    isLoading: isLoadingModal
  } = useLocalForage("trial_welcome_modal_closed");

  const handleClose = e => {
    e?.preventDefault();
    setIsClosed(true);
  };

  const redeemHandler = async () => {
    await setIsClosed(true);
    navigate(`/${organizationId}/-/billing/vouchers`);
  };

  // If billing is not enabled, we should not show any
  // trial informations
  if (!billingEnabled) {
    return null;
  }

  // We avoid showing the banner if we are still fetching
  // a payment source, so we don't flash the banner
  if (loadingPaymentSource && !currentTestTrial) {
    return null;
  }
  const hasOrganizationProjects = organizationProjects?.length > 0;
  const hasPaymentSourceOrHasSafeRole =
    hasPaymentSource || hasSafeRole(user.roles);

  if (
    hasPaymentSourceOrHasSafeRole &&
    !currentTestTrial &&
    hasOrganizationProjects
  ) {
    return null;
  }

  // This needs to be removed once we have the trial dialog on both upsun and console
  const showModal = showTrialModal && !subscribeCtaEnabled;

  if (isTrial) {
    return (
      <>
        {showModal && !isLoadingModal && (
          <ModalWelcome
            closeModal={handleClose}
            redeemHandler={redeemHandler}
            currentTime={currentTime}
            isOpen={!isClosed}
            organizationProfile={organizationProfile}
          />
        )}
        <Trial
          organizationProfile={organizationProfile}
          useBrandedArt={!useUnbrandedArt}
          organizationId={organizationId}
        />
      </>
    );
  }

  if (
    (!isTrial && !hasPaymentSource && isProjectOwner) ||
    currentTestTrial?.status === "payment_missing"
  )
    return <PaymentMissing organizationId={organizationId} />;

  return null;
};

BannerManager.propTypes = {
  isProjectOwner: PropTypes.bool,
  projects: PropTypes.array,
  user: PropTypes.object,
  organizationId: PropTypes.string
};

export default BannerManager;
