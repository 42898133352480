import React, { useCallback, useEffect, useState } from "react";

import { InfoSmIcon } from "@packages/ds";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { BACKUP_TYPES } from "Constants/constants";
import { Button, ButtonWrapper, IconControl } from "ds/Button";
import Dialog from "ds/Dialog";
import Modal from "ds/Modal";
import * as Sticker from "ds/Sticker";
import { Label, Title, BodyText } from "ds/Typography";
import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";
import { capitalize } from "Libs/utils";
import { backupsStoreSelector } from "Reducers/backupsStored/backupsStoredReducer.selectors";
import {
  environmentSelector,
  environmentLoadingSelector
} from "Reducers/environment";
import {
  backup,
  clearBackup,
  environmentBackupSelector,
  backupStateSelector
} from "Reducers/environment/actions/backup";
import { selectSubscriptionDetails } from "Reducers/organization/subscription";
import { selectEnableCustomBackupSchedules } from "Reducers/project/capabilities";
import { gitProjectSelector } from "Reducers/project/project";
import ActivityDate from "src/organization/common/components/activities/Activity/ActivityDate";
import { useAppSelector, useAppDispatch } from "Store/hooks";

import * as S from "./BackupModal.style";

type BackupModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const BackupModal = ({ isOpen, closeModal }: BackupModalProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { environmentId, organizationId, projectId } = useDecodedParams<{
    environmentId: string;
    organizationId: string;
    projectId: string;
  }>();

  const [isLiveBackup, setIsLiveBackup] = useState(false);
  const [isBackupConfirmBtnActive, setIsBackupConfirmBtnActive] =
    useState(false);

  const [showFullBackupsCapacityNote, setShowFullBackupsCapacityNote] =
    useState<boolean>(false);

  const command = `${intl.formatMessage({
    id: "CLI_command_name"
  })} backup:create${isLiveBackup ? " --live" : ""}`;

  const environment = useSelectorWithUrlParams(environmentSelector);

  const isEnvironmentLoading = useSelectorWithUrlParams(
    environmentLoadingSelector
  );

  const { loading, errors } = useAppSelector(backupStateSelector);

  const backupData = useAppSelector(state =>
    environmentBackupSelector(state, {
      organizationId,
      projectId,
      environmentId
    })
  );

  const project = useSelectorWithUrlParams(gitProjectSelector);

  const subscription = useAppSelector(state => {
    return selectSubscriptionDetails(state, {
      organizationId,
      subscriptionId: project?.subscription_id
    });
  });

  const { backupsManual } = useAppSelector(backupsStoreSelector);

  const isCustomBackupSchedulesEnabled = useSelectorWithUrlParams(
    selectEnableCustomBackupSchedules
  );

  const onCloseModal = useCallback(() => {
    dispatch(clearBackup({ organizationId, projectId, environmentId }));
    closeModal();
  }, [closeModal, dispatch, environmentId, organizationId, projectId]);

  useEffect(() => {
    if (backupData) {
      onCloseModal();
    }
  }, [backupData, onCloseModal]);

  useEffect(() => {
    dispatch(clearBackup({ organizationId, projectId, environmentId }));
  }, [dispatch, environmentId, organizationId, projectId]);

  const handleBackup = async (e: React.MouseEvent | undefined) => {
    if (e) e.preventDefault();
    setIsBackupConfirmBtnActive(true);

    // Reducer will handle route back to project environment on success
    const response = await dispatch(
      backup({ organizationId, projectId, environmentId, safe: !isLiveBackup })
    );

    if (response.meta.requestStatus === "fulfilled") {
      setIsBackupConfirmBtnActive(false);
      return navigate(
        `/${organizationId}/${projectId}/${environmentId}/backups/history`
      );
    }
  };

  const defaultBackup =
    subscription?.project_options?.features?.backups?.default[0];
  const available =
    subscription?.project_options?.features?.backups?.available[0];

  const acceptedBackup = subscription?.backups || defaultBackup || available;

  const backupMax =
    (acceptedBackup &&
      BACKUP_TYPES[acceptedBackup as keyof typeof BACKUP_TYPES]?.manual) ||
    2;

  const lastBackupDate = environment?.last_backup_at;
  const isProduction = environment?.type === "production";

  const infoDialogTitle = intl.formatMessage({
    id: "environment.backups.modal.info_dialog.title",
    defaultMessage: `Live Backups`
  });
  const infoDialogText = intl.formatMessage({
    id: "environment.backups.modal.info_dialog.text",
    defaultMessage: `Manual live backups are run without any downtime to your environment, but may result in data inconsistencies. Alternatively, consistent backups run after pausing all requests which can result in downtime.`
  });
  const lastBackupText = intl.formatMessage({
    id: "environment.backups.modal.lastbackup.text",
    defaultMessage: "Last backup:"
  });
  const lastBackupInProgress = intl.formatMessage({
    id: "environment.backups.modal.lastbackup.progress",
    defaultMessage: "In progress..."
  });
  const fullCapacityNote = intl.formatMessage({
    id: "backups.modal.reached.max.note",
    defaultMessage: "Note: This backup will replace your oldest manual backup."
  });
  const customBackupScheduleNote = intl.formatMessage<React.ReactNode>(
    {
      id: "environment.backups.modal.custom_backup_schedule.note"
    },
    {
      link: chunks => (
        <S.ViewPricingLink
          variant="link"
          analyticId="links.pricing"
          href={intl.formatMessage({ id: "links.pricing" })}
        >
          {chunks}
        </S.ViewPricingLink>
      )
    }
  );

  useEffect(() => {
    const max = isProduction ? backupMax : 2;
    setShowFullBackupsCapacityNote(
      (backupsManual ? backupsManual.length : 0) >= max
    );
  }, [isProduction, backupsManual, backupMax]);

  return (
    <Modal
      id="environment-backup-modal-title"
      isOpen={isOpen}
      size="medium"
      onOpenChange={onCloseModal}
    >
      <Title tag="h3">{intl.formatMessage({ id: "backup.title" })}</Title>
      <div>
        {errors?.message && (
          <S.StickerWrapper>
            <Sticker.Root priority="critical">
              <Sticker.Icon priority="critical" />
              <Sticker.Text>{errors.message}</Sticker.Text>
            </Sticker.Root>
          </S.StickerWrapper>
        )}
        <BodyText size="medium" weight="regular">
          {intl.formatMessage({
            id: "environment.backups.modal.explanation",
            defaultMessage:
              "Create a complete backup of this environment. Your backup will include all persistent data from all running services and any files stored on mounted volumes."
          })}
        </BodyText>
        <S.CheckboxStyled
          id="environment-sync-modal-data-switch"
          forId="environment-sync-modal-data"
          label={
            <S.FlexWrapper>
              <S.LiveBackupText>
                {intl.formatMessage({
                  id: "environment.backups.modal.checkbox",
                  defaultMessage: "Create live backup"
                })}
              </S.LiveBackupText>
              <Dialog
                align="center"
                side="right"
                title={infoDialogTitle}
                description={infoDialogText}
                analyticId="environment.backups.modal.info_dialog.text"
                link={
                  <Button
                    href={intl.formatMessage({
                      id: "links.documentation.live_backups"
                    })}
                    analyticId="learnmore"
                    variant="link"
                  >
                    {intl.formatMessage({
                      id: "learnmore"
                    })}
                  </Button>
                }
                trigger={
                  <IconControl>
                    <InfoSmIcon />
                  </IconControl>
                }
              />
            </S.FlexWrapper>
          }
          onCheckedChanged={checked => {
            setIsLiveBackup(checked);
          }}
          checked={isLiveBackup}
        />

        <S.CodeBlock isCopyable singleLine id="environment-backup-cmd">
          {command}
        </S.CodeBlock>

        {showFullBackupsCapacityNote && (
          <S.BodyText weight="semiBold" size="small">
            {fullCapacityNote}
          </S.BodyText>
        )}

        {isCustomBackupSchedulesEnabled && (
          <S.BodyText weight="semiBold" size="extraSmall">
            {customBackupScheduleNote}
          </S.BodyText>
        )}

        <S.BottomWrapper
          justifyContent={lastBackupDate ? "space-between" : "end"}
        >
          {lastBackupDate ? (
            <S.Code variant="inline">
              <Label textStyle="lowercaseControl">
                {lastBackupText}{" "}
                {isEnvironmentLoading || loading ? (
                  lastBackupInProgress
                ) : (
                  <ActivityDate date={`${lastBackupDate}`} />
                )}
              </Label>
            </S.Code>
          ) : null}
          <ButtonWrapper spacing="modal" justifyContent="end">
            <Button
              id="environment-backup-cancel-btn"
              variant="secondary"
              type="button"
              aria-label={intl.formatMessage({ id: "cancel" })}
              onClick={onCloseModal}
              analyticId="cancel"
            >
              {capitalize(intl.formatMessage({ id: "cancel" }))}
            </Button>
            <Button
              id="environment-backup-save-btn"
              type="submit"
              aria-label={intl.formatMessage({ id: "backup.button" })}
              onClick={handleBackup}
              disabled={isBackupConfirmBtnActive}
              analyticId="backup.button"
            >
              {capitalize(intl.formatMessage({ id: "backup.button" }))}
            </Button>
          </ButtonWrapper>
        </S.BottomWrapper>
      </div>
    </Modal>
  );
};

export default BackupModal;
