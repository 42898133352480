import React, { useEffect } from "react";

import { SuccessIcon, WarningIcon } from "@packages/ds";
import { useIntl } from "react-intl";
import { withTheme } from "styled-components";

import useMediaQuery from "Hooks/useMediaQuery";
import { getSystemStatus } from "Reducers/systemStatus";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import * as S from "./styles";

import type { DefaultTheme } from "styled-components";

export const SYSTEM_STATUS = {
  WARNING: "warning",
  CRITICAL: "critical",
  MINOR: "minor",
  MAJOR: "major",
  SUCCESS: "success",
  NONE: "none",
  MAINTENANCE: "maintenance"
};

const getColorLevel = (level: string, theme: DefaultTheme) => {
  switch (level) {
    case SYSTEM_STATUS.NONE:
    case SYSTEM_STATUS.SUCCESS:
      return {
        bg: "var(--theme-success-200)",
        ico: "var(--theme-success-600)"
      };
    case SYSTEM_STATUS.MAINTENANCE:
      return {
        bg: "var(--theme-neutral-100)",
        ico: "var(--theme-neutral-500)"
      };

    case SYSTEM_STATUS.MINOR:
    case SYSTEM_STATUS.MAJOR:
    case SYSTEM_STATUS.WARNING:
      return {
        bg: "var(--theme-warning-200)",
        ico: "var(--theme-warning-600)"
      };
    case SYSTEM_STATUS.CRITICAL:
      return {
        bg: "var(--theme-critical-200)",
        ico: "var(--theme-critical-600)"
      };
    default:
      return {
        bg:
          theme.name === "dark"
            ? "var(--theme-neutral-1000)"
            : "var(--theme-neutral-0)",
        ico: "var(--theme-neutral-700)"
      };
  }
};

type SystemStatusBannerProps = {
  anchor?: boolean;
  theme: DefaultTheme;
};

const SystemStatusBanner = ({
  anchor = false,
  theme
}: SystemStatusBannerProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const onDesktop = useMediaQuery("(min-width: 768px)");

  let status = useAppSelector(state => state.systemStatus.status);
  const loading = useAppSelector(state => state.systemStatus.loading);
  const selectedSystemStatus = useAppSelector(
    state => state.testMode.systemStatus
  );
  if (selectedSystemStatus !== "") {
    status = {
      ...status,
      indicator: selectedSystemStatus,
      description: `Test mode: status set to ${selectedSystemStatus}`
    };
  }

  useEffect(() => {
    if (loading === "idle") dispatch(getSystemStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!status) return null;

  // Display banner on mobile only when an incident is in progress
  if (!onDesktop && status.indicator === SYSTEM_STATUS.NONE) return null;

  const { ico, bg } = getColorLevel(status.indicator, theme);

  return (
    <S.Wrapper bg={bg}>
      <S.IconWrapper>
        {status.indicator === SYSTEM_STATUS.SUCCESS ||
        status.indicator === SYSTEM_STATUS.NONE ? (
          <SuccessIcon color={ico} />
        ) : (
          <WarningIcon color={ico} />
        )}
      </S.IconWrapper>
      <S.StatusText size="extraSmall" weight="semiBold">
        {status.description}
      </S.StatusText>

      {!onDesktop && anchor && (
        <S.Link href="#status">
          {intl.formatMessage({
            id: "system_status.anchor",
            defaultMessage: "View status"
          })}
        </S.Link>
      )}
    </S.Wrapper>
  );
};

export default withTheme(SystemStatusBanner);
